import React from 'react'
import { Link } from 'react-router-dom'

import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import CategoryTable from './components/CategoryTable'

import { PageTopSection, CreateProductButton } from './style'

const CategoryList = () => (
  <PageWrapper title="Categorías">
    <PageTopSection>
      <Link to="/categories/create">
        <CreateProductButton icon="plus" color="success">
          Crear Categoría
        </CreateProductButton>
      </Link>
    </PageTopSection>
    <Paper>
      <CategoryTable />
    </Paper>
  </PageWrapper>
)

export default CategoryList
