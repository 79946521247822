import styled from 'styled-components'

import { Button } from '../../../components/Buttons'
import Icon from '../../../components/Icon'
import sizes from '../../../styles/media'
import { CheckBox } from '../../../components/FormElements'

export const SaveButton = styled(Button)`
  width: 100%;
  @media ${sizes.sm} {
    width: auto;
  }
`

export const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`

export const AddContactWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 186px;
  height: 100%;
  flex-direction: column;
  color: #858585;
`

export const AddContactText = styled.span`
  margin-top: 15px;
`

export const CloseButton = styled.button`
  font-size: 14px;
  position: absolute;
  top: -14px;
  right: -24px;
  cursor: pointer;
  color: #fff;
  background: none;
  border: none;
  display: block;
  z-index: 1;
`

export const CloseIcon = styled(Icon)`
  font-size: 34px;
  color: #ff2d2d;
`
export const StyledCheckBox = styled(CheckBox)``

export const AddFeatureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 188px;
  height: 100%;
  flex-direction: column;
  color: #858585;
`
export const AddFeatureText = styled.span`
  margin-top: 15px;
`
export const MapContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`
export const MapView = styled.div`
  display: grid;
  grid-gap: 15px;
  padding: 10px;
  width: 100%;
`

export const MapButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  padding: 10px;
`

export const MapDetails = styled.div`
  display: grid;
  grid-template-columns: 70% auto;
  grid-gap: 10px;
  padding: 10px;
`
export const ScheduleData = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  padding: 10px;
`
export const ScheduleLabel = styled.p`
  font-weight: bold;
  text-align: left;
`
export const ScheduleDayLabel = styled.p`
  text-align: left;
  padding-top: 2rem;
`

export const ScheduleDates = styled.div`
  display: grid;
  grid-template-columns: 20% 1% 9% 35% 35%;
  grid-column-gap: 10px;
  padding: 2rem;
  border-bottom: 1px solid #c5c5c5;
`

export const ScheduleSelectsContainer = styled.div`
  border-bottom: 1px solid black;
`

export const SwitchTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #858585;
  margin: 0;
  padding-left: 1rem;
  padding-top: 0.2rem;
`

export const SwitchDummy = styled.div`
  width: 100%;
`
