import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import { AsyncSelect } from '../../FormElements'
import Loading from '../../Loader'
import { Spacer } from '../../Globals'

const GET_CATEGORIES_QUERY = gql`
  query getCategoriesQuery(
    $options: CategoriesOptions!
    $filters: CategoriesFilters!
  ) {
    categories(options: $options, filters: $filters) {
      nodes {
        id
        name
      }
    }
  }
`

type Category = { [key: string]: any }

export type CategoryOption = ValueType<{ [key: string]: any }>

type Data = {
  categories: {
    nodes: Category[]
  }
}

type Props = {
  value: CategoryOption | CategoryOption[] | null
  onChange: (categories: CategoryOption) => void
  isMulti: boolean
  disabled: boolean
  isRequired: boolean
  error?: boolean
}

export default function CategorySelector({
  value,
  onChange,
  isMulti,
  disabled,
  isRequired,
  error,
}: Props) {
  return (
    <Query
      query={GET_CATEGORIES_QUERY}
      fetchPolicy="network-only"
      variables={{ options: { limit: 10 }, filters: {} }}
    >
      {({
        data,
        loading,
        error: errorQuery,
        client,
      }: QueryResult<Data, any>) => {
        if (loading) {
          return (
            <div id={'categories'}>
              <Spacer />
              <Loading />
            </div>
          )
        }
        if (errorQuery || !data) {
          return (
            <p>
              Error cargando categories. <br />
              Por favor recargue la página.
            </p>
          )
        }
        return (
          <AsyncSelect
            id="categoriesSelect"
            label="Categoria"
            placeholder="Seleccione un categoria"
            isDisabled={disabled}
            required={isRequired}
            isMulti={isMulti}
            inputId={'categories'}
            value={value}
            onChange={onChange}
            getOptionLabel={({ name }: Category) => name}
            getOptionValue={({ id }: Category) => id}
            backspaceRemovesValue={false}
            defaultOptions={data.categories.nodes}
            cacheOptions
            error={error}
            loadOptions={async inputValue => {
              const { data } = await client.query<Data>({
                query: GET_CATEGORIES_QUERY,
                variables: {
                  options: { limit: 10 },
                  filters: { s: inputValue },
                },
              })
              if (!data) {
                return []
              }
              return data.categories.nodes
            }}
          />
        )
      }}
    </Query>
  )
}
