import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs'

import { PAGE_SIZE_OPTIONS } from '../../../../config'
import DataTable from '../../../../components/DataTable'
import { parseCursorPageParams } from '../../../../helpers/params'
import { Tooltip } from '../../../../components/FormElements'
import { EyeIcon, EditIcon } from '../style'

import { ActionContainer } from '../../../../components/Globals'
import ThemeContext from '../../../../context/ThemeContext'

const GET_PROMOS_QUERY = gql`
  query getPromosQuery($options: PromosOptions!, $filters: PromosFilters!) {
    promos(options: $options, filters: $filters) {
      nodes {
        id
        name
        entity {
          id
          name
        }
        status
        psCode
        promoType {
          id
          name
        }
        validTo
        validFrom
      }
      pagination {
        limit
        cursors {
          before
          after
        }
      }
    }
  }
`

type PromoNode = Record<string, any>

interface Data {
  promos: {
    nodes: PromoNode[]
    pagination: {
      limit: number
      cursors: {
        before: string
        after: string
      }
    }
  }
}

type FilterOptionType = Record<string, any>

interface promoTableState {
  filtersOpened: boolean
  filterSelected: FilterOptionType | null
  supplierFromUrl: string
}

class promoTable extends React.Component<
  RouteComponentProps<{}>,
  promoTableState
> {
  state: promoTableState = {
    filtersOpened: false,
    filterSelected: null,
    supplierFromUrl: '',
  }

  componentDidMount() {
    const { supplier } = parseCursorPageParams(
      this.props.location.search.slice(1)
    )

    if (supplier) {
      this.setState({ filtersOpened: true, supplierFromUrl: supplier })
    }
  }

  toggleFilterSection = () => {
    const { filtersOpened } = this.state
    this.setState({ filtersOpened: !filtersOpened })
  }

  extractPageParams = () => {
    const {
      page_size,
      sort_by,
      after,
      before,
      status,
      promo_type,
      entity_type,
      s,
    } = parseCursorPageParams(this.props.location.search.slice(1))
    return {
      page_size,
      sort_by,
      after,
      before,
      status,
      promo_type,
      entity_type,
      s,
    }
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText
    params.after = ''
    params.before = ''
    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  onAfter = (after: string | undefined) => {
    const params = this.extractPageParams()
    params.after = after

    if (params.before) {
      delete params.before
    }

    this.updatePageParams(params)
  }

  onBefore = (before: string | undefined) => {
    const params = this.extractPageParams()
    params.before = before

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onFetch = (formatParams: any) => {
    const params = this.extractPageParams()

    const { filters } = formatParams

    if (filters && filters.status) {
      params.status = filters.status.value
    }

    if (filters && filters.promo_type) {
      params.promo_type = filters.promo_type.value
    }

    if (filters && filters.entity_type) {
      params.entity_type = filters.entity_type.value
    }

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }
    if (params.s) {
      delete params.s
    }

    this.updatePageParams(params)
  }

  onFilterSelected = (
    index: number,
    selected: FilterOptionType | null | undefined
  ) => {
    this.setState({ filterSelected: { index, selected } })
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  render() {
    const { filtersOpened, filterSelected, supplierFromUrl } = this.state

    const {
      page_size,
      after,
      before,
      status,
      promo_type,
      entity_type,
      s,
    } = this.extractPageParams()

    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

    return (
      <ThemeContext.Consumer>
        {context => (
          <Query
            variables={{
              options: {
                limit: pageSize,
                after,
                before,
              },
              filters: { status, promo_type, entity_type, s },
            }}
            query={GET_PROMOS_QUERY}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }: QueryResult<Data, any>) => {
              if (loading) {
                return <p>Loading...</p>
              }
              if (error) {
                return <p>Error</p>
              }
              if (!data) {
                return <p>No Data</p>
              }

              const { nodes, pagination } = data.promos

              const statusOptions: FilterOptionType[] = []
              statusOptions.push(
                { label: 'Todos los estados', value: 'all' },
                { label: 'Aprobado', value: 'approved' },
                { label: 'Pendiente', value: 'pending' },
                { label: 'Rechazado', value: 'rejected' }
              )

              /** filter options for promo types */
              const promosID = nodes.map(promo => ({
                label: promo.promoType.name,
                value: promo.promoType.id,
              }))
              const uniquePromosID = Object.values(
                promosID.reduce(
                  (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
                  {}
                )
              )
              let promoOptions: FilterOptionType[] = []
              promoOptions.push({
                label: 'Todos los tipos de promoción',
                value: 'all',
              })
              promoOptions = promoOptions.concat(uniquePromosID)

              /** filter options for entities */
              const entitiesID = nodes.map(promo => {
                const entity = {
                  label: promo.entity.name,
                  value: promo.entity.id,
                }
                return entity
              })
              const uniqueEntitiesID = Object.values(
                entitiesID.reduce(
                  (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
                  {}
                )
              )
              let entityOptions: FilterOptionType[] = []
              entityOptions.push({
                label: 'Todas entidades',
                value: 'all',
              })
              entityOptions = entityOptions.concat(uniqueEntitiesID)

              const columns = [
                {
                  header: 'Código',
                  key: 'psCode',
                },
                {
                  header: 'Tipo',
                  key: 'promoType',
                  Cell: (promo: any) => promo.promoType.name,
                },
                {
                  header: 'Nombre',
                  key: 'name',
                },
                {
                  header: 'Entidad',
                  key: 'entity',
                  Cell: (promo: any) => promo.entity.name,
                },
                {
                  header: 'Inicio',
                  key: 'validFrom',
                },
                {
                  header: 'Finaliza',
                  key: 'validTo',
                },
                {
                  header: 'Estado',
                  key: 'status',
                },
                {
                  header: 'Acciones',
                  key: 'actions',
                  width: 99,
                  sortable: false,
                  Cell: (promo: PromoNode) => {
                    return (
                      <ActionContainer>
                        {(promo.status === 'pending' ||
                          promo.status === 'rejected') && (
                          <Link to={`promos/${promo.id}/edit`}>
                            <Tooltip id={`edit-${promo.id}`} message="Editar">
                              <EditIcon name="pencil" />
                            </Tooltip>
                          </Link>
                        )}
                        <Link to={`promos/${promo.id}`}>
                          <Tooltip
                            id={`preview-${promo.id}`}
                            message="Previsualizar"
                          >
                            <EyeIcon name="eye" />
                          </Tooltip>
                        </Link>
                      </ActionContainer>
                    )
                  },
                },
              ]

              const filters = [
                {
                  key: 'promo_type',
                  label: 'Tipo de promoción',
                  options: promoOptions,
                },
                {
                  key: 'entity_type',
                  label: 'Entidad',
                  options: entityOptions,
                },
                {
                  key: 'status',
                  label: 'Estados',
                  options: statusOptions,
                },
              ]
              return (
                <React.Fragment>
                  <DataTable
                    indexKey="number"
                    columns={columns}
                    data={nodes}
                    loading={loading}
                    sortBy={''}
                    pageSize={pageSize}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    onSort={this.onSortByChange}
                    onPageSizeChange={this.onPageSizeChange}
                    onAfter={this.onAfter}
                    onBefore={this.onBefore}
                    after={pagination.cursors.after}
                    before={pagination.cursors.before}
                    filters={filters}
                    onFetch={this.onFetch}
                    searchText={s}
                    onSearch={this.onSearch}
                    placeholderSearchBar={
                      'Buscar por código, tipo, nombre, entidad, etc.'
                    }
                    filtersOpened={filtersOpened}
                    toggleFilterSection={this.toggleFilterSection}
                    filterSelected={filterSelected}
                    onFilterSelected={this.onFilterSelected}
                    supplierFromUrl={supplierFromUrl}
                  />
                </React.Fragment>
              )
            }}
          </Query>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default withRouter(promoTable)
