import React from 'react'
import gql from 'graphql-tag'

import PageWrapper from '../../components/PageWrapper'
import Paper from '../../components/Paper'
import { Button } from '../../components/Buttons'
import AssetSelector from '../../components/AssetSelector'
import { Label } from '../../components/Buttons/style'
import * as Grid from '../../components/Grid'
import { Mutation } from 'react-apollo'
import Modal from '../../components/Modals/ModalRoot'

import CategorySelector, {
  CategoryOption,
} from '../../components/Selectors/CategorySelector'

import SupplierSelector, {
  SupplierOption,
} from '../../components/Selectors/SupplierSelector'

import {
  Instruction,
  Title,
  Feedback,
  FeedbackError,
  StyledExportButtonContainer,
} from './style'

import ThemeContext from '../../context/ThemeContext'

interface State {
  asset: Asset
  uploading: boolean
  categories: CategoryOption | CategoryOption[] | null
  suppliers: SupplierOption | SupplierOption[] | null
}

interface Asset {
  type: string
  url: string
  file?: File
}

type ExportProductInput = {
  categories: string[]
  suppliers: string[]
}

const EXPORT_PRODUCTS_MUTATION = gql`
  mutation exportProducts($input: ExportProductsInput!) {
    exportProducts(input: $input)
  }
`

const UPLOAD_UPDATE_FILE = gql`
  mutation uploadUpdateProductFile($file: Upload!) {
    uploadUpdateProductFile(file: $file) {
      url
      fileName
    }
  }
`
class ProductImport extends React.Component<{}, State> {
  state: State = {
    asset: { url: '', type: '' },
    uploading: false,
    categories: [],
    suppliers: [],
  }

  clearFilters = () => {
    this.setState({ categories: [], suppliers: [] })
  }

  handleCategoriesChange = (categories: CategoryOption) => {
    this.setState({ categories })
  }

  handleSuppliersChange = (suppliers: SupplierOption) => {
    this.setState({ suppliers })
  }

  handleAssetsChange = (asset: any) => {
    this.setState({ asset: asset[1] })
  }

  render() {
    const { asset, categories, suppliers } = this.state
    const assetArray = [asset]
    return (
      <ThemeContext.Consumer>
        {notify => (
          <PageWrapper title="Actualización masiva de productos">
            <Paper>
              <Grid.Row>
                <Grid.Column md={12}>
                  <Instruction>
                    Para empezar descargue los productos previamente ingresados
                  </Instruction>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column md={4}>
                  <CategorySelector
                    value={categories}
                    onChange={this.handleCategoriesChange}
                    disabled={false}
                    isMulti={true}
                    isRequired={false}
                  />
                </Grid.Column>
                <Grid.Column md={4}>
                  <SupplierSelector
                    value={suppliers}
                    onChange={this.handleSuppliersChange}
                    isMulti={true}
                    isRequired={false}
                  />
                </Grid.Column>
                <StyledExportButtonContainer md={4}>
                  <Mutation
                    mutation={EXPORT_PRODUCTS_MUTATION}
                    onCompleted={() => {
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message:
                            'Se ha enviado un correo con los productos exportados',
                        })
                      this.clearFilters()
                    }}
                    onError={() => {
                      console.log('error de exportacion')
                    }}
                  >
                    {(exportProducts, { loading, error }) => {
                      return (
                        <Button
                          color="secondary"
                          onClick={() => {
                            const exportProductsFilters: ExportProductInput = {
                              categories: [],
                              suppliers: [],
                            }

                            exportProductsFilters.categories =
                              categories &&
                              categories.map(
                                (category: CategoryOption) =>
                                  category && category['id']
                              )

                            exportProductsFilters.suppliers =
                              suppliers &&
                              suppliers.map(
                                (suppliers: SupplierOption) =>
                                  suppliers && suppliers['id']
                              )

                            exportProducts({
                              variables: {
                                input: exportProductsFilters,
                              },
                            })
                          }}
                        >
                          Exportar productos
                        </Button>
                      )
                    }}
                  </Mutation>
                </StyledExportButtonContainer>
              </Grid.Row>
              <Title>Carga masiva de nuevos productos</Title>
              <Instruction>
                Cargue el archivo que se descargó anteriormente
              </Instruction>
              <Grid.Row>
                <Grid.Column md={4}>
                  <AssetSelector
                    colorButton="primary"
                    textButton="Cargar archivo"
                    disabled={this.state.uploading}
                    accept=".csv"
                    assets={assetArray}
                    onChange={this.handleAssetsChange}
                  />
                </Grid.Column>
                <Grid.Column md={3}>
                  {this.state.asset.file && (
                    <Modal>
                      {({ openModal }) => {
                        return (
                          <Mutation
                            mutation={UPLOAD_UPDATE_FILE}
                            onCompleted={() => {
                              this.setState({ uploading: false })
                            }}
                            onError={() => {
                              openModal('ALERT', {
                                header: {
                                  title: 'ALERTA',
                                },
                                description:
                                  'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                type: 'fail',
                              })
                            }}
                          >
                            {(
                              uploadUpdateProductFile,
                              { loading, error, called }
                            ) => (
                              <React.Fragment>
                                <Button
                                  color="secondary"
                                  disabled={loading}
                                  onClick={async () => {
                                    if (asset.file) {
                                      this.setState({ uploading: true })
                                      try {
                                        await uploadUpdateProductFile({
                                          variables: { file: asset.file },
                                        })
                                      } catch (error) {
                                        throw new Error(error)
                                      }
                                    }
                                  }}
                                >
                                  {loading
                                    ? 'Subiendo archivo...'
                                    : 'Subir Archivo'}
                                </Button>
                                {called &&
                                  !loading &&
                                  (!error ? (
                                    <Feedback>
                                      Archivo importado correctamente. Llegará
                                      un correo con detalles más específicos
                                    </Feedback>
                                  ) : (
                                    <FeedbackError>
                                      La acción solicitada no se pudo completar.
                                      Intenténtelo mas tarde por favor.
                                    </FeedbackError>
                                  ))}
                              </React.Fragment>
                            )}
                          </Mutation>
                        )
                      }}
                    </Modal>
                  )}
                </Grid.Column>
                <Grid.Column md={4}>
                  {this.state.asset.file && (
                    <Label>{this.state.asset.file.name}</Label>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Paper>
          </PageWrapper>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default ProductImport
