import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import qs from 'qs'

import { PAGE_SIZE_OPTIONS } from '../../../../config'
import DataTable from '../../../../components/DataTable'
import { parseCursorPageParams } from '../../../../helpers/params'
import Modal from '../../../../components/Modals/ModalRoot'
import { Tooltip } from '../../../../components/FormElements'
import { EyeIcon } from '../style'

import { ActionContainer, ConfirmAction } from '../../../../components/Globals'

import PurchaseDetailsModal from './PurchaseDetailsModal'

const GET_UNPROCESSED_PURCHASES_QUERY = gql`
  query getUnprocessedPurchasesQuery(
    $options: PurchasesOptions!
    $filters: PurchasesFilters!
  ) {
    unprocessedPurchases(options: $options, filters: $filters) {
      nodes {
        id
        productName
        reference
        cost
        total
        weight
        width
        height
        length
        createdAt
        customer {
          firstName
          lastName
          businessName
        }
        supplier {
          legalName
        }
        lineItem {
          variation {
            options {
              name
              feature {
                name
              }
            }
          }
        }
      }
      pagination {
        limit
        cursors {
          before
          after
        }
      }
    }
  }
`

const GET_PURCHASE_SUPPLIERS_QUERY = gql`
  query getPurchaseSuppliersQuery(
    $options: PurchaseSuppliersOptions!
    $filters: PurchaseSuppliersFilters!
  ) {
    purchaseSuppliers(options: $options, filters: $filters) {
      nodes {
        supplier {
          id
          legalName
        }
      }
    }
  }
`

type PurchaseNode = Record<string, any>

interface Data {
  unprocessedPurchases: {
    nodes: PurchaseNode[]
    pagination: {
      limit: number
      cursors: {
        before: string
        after: string
      }
    }
  }
}

interface SupplierOption {
  id: string
  legalName: string
}

interface PurchaseSupplier {
  supplier: SupplierOption
}

interface SupplierData {
  purchaseSuppliers: {
    nodes: PurchaseSupplier[]
  }
}

type FilterOptionType = Record<string, any>

interface PurchaseTableState {
  filtersOpened: boolean
  filterSelected: FilterOptionType | null
  supplierFromUrl: string
}

class PurchaseTable extends React.Component<
  RouteComponentProps<{}>,
  PurchaseTableState
> {
  state: PurchaseTableState = {
    filtersOpened: false,
    filterSelected: null,
    supplierFromUrl: '',
  }

  componentDidMount() {
    const { supplier } = parseCursorPageParams(
      this.props.location.search.slice(1)
    )

    if (supplier) {
      this.setState({ filtersOpened: true, supplierFromUrl: supplier })
    }
  }

  toggleFilterSection = () => {
    const { filtersOpened } = this.state
    this.setState({ filtersOpened: !filtersOpened })
  }

  extractPageParams = () => {
    const {
      page_size,
      sort_by,
      after,
      before,
      supplier,
      s,
    } = parseCursorPageParams(this.props.location.search.slice(1))
    return {
      page_size,
      sort_by,
      after,
      before,
      supplier,
      s,
    }
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText
    params.after = ''
    params.before = ''
    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  onAfter = (after: string | undefined) => {
    const params = this.extractPageParams()
    params.after = after

    if (params.before) {
      delete params.before
    }

    this.updatePageParams(params)
  }

  onBefore = (before: string | undefined) => {
    const params = this.extractPageParams()
    params.before = before

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onFetch = (formatParams: any) => {
    const params = this.extractPageParams()

    const { filters } = formatParams

    if (filters && filters.supplier) {
      params.supplier = filters.supplier.value
    }

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }
    if (params.s) {
      delete params.s
    }

    this.updatePageParams(params)
  }

  onFilterSelected = (
    index: number,
    selected: FilterOptionType | null | undefined
  ) => {
    this.setState({ filterSelected: { index, selected } })
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  render() {
    const { filtersOpened, filterSelected, supplierFromUrl } = this.state

    const { page_size, after, before, supplier, s } = this.extractPageParams()

    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

    return (
      <Query
        variables={{
          options: {
            limit: pageSize,
            after,
            before,
          },
          filters: { supplier, s },
        }}
        query={GET_UNPROCESSED_PURCHASES_QUERY}
      >
        {({
          loading: PurchasesLoading,
          error: PurchasesError,
          data: PurchasesData,
        }: QueryResult<Data, any>) => (
          <Query
            variables={{
              options: { limit: 50 },
              filters: {},
            }}
            query={GET_PURCHASE_SUPPLIERS_QUERY}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }: QueryResult<SupplierData, any>) => {
              if (PurchasesLoading) {
                return <p>Loading...</p>
              }
              if (PurchasesError) {
                return <p>Error</p>
              }
              if (!PurchasesData) {
                return <p>No Data</p>
              }
              if (loading) {
                return <p>Loading...</p>
              }
              if (error) {
                return <p>Error</p>
              }
              if (!data) {
                return <p>No Data</p>
              }

              const { nodes, pagination } = PurchasesData.unprocessedPurchases

              const { purchaseSuppliers } = data

              const supplierOptions: FilterOptionType[] = []
              supplierOptions.push({
                label: 'Todos los proveedores',
                value: 'all',
              })

              supplierOptions.push(
                ...purchaseSuppliers.nodes.map(purchaseSupplier => ({
                  label: purchaseSupplier.supplier.legalName,
                  value: purchaseSupplier.supplier.id,
                }))
              )

              const columns = [
                { header: 'Transacción', key: 'id', sortable: false },
                {
                  header: 'Producto',
                  key: 'productName',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    return purchase && purchase.productName
                  },
                },
                {
                  header: 'Referencia',
                  key: 'variationReference',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    return purchase && purchase.reference
                  },
                },
                {
                  // TODO: Put the correct value of variation cost
                  header: 'Costo',
                  key: 'variationCost',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    return purchase && purchase.cost
                  },
                },
                {
                  header: 'Precio',
                  key: 'variationPrice',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    return purchase && purchase.total
                  },
                },
                {
                  header: 'Millas',
                  key: 'variationMileCost',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    return ''
                  },
                },
                {
                  header: 'Socio',
                  key: 'orderClient',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    return (
                      purchase.customer &&
                      purchase.customer.businessName +
                        purchase.customer.firstName +
                        ' ' +
                        purchase.customer.lastName
                    )
                  },
                },
                {
                  header: 'Proveedor',
                  key: 'supplierName',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    return purchase.supplier && purchase.supplier.legalName
                  },
                },
                {
                  header: 'Fecha',
                  key: 'createdAt',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    const d = new Date(purchase.createdAt)
                    const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
                    const mm =
                      (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
                    const yyyy = d.getFullYear()
                    const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
                    const min =
                      (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
                    const sec =
                      (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
                    const date =
                      yyyy +
                      '-' +
                      mm +
                      '-' +
                      dd +
                      ' ' +
                      hour +
                      ':' +
                      min +
                      ':' +
                      sec
                    return date
                  },
                },
                {
                  header: 'Acciones',
                  key: 'actions',
                  width: 99,
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => (
                    <ActionContainer>
                      <Modal>
                        {({ openModal }) => {
                          return (
                            <ConfirmAction
                              onClick={() =>
                                openModal('DETAILS', {
                                  header: {
                                    title: 'Detalle del producto redimido',
                                    textAlign: 'left',
                                  },
                                  modalSize: 'lg',
                                  description: (
                                    <PurchaseDetailsModal purchase={purchase} />
                                  ),
                                })
                              }
                            >
                              <Tooltip
                                id={`show-${purchase.id}`}
                                message="Mostrar más detalles"
                              >
                                <EyeIcon name="eye" />
                              </Tooltip>
                            </ConfirmAction>
                          )
                        }}
                      </Modal>
                    </ActionContainer>
                  ),
                },
              ]

              return (
                <React.Fragment>
                  <DataTable
                    indexKey="id"
                    columns={columns}
                    data={nodes}
                    loading={loading}
                    sortBy={''}
                    pageSize={pageSize}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    onSort={this.onSortByChange}
                    onPageSizeChange={this.onPageSizeChange}
                    onAfter={this.onAfter}
                    onBefore={this.onBefore}
                    after={pagination.cursors.after}
                    before={pagination.cursors.before}
                    filters={[
                      {
                        key: 'supplier',
                        label: 'Proveedores',
                        options: supplierOptions,
                      },
                    ]}
                    onFetch={this.onFetch}
                    searchText={s}
                    onSearch={this.onSearch}
                    placeholderSearchBar={
                      'Buscar por transacción, referencia de proveedor'
                    }
                    filtersOpened={filtersOpened}
                    toggleFilterSection={this.toggleFilterSection}
                    filterSelected={filterSelected}
                    onFilterSelected={this.onFilterSelected}
                    supplierFromUrl={supplierFromUrl}
                  />
                </React.Fragment>
              )
            }}
          </Query>
        )}
      </Query>
    )
  }
}

export default withRouter(PurchaseTable)
