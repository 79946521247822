import styled from 'styled-components'

const joditTextContainerClassName = 'jodit_wysiwyg'
const joditTopClassName = 'jodit_toolbar'
const joditBottomClassName = 'jodit_statusbar'

export const StyledEditorWrapper = styled.div`
  .${joditTopClassName} {
    border-radius: 20px 20px 0px 0px;
  }
  .${joditBottomClassName} {
    border-radius: 0px 0px 20px 20px;
  }
  .${joditTextContainerClassName} {
    color: #000;
  }
`

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #858585;
  cursor: 'pointer';
  display: block;
`

export const LabelWrapper = styled.div`
  margin: 0 0 6px 8px;
  vertical-align: middle;
  align-self: center;
`

export const RequiredMark = styled.span`
  color: #18b0f7;
  margin-left: 3px;
`
