import styled from 'styled-components'
import Icon from '../../../components/Icon'

export const TabList = styled.ol`
  padding-left: 0;
  li {
    &:first-child {
      border-radius: 20px 0 0 0;
    }
    &:last-child {
      border-radius: 0 20px 0 0;
    }
  }
`
export const Paper = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
`
export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const EditIcon = styled(Icon)`
  color: #18b0f7;
  font-size: 20px;
`

export const DeleteIcon = styled(Icon)`
  color: #454545;
  font-size: 20px;
`

export const EyeIcon = styled(Icon)`
  color: #454545;
  font-size: 20px;
`
