import React from 'react'
import { Input, Error } from '../../../../components/FormElements'
import { Spacer } from '../../../../components/Globals'
import { CloseButton, CloseIcon } from '../style'
import { Field } from '../../../../helpers/validations'

interface Props {
  index: number
  contactName: Field
  contactEmail: Field
  onContactNameChange: (contactIndex: number, contactName: string) => void
  onContactEmailChange: (contactIndex: number, contactEmail: string) => void
  onCloseClick: (contactIndex: number) => void
}

const ContactGroup = ({
  index,
  contactName,
  contactEmail,
  onContactNameChange,
  onContactEmailChange,
  onCloseClick,
}: Props) => (
  <React.Fragment>
    <CloseButton
      onClick={() => {
        onCloseClick(index)
      }}
    >
      <CloseIcon name="cancel-circled" />
    </CloseButton>
    <Input
      required
      label="Nombre"
      id={`name${index}`}
      type="text"
      value={contactName.value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onContactNameChange(index, e.target.value)
      }
      error={contactName.error}
    />
    {contactName.error ? <Error>{contactName.errorText}</Error> : <Spacer />}
    <Input
      id={`email${index}`}
      required
      label="Email"
      type="text"
      value={contactEmail.value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onContactEmailChange(index, e.target.value)
      }
      error={contactEmail.error}
    />
    {contactEmail.error ? <Error>{contactEmail.errorText}</Error> : <Spacer />}
  </React.Fragment>
)

export default ContactGroup
