import React from 'react'
import {
  SortTopIconContainer,
  SortBottomIconContainer,
  SortIcon,
} from '../style'

interface SortingIndicatorProps {
  direction?: 'asc' | 'desc' | null
}

const SortingIndicator = ({ direction }: SortingIndicatorProps) => (
  <div>
    {(direction === 'asc' || direction === null) && (
      <SortTopIconContainer active={direction === 'asc'}>
        <SortIcon name="up-dir" />
      </SortTopIconContainer>
    )}
    {(direction === 'desc' || direction === null) && (
      <SortBottomIconContainer active={direction === 'desc'}>
        <SortIcon name="down-dir" />
      </SortBottomIconContainer>
    )}
  </div>
)

export default SortingIndicator
