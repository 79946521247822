import React from 'react'
import { RouteComponentProps } from 'react-router'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import { Query, QueryResult, Mutation } from 'react-apollo'

import { PAGE_SIZE_OPTIONS } from '../../../../config'
import { ActionContainer } from '../style'
import DataTable from '../../../../components/DataTable'
import { Input } from '../../../../components/FormElements'
import { parsePageParams } from '../../../../helpers/params'
import Modal from '../../../../components/Modals/ModalRoot'

import ThemeContext from '../../../../context/ThemeContext'

const GET_ITEMS_PURCHASE_ORDER_QUERY = gql`
  query getPurchaseOrderItemsQuery(
    $id: ID!
    $options: PurchaseOrderItemsOptions!
    $filters: PurchaseOrderItemsFilters!
  ) {
    purchaseOrderItems(id: $id, options: $options, filters: $filters) {
      nodes {
        id
        invoiceNumber
        trackingCode
        productName
        reference
        cost
        total
        weight
        width
        height
        length
        createdAt
        customer {
          firstName
          lastName
          businessName
        }
      }
      supplier {
        courier {
          code
        }
      }
      pagination {
        total
      }
    }
  }
`

const EDIT_PURCHASE_MUTATION = gql`
  mutation editPurchase($input: EditPurchaseInput!) {
    editPurchase(input: $input)
  }
`

type PurchaseNode = Record<string, any>
type SupplierNode = Record<string, any>

interface Data {
  purchaseOrderItems: {
    nodes: PurchaseNode[]
    supplier: SupplierNode
    pagination: {
      total: number
    }
  }
}

interface RouteParams {
  id: string
  action: string
  history: string
  location: string
}

interface PurchaseOrderItemsTableState {
  invoiceNumber: string[]
  trackingCode: string[]
}

class ProcessOrderItemsTable extends React.Component<
  RouteComponentProps<RouteParams>,
  PurchaseOrderItemsTableState
> {
  state: PurchaseOrderItemsTableState = {
    invoiceNumber: [],
    trackingCode: [],
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  extractPageParams = () => {
    const { page = 1, page_size, s, sort_by } = parsePageParams(
      window.location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
    }
  }

  onPageChange = (page: number) => {
    const params = this.extractPageParams()

    params.page = page

    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    this.updatePageParams(params)
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText
    params.page = 1
    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  handleInvoiceNumberChange = (id: string) => ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    let { invoiceNumber } = this.state
    invoiceNumber[id] = value
    this.setState({ invoiceNumber })
  }

  handleTrackingCodeChange = (index: string) => ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    let { trackingCode } = this.state
    trackingCode[index] = value
    this.setState({ trackingCode })
  }

  render() {
    let { invoiceNumber, trackingCode } = this.state

    const { page_size, sort_by, s, page } = this.extractPageParams()

    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

    return (
      <ThemeContext.Consumer>
        {({ forceShowNotification }) => (
          <Query
            variables={{
              id: this.props.match.params.id,
              options: {
                limit: pageSize,
                offset: page ? (page - 1) * pageSize : 0,
                sortBy: sort_by,
              },
              filters: { s },
            }}
            query={GET_ITEMS_PURCHASE_ORDER_QUERY}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, refetch }: QueryResult<Data, any>) => {
              if (loading) {
                return <p>Loading...</p>
              }
              if (error) {
                return <p>Error</p>
              }
              if (!data) {
                return <p>No Data</p>
              }
              if (!data.purchaseOrderItems) {
                return <p>No Items</p>
              }

              const { nodes, supplier, pagination } = data.purchaseOrderItems

              const defaultColumns = [
                {
                  header: 'Producto',
                  key: 'productName',
                  sortable: false,
                },
                {
                  header: 'Referencia',
                  key: 'reference',
                  sortable: false,
                },
                {
                  header: 'Costo',
                  key: 'total',
                  sortable: false,
                },
                {
                  header: 'Cliente',
                  key: 'customer',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    return (
                      purchase.customer.businessName +
                      purchase.customer.firstName +
                      ' ' +
                      purchase.customer.lastName
                    )
                  },
                },
              ]
              const internationalSupplier = [
                {
                  header: '# Factura',
                  key: 'invoice',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    return (
                      <Modal>
                        {({ openModal }) => {
                          return (
                            <Mutation
                              mutation={EDIT_PURCHASE_MUTATION}
                              onCompleted={() => {
                                refetch().finally(() => {
                                  forceShowNotification &&
                                    forceShowNotification({
                                      type: 'ok',
                                      message:
                                        'Producto actualizado correctamente',
                                    })
                                })
                              }}
                              onError={() => {
                                openModal('ALERT', {
                                  header: {
                                    title: 'ALERTA',
                                  },
                                  description:
                                    'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                  type: 'fail',
                                })
                              }}
                            >
                              {(editPurchase, { loading, called, error }) => (
                                <ActionContainer>
                                  <Input
                                    id="invoiceNumber"
                                    value={
                                      invoiceNumber[purchase.id] ||
                                      purchase.invoiceNumber
                                    }
                                    onChange={this.handleInvoiceNumberChange.call(
                                      {},
                                      purchase.id
                                    )}
                                    onBlur={() => {
                                      if (
                                        invoiceNumber[purchase.id] !=
                                          undefined &&
                                        invoiceNumber[purchase.id] !=
                                          purchase.invoiceNumber
                                      ) {
                                        editPurchase({
                                          variables: {
                                            input: {
                                              id: purchase.id,
                                              invoiceNumber:
                                                invoiceNumber[purchase.id],
                                            },
                                          },
                                        })
                                      }
                                    }}
                                    type="text"
                                  />
                                </ActionContainer>
                              )}
                            </Mutation>
                          )
                        }}
                      </Modal>
                    )
                  },
                },
                {
                  header: '# Tracking',
                  key: 'tracking',
                  sortable: false,
                  Cell: (purchase: PurchaseNode) => {
                    return (
                      <Modal>
                        {({ openModal }) => {
                          return (
                            <Mutation
                              mutation={EDIT_PURCHASE_MUTATION}
                              onCompleted={() => {
                                refetch().finally(() => {
                                  forceShowNotification &&
                                    forceShowNotification({
                                      type: 'ok',
                                      message:
                                        'Producto actualizado correctamente',
                                    })
                                })
                              }}
                              onError={() => {
                                openModal('ALERT', {
                                  header: {
                                    title: 'ALERTA',
                                  },
                                  description:
                                    'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                  type: 'fail',
                                })
                              }}
                            >
                              {(editPurchase, { loading, called, error }) => (
                                <ActionContainer>
                                  <Input
                                    id="trackingCode"
                                    value={
                                      trackingCode[purchase.id] ||
                                      purchase.trackingCode
                                    }
                                    onChange={this.handleTrackingCodeChange.call(
                                      {},
                                      purchase.id
                                    )}
                                    onBlur={() => {
                                      if (
                                        trackingCode[purchase.id] !=
                                          undefined &&
                                        trackingCode[purchase.id] !=
                                          purchase.trackingCode
                                      ) {
                                        editPurchase({
                                          variables: {
                                            input: {
                                              id: purchase.id,
                                              trackingCode:
                                                trackingCode[purchase.id],
                                            },
                                          },
                                        })
                                      }
                                    }}
                                    type="text"
                                  />
                                </ActionContainer>
                              )}
                            </Mutation>
                          )
                        }}
                      </Modal>
                    )
                  },
                },
              ]

              let columns: any[]

              if (supplier.courier.code == 'laar') {
                columns = [...defaultColumns, ...internationalSupplier]
              } else {
                columns = defaultColumns
              }

              return (
                <React.Fragment>
                  <DataTable
                    indexKey="id"
                    columns={columns}
                    data={nodes}
                    loading={loading}
                    totalItemsCount={pagination.total}
                    sortBy={sort_by}
                    page={page || 1}
                    pageSize={pageSize}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    searchText={s}
                    onSort={this.onSortByChange}
                    onPageChange={this.onPageChange}
                    onPageSizeChange={this.onPageSizeChange}
                    onSearch={this.onSearch}
                    placeholderSearchBar={
                      'Buscar por cliente, producto, referencia'
                    }
                  />
                </React.Fragment>
              )
            }}
          </Query>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default withRouter(ProcessOrderItemsTable)
