import styled from 'styled-components'

export const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`

export const Container = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
`
export const Header = styled.div`
  background: #858585;
  height: 40px;
  border-radius: 20px 20px 0 0;
  color: #ffffff;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 12.5px;

  @media screen and (max-width: 576px) {
    display: none;
  }
`

export const Pading = styled.div`
  padding: 20px;
`
