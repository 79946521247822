import styled from 'styled-components'
import { Button } from '../../../components/Buttons'
import Icon from '../../../components/Icon'
import { CreatableSelect } from '../../../components/FormElements'

export const InputTitleWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
`

export const VariationsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`

export const VariationsButton = styled(Button)`
  width: 100%;
`

export const Summary = styled.div`
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #18b0f7;
`

export const Description = styled.div`
  height: 13px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #858585;
`
export const CloseButton = styled.button`
  font-size: 14px;
  position: absolute;
  top: -14px;
  right: -24px;
  cursor: pointer;
  color: #fff;
  background: none;
  border: none;
  display: block;
  z-index: 1;
`
export const AddFeatureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 188px;
  height: 100%;
  flex-direction: column;
  color: #858585;
`
export const AddFeatureText = styled.span`
  margin-top: 15px;
`

export const CloseIcon = styled(Icon)`
  font-size: 34px;
  color: #ff2d2d;
`
const className = 'old-elements'
interface Props {
  type?: string
  color?: string
}
export const OldElements = styled(CreatableSelect).attrs<Props>({
  className,
})`
  ${'div'} {
    background-color: transparent;
    border: none;
    div {
      padding: 0.2rem 0.5rem;
      :last-child {
        display: none;
      }
    }
    div > div {
      background-color: #858585;
      div {
        :nth-child(even) {
          display: none;
        }
      }
    }
  }
`
