import React from 'react'
import { Link } from 'react-router-dom'

import Datatable from '../../../../components/DataTable'
import { PAGE_SIZE_OPTIONS } from '../../../../config'
import { Wrapper } from '../../../../components/Modals/style'
import { Tooltip } from '../../../../components/FormElements'
import { ActionContainer, EditIcon } from '../../../../components/Globals'
import { Balance } from '../../Form'
import Modal from '../../../../components/Modals/ModalRoot'
import TransactionsActionsModal from './TransacionsActionsModal'
import { ConfirmAction } from '../style'

type BalanceNode = Record<string, any>

type Props = {
  balances: Balance[]
  refetch: any
}

export default function CommerceBalanceTable({ balances, refetch }: Props) {
  const columns = [
    {
      header: 'Moneda',
      key: 'currency_name',
      sortable: false,
      Cell: (balance: BalanceNode) => <>{balance.currency.name}</>,
    },
    {
      header: 'Monto inicial',
      key: 'initialAmount',
      Cell: (balance: BalanceNode) => {
        let numberFormat = new Intl.NumberFormat('en-EN').format(
          balance.initialAmount
        )
        return <>{numberFormat}</>
      },
    },
    {
      header: 'Total',
      key: 'total',
      Cell: (balance: BalanceNode) => {
        let numberFormat = new Intl.NumberFormat('en-EN').format(balance.total)
        return <>{numberFormat}</>
      },
    },
    {
      header: 'Deuda',
      key: 'debt',
      Cell: (balance: BalanceNode) => {
        if (Number(balance.total) >= 0) {
          return <>{'$ 0'}</>
        }
        let numberFormat = new Intl.NumberFormat('en-EN').format(
          Number(balance.total) * Number(balance.agreements[0].postpaidPrice)
        )
        return <>{'$ ' + numberFormat}</>
      },
    },

    {
      header: 'Acciones',
      key: 'actions',
      width: 99,
      sortable: false,
      Cell: (balance: BalanceNode) => (
        <ActionContainer>
          <Link to={`/reports/balance/${balance.id}`}>
            <Tooltip id={`report-${balance.id}`} message="Ver Reportes">
              <EditIcon name="file-excel" />
            </Tooltip>
          </Link>
          <Modal>
            {({ openModal }) => {
              return (
                <ConfirmAction
                  onClick={() =>
                    openModal('FORM', {
                      header: {},
                      description: (
                        <TransactionsActionsModal
                          balance={balance}
                          refetch={refetch}
                        />
                      ),
                      modalSize: 'lg',
                      showCloseTop: true,
                    })
                  }
                >
                  <Tooltip id={`buy/pay-${balance.id}`} message="Pago/Compra">
                    <EditIcon name="plus" />
                  </Tooltip>
                </ConfirmAction>
              )
            }}
          </Modal>
        </ActionContainer>
      ),
    },
  ]

  return (
    <Wrapper>
      <Datatable
        indexKey="id"
        columns={columns}
        data={balances}
        loading={false}
        page={1}
        onSort={() => {}}
        pageSize={1}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        onPageSizeChange={() => {}}
      />
    </Wrapper>
  )
}
