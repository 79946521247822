import React from 'react'
import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import PqrsTable from './components/PqrsTable'

export const PqrsList = () => (
  <PageWrapper title="Peticiones, Quejas, Reclamos y Sugerencias (PQRS)">
    <Paper>
      <PqrsTable />
    </Paper>
  </PageWrapper>
)
