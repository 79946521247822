import React, { useContext, useState, useEffect } from 'react'
import { Tab, TabProps } from './Tab'
import { Panel, PanelProps } from './Panel'

interface Context {
  activeTab: string
  width: number
  last: string
  first: string
  setActiveTab: (label: string) => void
}

interface Composition {
  Tab: React.FC<TabProps>
  Panel: React.FC<PanelProps>
}

const TabsContext = React.createContext<Context | undefined>(undefined)

const Tabs: React.FC & Composition = (props: any) => {
  const [activeTab, setActiveTab] = useState()
  const [width, setWidth] = useState(0)
  const [last, setLast] = useState('')
  const [first, fisrtLast] = useState('')
  const childrens = props.children

  useEffect(() => {
    if (childrens && childrens.props && childrens.props.children.length > 0) {
      const lenght = childrens.props.children.length
      setActiveTab(childrens.props.children[0].props.label)
      setLast(childrens.props.children[lenght - 1].props.label)
      fisrtLast(childrens.props.children[0].props.label)
    }
  }, [])

  if (childrens && childrens.props && width <= 0) {
    setWidth(100 / (childrens.props.children.length / 2))
  }

  const memoizedContextValue = React.useMemo(
    () => ({
      activeTab,
      setActiveTab,
      width,
      last,
      first,
    }),
    [activeTab, setActiveTab, width, last, first]
  )

  return (
    <TabsContext.Provider value={memoizedContextValue}>
      {props.children}
    </TabsContext.Provider>
  )
}

export const useTabs = (): Context => {
  const context = useContext(TabsContext)
  if (!context) {
    throw new Error('This component must be used within a <Tabs> component.')
  }
  return context
}

Tabs.Tab = Tab
Tabs.Panel = Panel

export { Tabs }
