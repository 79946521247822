import React from 'react'
import * as Grid from '../Grid'

import { Container, Header, Pading, AlignRight } from './style'
interface Props {
  children?: React.ReactNode
}

const VariationPaper = ({ children }: Props) => (
  <Container>
    <Header>
      <Grid.Row>
        <Grid.Column md={2}>
          <AlignRight>
            <span>Nombre</span>
          </AlignRight>
        </Grid.Column>
        <Grid.Column md={10}>
          <span>Valor</span>
        </Grid.Column>
      </Grid.Row>
    </Header>
    <Pading>{children}</Pading>
  </Container>
)

export default VariationPaper
