import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { Query, QueryResult } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import qs from 'qs'

import Modal from '../../../../components/Modals/ModalRoot'
import DataTable from '../../../../components/DataTable'
import { Tooltip } from '../../../../components/FormElements'
import { STATUS_OPTIONS, PAGE_SIZE_OPTIONS } from '../../../../config'
import { parsePageParams } from '../../../../helpers/params'
import { Select } from '../../../../components/FormElements'
import Loading from '../../../../components/Loader'

import { ActionContainer, EditIcon } from '../style'
import ThemeContext from '../../../../context/ThemeContext'

const EDIT_VARIATION_MUTATION = gql`
  mutation editVariationMutation($input: EditVariationInput!) {
    editVariation(input: $input)
  }
`

const GET_VARIATIONS_QUERY = gql`
  query getVariationsQuery(
    $options: VariationsOptions!
    $filters: VariationsFilters!
  ) {
    variations(options: $options, filters: $filters) {
      nodes {
        id
        reference
        status
        inventory {
          id
          sku
          localStock
          storageStock
        }
        product {
          id
          name
        }
      }
      pagination {
        total
      }
    }
  }
`

type VariationNode = { [key: string]: any }

interface Data {
  variations: {
    nodes: VariationNode[]
    pagination: {
      total: number
    }
  }
}

function VariationTable({ history, location }: RouteComponentProps) {
  const { forceShowNotification } = useContext(ThemeContext)

  const { page, page_size, s, sort_by } = extractPageParams()

  const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

  function updatePageParams(params: object) {
    history.push({
      pathname: history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  function extractPageParams() {
    const { page, page_size, s, sort_by } = parsePageParams(
      location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
    }
  }

  function onPageChange(page: number) {
    const params = extractPageParams()

    params.page = page
    updatePageParams(params)
  }

  function onPageSizeChange(pageSize: number) {
    const params = extractPageParams()

    params.page_size = pageSize
    updatePageParams(params)
  }

  function onSearch(searchText: string | undefined) {
    const params = extractPageParams()

    params.s = searchText
    params.page = 1
    updatePageParams(params)
  }

  function onSortByChange(sortBy: string | undefined) {
    const params = extractPageParams()

    params.sort_by = sortBy
    updatePageParams(params)
  }

  var flag = false

  return (
    <Query
      variables={{
        options: {
          limit: pageSize,
          offset: page ? (page - 1) * pageSize : 0,
          sortBy: sort_by,
        },
        filters: {
          s,
        },
      }}
      query={GET_VARIATIONS_QUERY}
      fetchPolicy="network-only"
    >
      {({
        loading,
        error,
        data: dataQuery,
        refetch,
      }: QueryResult<Data, any>) => {
        if (loading) {
          return <p>Loading...</p>
        }

        if (error) {
          return <p>Error</p>
        }

        if (!dataQuery) {
          return <p>No Data</p>
        }

        const columns = [
          {
            header: 'Sku',
            key: 'sku',
            Cell: (variation: VariationNode) => {
              return variation.inventory && variation.inventory.sku
            },
          },
          {
            header: 'Nombre del producto',
            key: 'name',
            sortable: false,
            Cell: (variation: VariationNode) => {
              return variation.product && variation.product.name
            },
          },
          {
            header: 'Referencia',
            key: 'reference',
            sortable: false,
          },
          {
            header: 'Stock',
            key: 'stock',
            Cell: (variation: VariationNode) => {
              return (
                variation.inventory.localStock +
                variation.inventory.storageStock
              )
            },
          },
          {
            header: 'Estado',
            key: 'status',
            width: 210,
            Cell: (variation: VariationNode) => {
              const status = { value: variation.status, label: '' }
              if (variation.status === 'active') {
                status.label = 'Activo'
              } else if (variation.status === 'inactive') {
                status.label = 'Inactivo'
              } else {
                status.label = 'Descontinuado'
              }
              return (
                <Modal>
                  {({ openModal }) => {
                    return (
                      <Mutation
                        mutation={EDIT_VARIATION_MUTATION}
                        onCompleted={() => {
                          refetch().finally(() => {
                            flag = true
                            forceShowNotification &&
                              forceShowNotification({
                                type: 'ok',
                                message: 'Variación actualizada correctamente',
                              })
                          })
                        }}
                        onError={() => {
                          openModal('ALERT', {
                            header: {
                              title: 'ALERTA',
                            },
                            description:
                              'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                            type: 'fail',
                          })
                        }}
                      >
                        {(
                          editStatusVariation,
                          { loading: loadingEdit, error }
                        ) => {
                          return (
                            <div>
                              {loadingEdit || loading || flag ? (
                                <Loading />
                              ) : (
                                <Select
                                  id="status"
                                  options={STATUS_OPTIONS}
                                  value={status}
                                  onChange={s => {
                                    if (s && s['value'] != status.value) {
                                      flag = true
                                      editStatusVariation({
                                        variables: {
                                          input: {
                                            status: s && s['value'],
                                            variationId: variation.id,
                                          },
                                        },
                                      }).then(() => {
                                        flag = false
                                      })
                                    }
                                  }}
                                />
                              )}
                            </div>
                          )
                        }}
                      </Mutation>
                    )
                  }}
                </Modal>
              )
            },
          },
          {
            header: 'Acciones',
            key: 'actions',
            width: 99,
            sortable: false,
            Cell: (variation: VariationNode) => (
              <ActionContainer>
                <Link to={`/variations/${variation.id}/edit`}>
                  <Tooltip id={`edit-${variation.id}`} message="Editar">
                    <EditIcon name="pencil" />
                  </Tooltip>
                </Link>
              </ActionContainer>
            ),
          },
        ]

        var { nodes, pagination } = dataQuery.variations

        return (
          <DataTable
            indexKey="id"
            columns={columns}
            data={nodes}
            loading={loading}
            totalItemsCount={pagination.total}
            sortBy={sort_by}
            page={page || 1}
            pageSize={pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            searchText={s}
            onSort={onSortByChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSearch={onSearch}
            placeholderSearchBar={'Buscar por referencia, nombre de producto'}
          />
        )
      }}
    </Query>
  )
}

export default withRouter(VariationTable)
