import styled from 'styled-components'
import { Button } from '../Buttons'
import Icon from '../Icon'

import sizes from '../../styles/media'

export const Spacer = styled.span`
  width: 30px;
  height: 30px;
  display: block;
`

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`

export const PageTopSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  & a {
    width: 100%;
  }

  @media ${sizes.sm} {
    & a {
      width: auto;
    }
  }
`

export const CreateButton = styled(Button)`
  width: 100%;

  @media ${sizes.sm} {
    width: auto;
  }
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const EditIcon = styled(Icon)`
  color: #18b0f7;
  font-size: 20px;
`
export const DeleteIcon = styled(Icon)`
  color: #454545;
  font-size: 20px;
`

export const ConfirmAction = styled.div`
  cursor: pointer;
`
export const SaveButton = styled(Button)`
  width: 100%;
  @media ${sizes.sm} {
    width: auto;
  }
`
