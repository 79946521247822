import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

interface Props {
  expanded: boolean
}

export const MainContent = styled.main<Props>`
  background: #f1f4f8;
  flex: 1;

  transition: 0.35s;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  ${props => {
    if (props.expanded) {
      return `
        @media (min-width: 992px) {
          margin-left: 280px;
        }

        @media (max-width: 991px) {
          transform: translate3d(280px,0,0);
        }
      `
    }

    return `
      @media (min-width: 992px) {
        margin-left: 100px;
      }
    `
  }};
`

export const TopSection = styled.section`
  background: #ffffff;
  box-shadow: 0 0 4px 0 #c5d0de;
  padding: 15px 20px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;

  display: flex;
  align-items: center;
`

export const PageTitle = styled.h1`
  display: inline-block;
  padding-left: 20px;
  margin: 0;
  font-size: 30px;
  font-weight: 300;
  color: #858585;
  vertical-align: middle;
`

export const Content = styled.div`
  padding: 20px;
`
