import React from 'react'
import { RouteComponentProps } from 'react-router'
import gql from 'graphql-tag'
import qs from 'qs'
import { Query, QueryResult, Mutation } from 'react-apollo'
import { withRouter, Link } from 'react-router-dom'
import { PAGE_SIZE_OPTIONS } from '../../../../config'
import {
  ActionContainer,
  WiewIcon,
  ForwardIcon,
  ConfirmAction,
  ShowItmesIcon,
  Semaphore,
} from '../style'
import DataTable from '../../../../components/DataTable'
import { Tooltip } from '../../../../components/FormElements'
import { parseCursorPageParams } from '../../../../helpers/params'
import Modal from '../../../../components/Modals/ModalRoot'
import Loading from '../../../../components/Loader'

import ThemeContext from '../../../../context/ThemeContext'
import PurchaseOrderActionsModal from './PurchaseOrderActionsModal'

const GET_PURCHASEORDER_QUERY = gql`
  query getPurchaseOrders(
    $options: PurchaseOrdersOptions!
    $filters: PurchaseOrdersFilters!
  ) {
    purchaseOrders(options: $options, filters: $filters) {
      nodes {
        id
        number
        createdAt
        documentUrl
        semaphore
        supplier {
          id
          businessName
          tagProducts
          courier {
            id
            name
          }
        }
      }
      pagination {
        limit
        cursors {
          before
          after
        }
      }
    }
  }
`

const GET_PURCHASE_ORDER_SUPPLIERS_QUERY = gql`
  query getPurchaseOrderSuppliersQuery(
    $options: PurchaseOrderSuppliersOptions!
    $filters: PurchaseOrderSuppliersFilters!
  ) {
    purchaseOrderSuppliers(options: $options, filters: $filters) {
      nodes {
        supplier {
          id
          legalName
        }
      }
    }
  }
`

const GENERATE_FULFILLMENT_ORDER = gql`
  mutation generateFulfillmentOrder($input: GenerateFulfillmentOrderInput!) {
    generateFulfillmentOrder(input: $input)
  }
`

type PurchaseOrdersNode = Record<string, any>
interface Data {
  purchaseOrders: {
    nodes: PurchaseOrdersNode[]
    pagination: {
      limit: number
      cursors: {
        before: string
        after: string
      }
    }
  }
}

interface SupplierOption {
  id: string
  legalName: string
}

interface PurchaseOrderSupplier {
  supplier: SupplierOption
}

interface SupplierData {
  purchaseOrderSuppliers: {
    nodes: PurchaseOrderSupplier[]
  }
}

type FilterOptionType = Record<string, any>

const semaphore = {
  ['green']: {
    color: 'rgb(118,217,65)',
    tooltip: 'Orden de recogida generada',
  },
  ['yellow']: {
    color: 'rgb(248,197,96)',
    tooltip: 'Falta generar orden de recogida',
  },
  ['red']: { color: 'rgb(216,82,82)', tooltip: 'Falta completar información' },
}

interface PurchaseOrderTableState {
  filtersOpened: boolean
  filterSelected: FilterOptionType | null
  supplierFromUrl: string
}

class PurchaseOrderTable extends React.Component<
  RouteComponentProps<{}>,
  PurchaseOrderTableState
> {
  state: PurchaseOrderTableState = {
    filtersOpened: false,
    filterSelected: null,
    supplierFromUrl: '',
  }
  componentDidMount() {
    const { supplier } = parseCursorPageParams(
      this.props.location.search.slice(1)
    )

    if (supplier) {
      this.setState({ filtersOpened: true, supplierFromUrl: supplier })
    }
  }

  toggleFilterSection = () => {
    const { filtersOpened } = this.state
    this.setState({ filtersOpened: !filtersOpened })
  }

  extractPageParams = () => {
    const {
      page_size,
      sort_by,
      after,
      before,
      supplier,
      s,
    } = parseCursorPageParams(this.props.location.search.slice(1))
    return {
      page_size,
      sort_by,
      after,
      before,
      supplier,
      s,
    }
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText
    params.after = ''
    params.before = ''
    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  onAfter = (after: string | undefined) => {
    const params = this.extractPageParams()
    params.after = after

    if (params.before) {
      delete params.before
    }

    this.updatePageParams(params)
  }

  onBefore = (before: string | undefined) => {
    const params = this.extractPageParams()
    params.before = before

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onFetch = (formatParams: any) => {
    const params = this.extractPageParams()

    const { filters } = formatParams

    if (filters && filters.supplier) {
      params.supplier = filters.supplier.value
    }

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }
    if (params.s) {
      delete params.s
    }

    this.updatePageParams(params)
  }

  onFilterSelected = (
    index: number,
    selected: FilterOptionType | null | undefined
  ) => {
    this.setState({ filterSelected: { index, selected } })
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  render() {
    const { filtersOpened, filterSelected, supplierFromUrl } = this.state
    const { page_size, after, before, s, supplier } = this.extractPageParams()
    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]
    let flag = false
    return (
      <ThemeContext.Consumer>
        {({ forceShowNotification }) => (
          <Query
            variables={{
              options: {
                limit: pageSize,
                after,
                before,
              },
              filters: { supplier, s },
            }}
            query={GET_PURCHASEORDER_QUERY}
            fetchPolicy="network-only"
          >
            {({
              loading: purchaseOrderLoading,
              error: purchaseOrderError,
              data: purchaseOrderData,
              refetch,
            }: QueryResult<Data, any>) => (
              <Query
                variables={{
                  options: { limit: 50 },
                  filters: { processed: 'false' },
                }}
                query={GET_PURCHASE_ORDER_SUPPLIERS_QUERY}
                fetchPolicy="network-only"
              >
                {({ loading, error, data }: QueryResult<SupplierData, any>) => {
                  if (purchaseOrderLoading) {
                    return <p>Loading...</p>
                  }
                  if (purchaseOrderError) {
                    return <p>Error</p>
                  }
                  if (!purchaseOrderData) {
                    return <p>No Data</p>
                  }

                  if (loading) {
                    return <p>Loading...</p>
                  }
                  if (error) {
                    return <p>Error</p>
                  }
                  if (!data) {
                    return <p>No Data</p>
                  }
                  const { nodes, pagination } = purchaseOrderData.purchaseOrders

                  const { purchaseOrderSuppliers } = data

                  const supplierOptions: FilterOptionType[] = []
                  supplierOptions.push({
                    label: 'Todos los proveedores',
                    value: 'all',
                  })

                  supplierOptions.push(
                    ...purchaseOrderSuppliers.nodes.map(
                      purchaseOrderSupplier => ({
                        label: purchaseOrderSupplier.supplier.legalName,
                        value: purchaseOrderSupplier.supplier.id,
                      })
                    )
                  )

                  const columns = [
                    {
                      header: 'Número de Órden',
                      key: 'number',
                      sortable: false,
                      Cell: (purchaseorder: PurchaseOrdersNode) => {
                        return purchaseorder ? (
                          <>
                            <Tooltip
                              id={`semaphore-${purchaseorder.id}`}
                              message={
                                semaphore[purchaseorder.semaphore].tooltip
                              }
                            >
                              <Semaphore
                                color={semaphore[purchaseorder.semaphore].color}
                              >
                                {purchaseorder.number}
                              </Semaphore>
                            </Tooltip>
                          </>
                        ) : (
                          ''
                        )
                      },
                    },
                    {
                      header: 'Proveedor',
                      key: 'supplier',
                      sortable: false,
                      Cell: (purchaseorder: PurchaseOrdersNode) => {
                        return (
                          purchaseorder.supplier &&
                          purchaseorder.supplier.businessName
                        )
                      },
                    },
                    {
                      header: 'Courier',
                      key: 'courier',
                      sortable: false,
                      Cell: (purchaseorder: PurchaseOrdersNode) => {
                        return (
                          purchaseorder.supplier &&
                          purchaseorder.supplier.courier &&
                          purchaseorder.supplier.courier.name
                        )
                      },
                    },
                    {
                      header: 'Fecha creación de orden',
                      key: 'createdAt',
                      sortable: false,
                      Cell: (purchaseorder: PurchaseOrdersNode) => {
                        const d = new Date(purchaseorder.createdAt)
                        const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
                        const mm =
                          (d.getMonth() + 1 < 10 ? '0' : '') +
                          (d.getMonth() + 1) // January is 0!
                        const yyyy = d.getFullYear()
                        const hour =
                          (d.getHours() < 10 ? '0' : '') + d.getHours()
                        const min =
                          (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
                        const sec =
                          (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
                        const date =
                          yyyy +
                          '-' +
                          mm +
                          '-' +
                          dd +
                          ' ' +
                          hour +
                          ':' +
                          min +
                          ':' +
                          sec
                        return date
                      },
                    },
                    {
                      header: 'Opciones',
                      key: 'actions',
                      width: 99,
                      sortable: false,
                      Cell: (purchaseOrder: PurchaseOrdersNode) => (
                        <ActionContainer>
                          <Link
                            to={
                              '/purchase-orders/' +
                              purchaseOrder.id +
                              '/purchases'
                            }
                          >
                            <Tooltip
                              id={`show-${purchaseOrder.id}`}
                              message="Visualizar ítems de la órden"
                            >
                              <ShowItmesIcon name="logout" />
                            </Tooltip>
                          </Link>
                          {purchaseOrder.documentUrl ? (
                            <a href={purchaseOrder.documentUrl} target="_blank">
                              <Tooltip
                                id={`see-${purchaseOrder.id}`}
                                message="Visualizar Documento"
                              >
                                <WiewIcon name="file-pdf" />
                              </Tooltip>
                            </a>
                          ) : null}
                          <Modal>
                            {({ openModal }) => {
                              return (
                                <div>
                                  <ConfirmAction
                                    onClick={() =>
                                      openModal('FORM', {
                                        header: {},
                                        showCloseTop: true,
                                        modalSize: 'md',
                                        description: (
                                          <PurchaseOrderActionsModal
                                            purchaseOrder={purchaseOrder}
                                            refetch={refetch}
                                          />
                                        ),
                                      })
                                    }
                                  >
                                    <Tooltip
                                      id={`resend-${purchaseOrder.id}`}
                                      message="Opciones de reenvío"
                                    >
                                      <ForwardIcon name="forward" />
                                    </Tooltip>
                                  </ConfirmAction>
                                </div>
                              )
                            }}
                          </Modal>
                          {purchaseOrder.semaphore == 'yellow' ? (
                            <Modal>
                              {({ openModal }) => {
                                return (
                                  <Mutation
                                    mutation={GENERATE_FULFILLMENT_ORDER}
                                    onCompleted={() => {
                                      refetch().finally(() => {
                                        flag = true
                                        forceShowNotification &&
                                          forceShowNotification({
                                            type: 'ok',
                                            message:
                                              'Número de guía generada correctamente',
                                          })
                                      })
                                    }}
                                    onError={() => {
                                      openModal('ALERT', {
                                        header: {
                                          title: 'ALERTA',
                                        },
                                        description:
                                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                        type: 'fail',
                                      })
                                    }}
                                  >
                                    {(
                                      generateFulfillmentOrder,
                                      { loading, called, error }
                                    ) => (
                                      <ActionContainer>
                                        {loading || flag ? (
                                          <Loading />
                                        ) : (
                                          <ConfirmAction
                                            onClick={() => {
                                              generateFulfillmentOrder({
                                                variables: {
                                                  input: {
                                                    purchaseOrderId:
                                                      purchaseOrder.id,
                                                  },
                                                },
                                              })
                                            }}
                                          >
                                            <Tooltip
                                              id={`save-${purchaseOrder.id}`}
                                              message="Generar orden de recogida"
                                            >
                                              <ShowItmesIcon name="arrows-cw" />
                                            </Tooltip>
                                          </ConfirmAction>
                                        )}
                                      </ActionContainer>
                                    )}
                                  </Mutation>
                                )
                              }}
                            </Modal>
                          ) : null}
                        </ActionContainer>
                      ),
                    },
                  ]
                  return (
                    <DataTable
                      indexKey="id"
                      columns={columns}
                      data={nodes}
                      loading={false}
                      totalItemsCount={10}
                      sortBy={''}
                      pageSize={pageSize}
                      pageSizeOptions={PAGE_SIZE_OPTIONS}
                      onSort={this.onSortByChange}
                      onPageSizeChange={this.onPageSizeChange}
                      searchText={s}
                      onSearch={this.onSearch}
                      onAfter={this.onAfter}
                      onBefore={this.onBefore}
                      onFetch={this.onFetch}
                      placeholderSearchBar={'Buscar por número de órden'}
                      after={pagination.cursors.after}
                      before={pagination.cursors.before}
                      filtersOpened={filtersOpened}
                      toggleFilterSection={this.toggleFilterSection}
                      filterSelected={filterSelected}
                      onFilterSelected={this.onFilterSelected}
                      supplierFromUrl={supplierFromUrl}
                      filters={[
                        {
                          key: 'supplier',
                          label: 'Proveedores',
                          options: supplierOptions,
                        },
                      ]}
                    />
                  )
                }}
              </Query>
            )}
          </Query>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default withRouter(PurchaseOrderTable)
