import React from 'react'
import Icon from '../../Icon'
import { CursorNextPage, CursorPrevPage, PaginationContainer } from '../style'

interface Props {
  onAfter: (after: string) => void
  onBefore: (before: string) => void
  after?: string
  before?: string
}

function CursorPagination(props: Props) {
  const { onAfter, onBefore, after, before } = props
  return (
    <PaginationContainer>
      <CursorPrevPage
        cursor={before}
        pageContent={<Icon name="left-big" />}
        onClick={onBefore}
        disabled={before === ''}
      />
      <CursorNextPage
        cursor={after}
        pageContent={<Icon name="right-big" />}
        onClick={onAfter}
        disabled={after === ''}
      />
    </PaginationContainer>
  )
}

export default CursorPagination
