import React from 'react'
import 'jodit/build/jodit.min.css'
import JoditEditor from 'jodit-react'

import {
  RequiredMark,
  StyledLabel,
  LabelWrapper,
  StyledEditorWrapper,
} from './style'

interface Props {
  value: string
  label: string
  required?: boolean
  id?: string
  onChange: (data: string) => void
}

class Editor extends React.Component<Props> {
  config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  }
  render() {
    const { id, label, required, value, onChange, ...props } = this.props
    return (
      <StyledLabel>
        {(label || required) && (
          <LabelWrapper>
            {label && <span>{label}</span>}
            {required && <RequiredMark>*</RequiredMark>}
          </LabelWrapper>
        )}
        <StyledEditorWrapper id={id}>
          <JoditEditor
            value={value}
            config={this.config}
            onChange={onChange}
            {...props}
          />
        </StyledEditorWrapper>
      </StyledLabel>
    )
  }
}

export default Editor
