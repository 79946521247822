import React from 'react'
import styled from 'styled-components'

interface Props extends React.HTMLProps<HTMLButtonElement> {
  color: 'primary' | 'secondary' | 'success' | 'tertiary'
  alone?: boolean
}

export const StyledButton = styled.button<Props>`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: ${props => (props.color === 'tertiary' ? '#858585' : '#fff')};
  font-weight: bold;
  padding: 12px 40px;
  border: ${props =>
    props.color === 'tertiary' ? '2px #858585 solid' : 'none'};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  outline: 0;

  transition: background-color 0.25s ease-out, opacity 0.25s ease-out;

  background-color: ${props =>
    props.color
      ? props.theme.colors[props.color]['default']
      : props.theme.colors['primary']['default']};

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${props =>
      props.color
        ? props.theme.colors[props.color]['hover']
        : props.theme.colors['primary']['hover']};
  }

  ${props => props.disabled && 'opacity: 0.4; cursor: not-allowed;'};
`

export const StyledCancelButton = styled(StyledButton)`
  margin: ${props => (props.alone ? '0px' : '0px 20px')};
`

export const Label = styled.span`
  display: block;
  padding: 0 10px;
`

export const StyledIconButton = styled.button<Props>`
  border-radius: 50%;
  color: #ffffff;
  padding: 10px 8px;
  border: none;
  font-size: 24px;
  line-height: 16px;
  cursor: pointer;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.25s ease-out, opacity 0.25s ease-out;

  background-color: ${props =>
    props.color
      ? props.theme.colors[props.color]['default']
      : props.theme.colors['primary']['default']};

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${props =>
      props.color
        ? props.theme.colors[props.color]['hover']
        : props.theme.colors['primary']['hover']};
  }

  ${props => props.disabled && 'opacity: 0.4; cursor: not-allowed;'};
`
