import React from 'react'
import { Link } from 'react-router-dom'
import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import PromoTable from './components/PromoTypeTable'
import { PageTopSection, CreateButton } from '../../../components/Globals'
const ApprovalsList = () => (
  <PageWrapper title="Tipos de promociones">
    <PageTopSection>
      <Link to="/promo-types/create">
        <CreateButton icon="plus" color="success">
          Crear tipo de promoción
        </CreateButton>
      </Link>
    </PageTopSection>
    <Paper>
      <PromoTable />
    </Paper>
  </PageWrapper>
)

export default ApprovalsList
