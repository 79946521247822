import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Icon from '../../../../Icon'
interface MenuLinkProps {
  active?: number
}

export const MenuLink = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
  position: relative;
  padding: 0 20px 0 25px;
  cursor: pointer;

  & span {
    margin-left: 10px;
  }

  ${(props: MenuLinkProps) => {
    if (props.active) {
      return `
        font-weight: 700;

        &:before {
          border-bottom: 17px solid transparent;
          border-top: 17px solid transparent;
          content: '';
          display: inline-block;
          opacity: 1;
          position: absolute;
          top: 7px;
          right: 0;
          border-right: 17px solid #f3f3f3;
        }
      `
    }

    return `
      font-weight: 300;

      &:hover {
        font-weight: normal;
      }
    `
  }};
`

export const MenuIcon = styled(Icon)`
  font-size: 34px;
`

export const MenuGroup = styled.ul`
  color: #ffffff;
  margin: 0;
  padding: 15px 0 0;
  list-style-type: none;
`

export const MenuOptions = styled.div`
  margin: 0;
  padding-left: 25px;
`

export const SubMenuLink = styled(Link)`
  display: block;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
  position: relative;
  padding: 0 20px;
  cursor: pointer;

  & span {
    margin-left: 20px;
  }

  ${(props: MenuLinkProps) => {
    if (props.active) {
      return `font-weight: 700;`
    }

    return `
      font-weight: 300;

      &:hover {
        font-weight: normal;
      }
    `
  }};
`

interface SubMenuProps {
  open: boolean
}

export const StyledMenuItem = styled.li`
  margin: 0;
  padding: 12px 0;
`

export const SubMenu = styled.div`
  padding-left: 58px;

  & ${StyledMenuItem} {
    padding: 5px 0;
  }

  & ${SubMenuLink} {
    font-size: 14px;
  }

  display: ${(props: SubMenuProps) => (props.open ? 'block' : 'none')};
`
