import React from 'react'
import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import FulfillmentOrderTable from './components/FulfillmentOrderTable'
import FulfillmentOrderItemsTable from './components/FulfillmentOrderItemsTable'

export const FulfillmentOrderList = () => (
  <PageWrapper title="Órdenes de Recogida">
    <Paper>
      <FulfillmentOrderTable />
    </Paper>
  </PageWrapper>
)

export const FulfillmentOrderItemsList = () => (
  <PageWrapper title="Productos de la órden de recogida">
    <FulfillmentOrderItemsTable />
  </PageWrapper>
)

// export default FulfillmentOrderList
