import React from 'react'
import gql from 'graphql-tag'

import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import { AsyncSelect } from '../../../../../components/FormElements'
import Loading from '../../../../../components/Loader'
import { Spacer } from '../../../../../components/Globals'

const GET_PQRS_STATUS_QUERY = gql`
  query getPqrsStatusQuery($filters: PqrsFilters!) {
    pqrsStatus(filters: $filters) {
      nodes {
        id
        name
      }
      pagination {
        total
      }
    }
  }
`

type PqrsStatus = { [key: string]: any }

export type PqrsStatusOption = ValueType<{ [key: string]: any }>

interface Data {
  pqrsStatus: {
    nodes: PqrsStatus[]
  }
}

interface Props {
  error?: boolean
  value: any
  disabled?: boolean
  isRequired?: boolean
  showLabel?: boolean
  onChange: (brand: PqrsStatusOption) => void
}

const PqrsStatusSelector = ({
  error,
  value,
  onChange,
  disabled,
  showLabel,
  isRequired,
}: Props) => (
  <Query
    query={GET_PQRS_STATUS_QUERY}
    fetchPolicy="network-only"
    variables={{ filters: {} }}
  >
    {({ loading, error: errorQuery, data, client }: QueryResult<Data, any>) => {
      if (loading) {
        return (
          <div id={'pqrs_status'}>
            <Spacer />
            <Loading />
          </div>
        )
      }
      if (errorQuery || !data) {
        return (
          <p>
            Error cargando los estados del PQRS. <br />
            Por favor recargue la página.
          </p>
        )
      }

      return (
        <AsyncSelect
          id="pqrsStatusSelect"
          label={showLabel ? 'Estado' : ''}
          placeholder="Seleccione un estado"
          disabled={disabled}
          required={isRequired}
          inputId={'pqrs-status'}
          value={value}
          error={error}
          onChange={onChange}
          getOptionLabel={({ name }: PqrsStatus) => name}
          getOptionValue={({ id }: PqrsStatus) => id}
          backspaceRemovesValue={false}
          defaultOptions={data.pqrsStatus.nodes}
          cacheOptions
          loadOptions={async inputValue => {
            const { data } = await client.query<Data>({
              query: GET_PQRS_STATUS_QUERY,
              variables: {
                filters: { s: inputValue },
              },
            })

            if (!data) {
              return []
            }

            return data.pqrsStatus.nodes
          }}
        />
      )
    }}
  </Query>
)

export default PqrsStatusSelector
