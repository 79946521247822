import styled from 'styled-components'

import { Button } from '../../../components/Buttons'
import Icon from '../../../components/Icon'

import sizes from '../../../styles/media'

export const PageTopSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  & a {
    width: 100%;
  }

  @media ${sizes.sm} {
    & a {
      width: auto;
    }
  }
`

export const CreateSupplierButton = styled(Button)`
  width: 100%;

  @media ${sizes.sm} {
    width: auto;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const EditIcon = styled(Icon)`
  color: #18b0f7;
  font-size: 20px;
`
