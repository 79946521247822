import React from 'react'

import {
  UserSectionWrapper,
  AvatarContainer,
  Avatar,
  UserInfo,
  StyledUserSection,
} from './style'

const UserSection = () => {
  return (
    <StyledUserSection>
      <UserSectionWrapper>
        <AvatarContainer>
          <Avatar src="/img/user-icon-female.svg" alt="Person Icon" />
        </AvatarContainer>
        <UserInfo>
          <span>Steve Jobs</span>
        </UserInfo>
      </UserSectionWrapper>
    </StyledUserSection>
  )
}

export default UserSection
