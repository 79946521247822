import React from 'react'
import {
  Container,
  Wrapper,
  CloseButton,
  CloseIcon,
  Preview,
  Overlay,
  PreviewButton,
  PreviewIcon,
  FileIcon,
} from './style'

interface Props {
  url: string
  type?: string
  onPreviewClick: () => void
  onRemove: () => void
}

const File = ({ url, type, onPreviewClick, onRemove }: Props) => {
  let iconName = 'excel'
  let previewIconName = 'descargar'
  switch (type) {
    case 'pdf':
      iconName = 'pdf'
      previewIconName = 'eye'
      break
    case 'word':
      iconName = 'word'
      break
  }
  return (
    <Container>
      <CloseButton onClick={onRemove}>
        <CloseIcon name="cancel-circled" />
      </CloseButton>
      <Wrapper>
        <Overlay>
          <PreviewButton onClick={onPreviewClick}>
            {type && type != 'pdf' ? (
              <a href={url} target="_blank" download>
                <PreviewIcon name={previewIconName} />
              </a>
            ) : (
              <PreviewIcon name={previewIconName} />
            )}
          </PreviewButton>
        </Overlay>
        <Preview url="">
          <FileIcon name={iconName} />
        </Preview>
      </Wrapper>
    </Container>
  )
}

export default File
