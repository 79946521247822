import styled from 'styled-components'

import Icon from '../Icon'

import sizes from '../../styles/media'

export const Header = styled.div`
  margin: 0 0 20px;
`

export const DesktopHeader = styled.div`
  display: none;

  @media ${sizes.lg} {
    display: block;
  }
`

export const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: #454545;
  text-align: left;
  margin: 0;
`

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.33;
  text-align: left;
  color: #858585;
  margin: 0;
`

export const MobileHeader = styled.button`
  border: none;
  background: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  outline: 0;

  @media ${sizes.lg} {
    display: none;
  }
`

export const CollapseIcon = styled(Icon)`
  font-size: 20px;
  color: #454545;
`

interface BodyProps {
  collapsed: boolean
  noBottomMargin: boolean
}

export const Body = styled.div<BodyProps>`
  ${props => !props.noBottomMargin && 'margin-bottom: 20px;'}
  ${props => props.collapsed && 'display: none;'}
  

  @media ${sizes.lg} {
    display: block;
  }
`
