import { createContext } from 'react'
import { ApolloClient } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

type Notification = {
  type: string
  message: string
}

export type Login = {
  user?: string
  token: string
}

export type Theme = {
  client?: ApolloClient<NormalizedCacheObject>
  title?: string
  notification: Notification
  login: Login
  onSetLogin?: (login: Login) => void
  onSetTitle?: (title: string) => void
  onSetNotification?: (notify: Notification) => void
  forceShowNotification?: (notify: Notification) => void
}

const ThemeContext = createContext<Theme>({
  login: { user: '', token: '' },
  notification: { type: '', message: '' },
})

export default ThemeContext
