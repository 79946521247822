import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import { RouteComponentProps, withRouter } from 'react-router'

import PageWrapper from '../../../components/PageWrapper'
import CreateCommerceForm from './components/CreateCommerceForm'
import EditCommerceForm from './components/EditCommerceForm'
import CommerceBalanceList from '../List/components/CommerceBalanceTable'
import Paper from '../../../components/Paper'

const GET_COMMERCE_QUERY = gql`
  query getCommerceQuery($id: ID!) {
    commerce(id: $id) {
      id
      name
      commercialEmail
      loyaltyEmail
      merchantCode
    }
  }
`

const GET_CURRENCIES_BALANCE_COMMERCES_QUERY = gql`
  query commerceBalance($id: ID!) {
    commerceBalance(commerceId: $id) {
      balances {
        id
        initialAmount
        total
        currency {
          name
        }
        agreements {
          id
          convertionRate
          prepaidPrice
          postpaidPrice
        }
      }
    }
  }
`

export type BalanceNode = Record<string, any>

export type Commerce = {
  id: string
  name: string
  commercialEmail: string
  loyaltyEmail: string
  merchantCode: string
}

export type Currency = {
  id: string
  name: string
  total: string
}

export type Balance = {
  id: string
  initial_amount: string
  total: string
  currency: Currency
}

type DataCommerce = {
  commerce: Commerce
}

type DataBalance = {
  commerceBalance: {
    balances: Balance[]
  }
}

type option = ValueType<{ [key: string]: any }>

interface RouterParams
  extends RouteComponentProps<{
      id?: string
      action: string
    }> {}

export default withRouter(function Commerce({
  history,
  match: {
    params: { id, action },
  },
}: RouterParams) {
  const onCommerceSaved = () => {
    history.push('/commerces')
  }

  return (
    <PageWrapper
      title={`${
        action === 'create'
          ? 'Crear'
          : action === 'edit'
            ? 'Editar'
            : 'Balances de'
      } Comercio`}
    >
      {action === 'create' ? (
        <CreateCommerceForm onCreated={onCommerceSaved} />
      ) : action === 'edit' ? (
        <Query
          variables={{ id }}
          query={GET_COMMERCE_QUERY}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }: QueryResult<DataCommerce>) => {
            if (loading) {
              return <p>Loading...</p>
            }
            if (error) {
              return <p>Error</p>
            }
            if (!data) {
              return <p>No Data</p>
            }
            if (!data.commerce) {
              return <p>No Commerce</p>
            }

            const { commerce } = data
            return (
              <EditCommerceForm
                commerce={commerce}
                onUpdated={onCommerceSaved}
              />
            )
          }}
        </Query>
      ) : (
        <Query
          variables={{ id }}
          query={GET_CURRENCIES_BALANCE_COMMERCES_QUERY}
          fetchPolicy="network-only"
        >
          {({ data, loading, error, refetch }: QueryResult<DataBalance>) => {
            if (loading) {
              return <p>Loading...</p>
            }
            if (error) {
              return <p>Error</p>
            }
            if (!data) {
              return <p>No Data</p>
            }

            if (!data.commerceBalance) {
              return <p>No Commerce</p>
            }

            const { balances } = data.commerceBalance
            return (
              <Paper>
                <CommerceBalanceList balances={balances} refetch={refetch} />
              </Paper>
            )
          }}
        </Query>
      )}
    </PageWrapper>
  )
})

export function useStringInput(initialValue: string, maxLength: number) {
  const [value, setValue] = useState(initialValue)

  function handleChange({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setValue(value)
  }

  return {
    value,
    onChange: handleChange,
    error: value.length >= maxLength,
  }
}

export function useSelectInput(
  initialValue: ValueType<string | number | { [key: string]: any }>
) {
  const [value, setValue] = useState(initialValue)

  function handleChange(
    value: ValueType<string | number | { [key: string]: any }>
  ) {
    setValue(value)
  }

  return {
    value,
    onChange: handleChange,
  }
}

export function useAsyncSelectInput(initialValue: option | option[]) {
  const [value, setValue] = useState(initialValue)

  function handleChange(value: option) {
    setValue(value)
  }

  return {
    value,
    onChange: handleChange,
  }
}

export function useNumberInput(initialValue: string) {
  const [value, setValue] = useState(initialValue)

  if (isNaN(Number(value))) {
    return
  }

  function handleChange({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setValue(value)
  }

  return {
    value,
    onChange: handleChange,
  }
}

export function useDateInput(initialValue: Date) {
  const [value, setValue] = useState(initialValue)

  function handleChange(value: Date) {
    setValue(value)
  }

  return {
    date: value,
    onChange: handleChange,
  }
}
