import React from 'react'
import qs from 'qs'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'

import { PAGE_SIZE_OPTIONS } from '../../../../config'
import DataTable from '../../../../components/DataTable'
import { parsePageParams } from '../../../../helpers/params'
import { Tooltip } from '../../../../components/FormElements'

import { ActionContainer, EditIcon } from '../../../../components/Globals'

const GET_BRANDS_QUERY = gql`
  query getBrandsQuery($options: BrandsOptions!, $filters: BrandsFilters!) {
    brands(options: $options, filters: $filters) {
      nodes {
        id
        name
      }
      pagination {
        total
      }
    }
  }
`

type BrandNode = Record<string, any>

interface Data {
  brands: {
    nodes: BrandNode[]
    pagination: {
      total: number
    }
  }
}

const BrandTable = ({ history, location }: RouteComponentProps) => {
  function updatePageParams(params: object) {
    history.push({
      pathname: history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  function extractPageParams() {
    const { page = 1, page_size, s, sort_by } = parsePageParams(
      location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
    }
  }

  function onPageChange(page: number) {
    const params = extractPageParams()

    params.page = page

    updatePageParams(params)
  }

  function onPageSizeChange(pageSize: number) {
    const params = extractPageParams()

    params.page_size = pageSize

    updatePageParams(params)
  }

  function onSearch(searchText: string | undefined) {
    const params = extractPageParams()

    params.s = searchText
    params.page = 1
    updatePageParams(params)
  }

  function onSortByChange(sortBy: string | undefined) {
    const params = extractPageParams()

    params.sort_by = sortBy

    updatePageParams(params)
  }

  const { page, page_size, s, sort_by } = extractPageParams()
  const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

  return (
    <Query
      variables={{
        options: {
          limit: pageSize,
          offset: page ? (page - 1) * pageSize : 0,
          sortBy: sort_by,
        },
        filters: {
          s,
        },
      }}
      query={GET_BRANDS_QUERY}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult<Data, any>) => {
        if (loading) {
          return <p>Loading...</p>
        }
        if (error) {
          return <p>Error</p>
        }
        if (!data) {
          return <p>No Data</p>
        }
        if (!data.brands) {
          return <p>No Brands</p>
        }

        const { nodes, pagination } = data.brands

        const columns = [
          {
            header: 'Nombre de la marca',
            key: 'name',
          },

          {
            header: 'Acciones',
            key: 'actions',
            width: 99,
            sortable: false,
            Cell: (brand: BrandNode) => (
              <ActionContainer>
                <Link to={`brands/${brand.id}/edit`}>
                  <Tooltip id={`edit-${brand.id}`} message="Editar">
                    <EditIcon name="pencil" />
                  </Tooltip>
                </Link>
              </ActionContainer>
            ),
          },
        ]

        return (
          <DataTable
            indexKey="id"
            columns={columns}
            data={nodes}
            loading={loading}
            totalItemsCount={pagination.total}
            sortBy={sort_by}
            page={page || 1}
            pageSize={pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            searchText={s}
            onSort={onSortByChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSearch={onSearch}
            placeholderSearchBar={'Buscar por nombre'}
          />
        )
      }}
    </Query>
  )
}

export default withRouter(BrandTable)
