import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import qs from 'qs'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'

import Datatable from '../../../../components/DataTable'
import { PAGE_SIZE_OPTIONS } from '../../../../config'
import { parseCursorPageParams } from '../../../../helpers/params'
import { Wrapper } from '../../../../components/Modals/style'
import * as Grid from '../../../../components/Grid'
import { Input } from '../../../../components/FormElements'
import { Spacer } from '../../../../components/Globals'

const GET_CTRANSACTIONS_BY_CURRENCY_BALANCE_QUERY = gql`
  query getTransactionsByCurrencyBalanceQuery(
    $id: ID!
    $options: BalanceTransactionsOptions!
    $filters: BalanceTransactionsFilters
  ) {
    transactionsByCurrencyBalance(
      balanceId: $id
      options: $options
      filters: $filters
    ) {
      agreement {
        prepaidPrice
        postpaidPrice
        convertionRate
        validityFrom
        validityTo
      }
      transactions {
        id
        identificationNumber
        transactionType
        points
        oldTotal
        newTotal
        amount
        createdAt
      }
      promos {
        id
        name
      }
      pagination {
        limit
        cursors {
          before
          after
        }
      }
    }
  }
`
type TransactionsNode = Record<string, any>

type FilterOptionType = Record<string, any>

type Agreement = {
  prepaidPrice: number
  postpaidPrice: number
  convertionRate: number
  status: string
  validityTo: string
  validityFrom: string
}

type Data = {
  transactionsByCurrencyBalance: {
    agreement: Agreement
    transactions: TransactionsNode[]
    promos: FilterOptionType[]
    pagination: {
      limit: number
      cursors: {
        before: string
        after: string
      }
    }
  }
}

interface RouterParams
  extends RouteComponentProps<{
      id?: string
      after?: string
      before?: string
      promo?: string
      action?: string
    }> {}

export default withRouter(function BalanceTransactionsTable({
  history,
  location,
  match: {
    params: { id },
  },
}: RouterParams) {
  const updatePageParams = (params: object) => {
    history.push({
      pathname: history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }
  interface TransactionsBalanceTableState {
    filtersOpened: boolean
    filterSelected: FilterOptionType | null
    promoFromUrl: string
  }

  const [filterState, setFilterState] = useState<TransactionsBalanceTableState>(
    {
      filtersOpened: false,
      filterSelected: {},
      promoFromUrl: '',
    }
  )

  const extractPageParams = () => {
    const { page_size, after, before, promo } = parseCursorPageParams(
      location.search.slice(1)
    )
    return {
      page_size,
      promo,
      after,
      before,
    }
  }

  const onSortByChange = (sortBy: string | undefined) => {
    const params = extractPageParams()
    updatePageParams(params)
  }

  const onPageSizeChange = (pageSize: number) => {
    const params = extractPageParams()

    params.page_size = pageSize

    updatePageParams(params)
  }

  function onAfter(after: string | undefined) {
    const params = extractPageParams()
    params.after = after

    if (params.before) {
      delete params.before
    }

    updatePageParams(params)
  }

  function onBefore(before: string | undefined) {
    const params = extractPageParams()
    params.before = before

    if (params.after) {
      delete params.after
    }

    updatePageParams(params)
  }

  const onFetch = (formatParams: any) => {
    const params = extractPageParams()

    const { filters } = formatParams

    if (filters && filters.promo) {
      params.promo = filters.promo.value
    }

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }

    updatePageParams(params)
  }

  const toggleFilterSection = () => {
    const { filtersOpened } = filterState
    setFilterState({
      ...filterState,
      filtersOpened: !filtersOpened,
    })
  }

  const onFilterSelected = (
    index: number,
    selected: FilterOptionType | null | undefined
  ) => {
    setFilterState({
      ...filterState,
      filterSelected: { index, selected },
    })
  }

  const { page_size, after, before, promo } = extractPageParams()

  const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

  const columns = [
    {
      header: 'Identificacion',
      key: 'identificationNumber',
    },
    {
      header: 'Tipo',
      key: 'transactionType',
      Cell: (transaction: TransactionsNode) => {
        let type = ''
        switch (transaction.transactionType) {
          case 'Buy':
            type = 'Compra'
            break
          case 'Payment':
            type = 'Pago'
            break
          case 'Debit':
            type = 'Débito'
            break

          default:
            break
        }
        return <>{type}</>
      },
    },
    {
      header: 'Puntos',
      key: 'points',
      Cell: (transaction: TransactionsNode) => {
        let numberFormat = new Intl.NumberFormat('en-EN').format(
          transaction.points
        )
        return <>{numberFormat}</>
      },
    },
    {
      header: 'Total anterior',
      key: 'oldTotal',
      Cell: (transaction: TransactionsNode) => {
        let numberFormat = new Intl.NumberFormat('en-EN').format(
          transaction.oldTotal
        )
        return <>{numberFormat}</>
      },
    },
    {
      header: 'Total nuevo',
      key: 'newTotal',
      Cell: (transaction: TransactionsNode) => {
        let numberFormat = new Intl.NumberFormat('en-EN').format(
          transaction.newTotal
        )
        return <>{numberFormat}</>
      },
    },
    {
      header: 'Monto',
      key: 'amount',
      Cell: (transaction: TransactionsNode) => {
        let numberFormat = new Intl.NumberFormat('en-EN').format(
          transaction.amount
        )
        return <>{'$ ' + numberFormat}</>
      },
    },
    {
      header: 'Fecha ',
      key: 'createdAt',
      sortable: false,
      Cell: (transaction: TransactionsNode) => {
        const d = new Date(transaction.createdAt)
        const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
        const mm = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
        const yyyy = d.getFullYear()
        const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
        const min = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
        const sec = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
        const date =
          yyyy + '-' + mm + '-' + dd + ' ' + hour + ':' + min + ':' + sec
        return date
      },
    },
  ]

  return (
    <Query
      variables={{
        id,
        options: {
          limit: pageSize,
          after,
          before,
        },
        filters: { promo: promo },
      }}
      query={GET_CTRANSACTIONS_BY_CURRENCY_BALANCE_QUERY}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult<Data, any>) => {
        if (loading) {
          return <p>Loading...</p>
        }
        if (error) {
          return <p>Error</p>
        }
        if (!data) {
          return <p>No Data</p>
        }
        if (!data.transactionsByCurrencyBalance) {
          return <p>No Transactions</p>
        }

        const {
          agreement,
          transactions,
          promos,
          pagination,
        } = data.transactionsByCurrencyBalance

        const { prepaidPrice, postpaidPrice, convertionRate } = agreement

        const promosOptions: FilterOptionType[] = promos.map(promo => ({
          label: promo.name,
          value: promo.id,
        }))

        return (
          <Wrapper>
            <Grid.ContainerFluid>
              <Grid.Row>
                <Grid.Column md={4}>
                  <Input
                    id="prepaid_price"
                    type="text"
                    label="Precio Prepago"
                    disabled={true}
                    value={prepaidPrice}
                    onChange={() => {}}
                    {...prepaidPrice}
                  />
                  <Spacer />
                </Grid.Column>
                <Grid.Column md={4}>
                  <Input
                    id="postpaidPrice"
                    type="text"
                    label="Precio Postpago"
                    disabled
                    value={postpaidPrice}
                    onChange={() => {}}
                    {...postpaidPrice}
                  />
                  <Spacer />
                </Grid.Column>
                <Grid.Column md={4}>
                  <Input
                    id="convertionRate"
                    type="email"
                    label="Tasa de conversión"
                    disabled
                    value={convertionRate}
                    onChange={() => {}}
                    {...convertionRate}
                  />
                  <Spacer />
                </Grid.Column>
              </Grid.Row>
            </Grid.ContainerFluid>

            <Datatable
              indexKey="id"
              columns={columns}
              data={transactions}
              loading={loading}
              totalItemsCount={10}
              pageSize={pageSize}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              onSort={onSortByChange}
              onPageSizeChange={onPageSizeChange}
              onAfter={onAfter}
              onBefore={onBefore}
              after={pagination.cursors.after}
              before={pagination.cursors.before}
              filters={[
                {
                  key: 'promo',
                  label: 'Promo',
                  options: promosOptions,
                },
              ]}
              onFetch={onFetch}
              filtersOpened={filterState.filtersOpened}
              toggleFilterSection={toggleFilterSection}
              filterSelected={filterState.filterSelected}
              onFilterSelected={onFilterSelected}
            />
          </Wrapper>
        )
      }}
    </Query>
  )
})
