import React from 'react'
import PageWrapper from '../../../components/PageWrapper'
//import Paper from '../components'
// import Paper from '../../../components/Paper'

import { Tab } from '../../../components/Tab/Tab'
import { Tabs } from '../../../components/Tab/Tabs'
import AccreditationTable from '../../Transaction/Accreditation/List/components/AccreditationTable'
import TransactionsFilesTable from '../../TransactionsFiles/List/components/TransactionsFilesTable'

import { TabList, Paper } from './style'

const ApprovalsList = () => (
  <PageWrapper title="Aprobaciones">
    <Paper>
      <Tabs>
        <TabList className="parent">
          <Tab label="a">Aprobación de movimientos</Tab>
          <Tab label="b">Aprobaciones de carga de millas</Tab>

          <Tabs.Panel label="a">
            <AccreditationTable />
          </Tabs.Panel>

          <Tabs.Panel label="b">
            <TransactionsFilesTable />
          </Tabs.Panel>
        </TabList>
      </Tabs>
    </Paper>
  </PageWrapper>
)

export default ApprovalsList
