import React from 'react'

import Menu from './Menu'
import UserSection from './UserSection'

import {
  Container,
  Header,
  Logo,
  MenuWrapper,
  MenuTopFill,
  MenuBottomFill,
} from './style'

interface Props {
  collapsed: boolean
  toggleMenu: () => void
}

class Sidebar extends React.Component<Props, any> {
  onContainerPress = () => {
    if (this.props.collapsed) {
      this.props.toggleMenu()
    }
  }

  render() {
    return (
      <Container
        collapsed={this.props.collapsed}
        onClick={this.onContainerPress}
      >
        <MenuTopFill src="/img/menu-top-fill.png" alt="" />
        <MenuBottomFill src="/img/menu-bottom-fill.png" alt="" />
        <Header>
          <Logo src="/img/logo-ppm.png" />
        </Header>
        <MenuWrapper collapsed={this.props.collapsed}>
          <UserSection />
          <Menu />
        </MenuWrapper>
      </Container>
    )
  }
}

export default Sidebar
