import React from 'react'

import { TabStyle } from './style'
import { useTabs } from './Tabs'

export interface TabProps {
  label: string
  children: React.ReactNode
}

export const Tab: React.FC<TabProps> = ({ label, children }: TabProps) => {
  const { setActiveTab, activeTab, width, last, first } = useTabs()

  return (
    <TabStyle
      active={label === activeTab}
      width={width.toString()}
      last={last === label}
      first={first === label}
      onClick={() => setActiveTab(label)}
    >
      {children}
    </TabStyle>
  )
}
