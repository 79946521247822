import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import Modal from '../../../../components/Modals/ModalRoot'
import VariationListModal, { ProductVariation } from './VariationListModal'
import { VariationsButtonContainer, VariationsButton } from '../style'

const GET_VARIATIONS_BY_PRODUCT = gql`
  query getVariationsByProduct($productId: String!) {
    variationsByProduct(productId: $productId) {
      nodes {
        id
        options {
          id
          name
          feature {
            id
            name
          }
        }
      }
    }
  }
`

interface Data {
  variationsByProduct: {
    nodes: ProductVariation[]
  }
}

interface Props {
  productId: string | null
}

function VariationSelector({ productId }: Props) {
  return (
    <Query
      query={GET_VARIATIONS_BY_PRODUCT}
      fetchPolicy="network-only"
      variables={{ productId }}
    >
      {({ loading, error, data }: QueryResult<Data, any>) => {
        if (error || !data) {
          return (
            <p>
              Error cargando productos. <br />
              Por favor recargue la página.
            </p>
          )
        }
        return (
          <VariationsButtonContainer>
            <Modal>
              {({ openModal }) => {
                return (
                  <VariationsButton
                    color="secondary"
                    disabled={
                      loading || data.variationsByProduct.nodes.length === 0
                    }
                    onClick={
                      () =>
                        openModal('DETAILS', {
                          header: {},
                          description: (
                            <VariationListModal
                              variations={data.variationsByProduct.nodes}
                            />
                          ),
                          //url: asset.url,
                        })
                      // openModal('VARIATION_LIST', {
                      //   variations: data.variationsByProduct.nodes,
                      // })
                    }
                  >
                    {loading
                      ? 'Cargando...'
                      : `Ver variaciones creadas (${
                          data.variationsByProduct.nodes.length
                        })`}
                  </VariationsButton>
                )
              }}
            </Modal>
          </VariationsButtonContainer>
        )
      }}
    </Query>
  )
}

export default VariationSelector
