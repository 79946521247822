import styled from 'styled-components'

interface Props {
  active?: boolean
  width?: string
  last?: boolean
  first?: boolean
}

export const TabStyle = styled('li').attrs<Props>({})`
  display: inline-block;
  list-style: none;
  cursor: pointer;
  margin-bottom: -1px;
  padding: 0.7rem 2rem;
  background-color: ${props => (props.active ? '#ffffff' : '#e5e5e5')};
  width: ${props => props.width + '%'};
  height: 40px;
  border-radius: ${props =>
    props.last ? '0 20px 0 0' : props.first ? '20px 0 0 0' : 'none'};
`

export const PanelTab = styled.div`
  padding: 20px;
`
