import * as React from 'react'
import { BagdeCircle, BagdeContainer } from './style'

interface Props {
  children: React.ReactNode
  type?: string
  size?: string
}

const Badge = (props: Props) => {
  const { type, size } = props
  let color = ''
  let margin = ''
  let cicleSize = ''

  if (type) {
    switch (type) {
      case 'fail': {
        color = '#ff3b30'
        break
      }
      case 'ok': {
        color = '#4cd964'
        break
      }
      case 'alert': {
        color = '#ff9500'
        break
      }
      default: {
        color = '#ffffff'
        break
      }
    }
  } else {
    color = '#ffffff'
  }

  switch (size) {
    case 's': {
      cicleSize = '25px'
      margin = '8px'
      break
    }
    case 'm': {
      cicleSize = '50px'
      margin = '16px'
      break
    }
    case 'l': {
      cicleSize = '75px'
      margin = '25px'
      break
    }
    default: {
      margin = '8px'
      cicleSize = '25px'
      break
    }
  }

  return (
    <BagdeContainer>
      <BagdeCircle margin={margin} size={cicleSize} color={color}>
        {props.children}
      </BagdeCircle>
    </BagdeContainer>
  )
}

export default Badge
