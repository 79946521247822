import styled from 'styled-components'
import sizes from '../../../../../styles/media'

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.16)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '1.2rem',
    zIndex: 10,
  },
  content: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: '20px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2)',
    padding: 0,
    border: 0,
    top: 'auto',
    bottom: 'auto',
    left: 'auto',
    right: 'auto',
    width: '100%',
    maxWidth: '700px',
    margin: '0 auto',
  },
}

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 700;
  color: #454545;
  margin: 0 0 20px;
`

export const VariationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  background-color: #f5f5f5;
  padding: 10px 20px;
  margin-bottom: 10px;

  @media ${sizes.sm} {
    flex-direction: row;
  }
`

export const VariationTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: #858585;
  margin: 0 0 10px 0;
  text-align: center;

  @media ${sizes.sm} {
    margin: 0 10px 0 0;
    text-align: left;
  }
`
