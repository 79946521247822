import React from 'react'
import { PageButton } from '../style'

interface Props {
  className?: string
  pageNumber: number
  pageContent?: React.ReactNode
  active?: boolean
  disabled?: boolean
  first?: boolean
  last?: boolean
  onClick: (pageNumber: number) => void
}

export default function PageButtonComponent(props: Props) {
  const onPageClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    const { disabled, pageNumber, onClick } = props

    if (disabled) {
      return
    }

    onClick(pageNumber)
  }

  const {
    className,
    pageContent,
    pageNumber,
    active,
    disabled,
    first,
    last,
  } = props
  return (
    <PageButton
      className={className}
      active={active}
      disabled={disabled}
      first={first}
      last={last}
      href="#"
      onClick={onPageClick}
    >
      {pageContent || pageNumber}
    </PageButton>
  )
}
