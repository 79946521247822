import React from 'react'
import gql from 'graphql-tag'

import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import { AsyncSelect } from '../../../../components/FormElements'
import Loading from '../../../../components/Loader'
import { Spacer } from '../../../../components/Globals'

const GET_BRANDS_QUERY = gql`
  query getBrandsQuery($options: BrandsOptions!, $filters: BrandsFilters!) {
    brands(options: $options, filters: $filters) {
      nodes {
        id
        name
      }
    }
  }
`

// export interface BrandOption {
//   id: string
//   name: string
// }

type Brand = { [key: string]: any }

export type BrandOption = ValueType<{ [key: string]: any }>

interface Data {
  brands: {
    nodes: Brand[]
  }
}

interface Props {
  error?: boolean
  value: any
  disabled?: boolean
  isRequired?: boolean
  onChange: (brand: BrandOption) => void
}

const BrandSelector = ({
  error,
  value,
  onChange,
  disabled,
  isRequired,
}: Props) => (
  <Query
    query={GET_BRANDS_QUERY}
    fetchPolicy="network-only"
    variables={{ options: { limit: 10 }, filters: {} }}
  >
    {({ loading, error: errorQuery, data, client }: QueryResult<Data, any>) => {
      if (loading) {
        return (
          <div id={'brand'}>
            <Spacer />
            <Loading />
          </div>
        )
      }
      if (errorQuery || !data) {
        return (
          <p>
            Error cargando marcas. <br />
            Por favor recargue la página.
          </p>
        )
      }

      return (
        <AsyncSelect
          id="brandSelect"
          label="Marca"
          placeholder="Seleccione una marca"
          disabled={disabled}
          required={isRequired}
          inputId={'brand'}
          value={value}
          error={error}
          onChange={onChange}
          getOptionLabel={({ name }: Brand) => name}
          getOptionValue={({ id }: Brand) => id}
          backspaceRemovesValue={false}
          defaultOptions={data.brands.nodes}
          cacheOptions
          loadOptions={async inputValue => {
            const { data } = await client.query<Data>({
              query: GET_BRANDS_QUERY,
              variables: {
                options: { limit: 10 },
                filters: { s: inputValue },
              },
            })

            if (!data) {
              return []
            }

            return data.brands.nodes
          }}
        />
      )
    }}
  </Query>
)

export default BrandSelector
