import React from 'react'

import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import BalanceTransactionsTable from './components/TransactionsBalance'

export function BalanceTransactionsReportList() {
  return (
    <PageWrapper title="Transacciones de Balance">
      <Paper>
        <BalanceTransactionsTable />
      </Paper>
    </PageWrapper>
  )
}
