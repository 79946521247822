import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'

import { AsyncSelect } from '../../FormElements'

import Loading from '../../Loader'
import { Spacer } from '../../Globals'

const GET_DRIVEINSCHEMAS_QUERY = gql`
  query getDriveInQuery(
    $options: DriveInItemsOptions!
    $filters: DriveInItemsFilters!
  ) {
    driveInSchemas(options: $options, filters: $filters) {
      nodes {
        name
        code
      }
    }
  }
`
type DriveInSchemas = { [key: string]: any }
export type DriveInSchemasOption = ValueType<{ [key: string]: any }>

interface Data {
  driveInSchemas: {
    nodes: DriveInSchemas[]
  }
}

interface Props {
  value: DriveInSchemasOption | DriveInSchemasOption[] | null
  onChange: (driveInSchemas: DriveInSchemasOption) => void
  isMulti?: boolean
  isRequired: boolean
  disabled?: boolean
  error?: boolean
}

const DriveInSchemasSelector = ({
  value,
  onChange,
  isMulti,
  isRequired,
  disabled,
  error,
}: Props) => (
  <Query
    query={GET_DRIVEINSCHEMAS_QUERY}
    fetchPolicy="network-only"
    variables={{ options: { limit: 10 }, filters: {} }}
  >
    {({ loading, error: errorQuery, data, client }: QueryResult<Data, any>) => {
      if (loading) {
        return (
          <div id={'driveInSchemas'}>
            <Spacer />
            <Loading />
          </div>
        )
      }
      if (errorQuery || !data) {
        return (
          <p>
            Error cargando datos. <br />
            Por favor recargue la página.
          </p>
        )
      }

      if (value) {
        if (!value['name']) {
          data.driveInSchemas.nodes.forEach(data => {
            if (value && value['code'] == data.code) {
              value = {
                name: data.name,
                code: data.code,
              }
            }
          })
        }
      }

      return (
        <AsyncSelect
          id="driveInSchemasSelect"
          label="Esquema"
          placeholder="Seleccione un esquema"
          required={isRequired}
          disabled={disabled}
          inputId={'supplier'}
          value={value}
          isMulti={isMulti ? isMulti : false}
          onChange={onChange}
          getOptionLabel={({ name }: DriveInSchemas) => name}
          getOptionValue={({ code }: DriveInSchemas) => code}
          backspaceRemovesValue={false}
          defaultOptions={data.driveInSchemas.nodes}
          cacheOptions
          error={error}
          loadOptions={async inputValue => {
            const { data } = await client.query<Data>({
              query: GET_DRIVEINSCHEMAS_QUERY,
              variables: {
                options: { limit: 10 },
                filters: { s: inputValue },
              },
            })

            if (!data) {
              return []
            }

            return data.driveInSchemas.nodes
          }}
        />
      )
    }}
  </Query>
)

export default DriveInSchemasSelector
