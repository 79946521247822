import React from 'react'
import { Link } from 'react-router-dom'
import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import BrandTable from './components/BrandTable'
import { PageTopSection, CreateButton } from '../../../components/Globals'

const BrandList = () => (
  <PageWrapper title="Marcas">
    <PageTopSection>
      <Link to="/brands/create">
        <CreateButton icon="plus" color="success">
          Crear Marca
        </CreateButton>
      </Link>
    </PageTopSection>
    <Paper>
      <BrandTable />
    </Paper>
  </PageWrapper>
)

export default BrandList
