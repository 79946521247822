import styled from 'styled-components'
import Dropzone from 'react-dropzone'

import Icon from '../Icon'

export const Container = styled.div`
  position: relative;
`

export const Overlay = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  display: none;

  background-color: rgba(69, 69, 69, 0.5);

  align-items: center;
  justify-content: center;
`

export const Wrapper = styled.div`
  background-color: #858585;
  border-radius: 10px;
  border: 1px solid #c5c5c5;
  overflow: hidden;
  width: 80px;
  height: 80px;
  position: relative;

  &:hover ${Overlay} {
    display: flex;
  }
`

interface PreviewProps {
  url: string
}

export const Preview = styled.div<PreviewProps>`
  width: 100%;
  height: 100%;

  background-image: url(${props => props.url});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const PreviewImg = styled.img`
  max-width: 100%;
`

export const PreviewIframe = styled.iframe`
  width: 100%;
  height: 800px;
`

export const CloseButton = styled.button`
  position: absolute;
  top: -10px;
  right: -14px;
  cursor: pointer;
  color: #fff;
  background: none;
  border: none;
  display: block;
  z-index: 1;
  padding: 0;
`

export const CloseIcon = styled(Icon)`
  font-size: 24px;
  color: #ff2d2d;
`

export const ImageDropzone = styled(Dropzone)`
  width: 100%;
  height: 100%;

  min-height: 100px;
`

export const FileSelector = styled(Dropzone)``

export const PreviewButton = styled.button`
  background-color: #f5f5f5;
  border-radius: 50%;
  padding: 2px 1px 2px 3px;
  outline: 0;
  cursor: pointer;
`

export const PreviewIcon = styled(Icon)`
  color: #454545;
  font-size: 18px;
`
export const FileIcon = styled(Icon)`
  font-size: 40px;
  padding: 0;
  margin: 0;
  color: #fff;
`
