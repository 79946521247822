import React from 'react'
import Paper from '../../../../components/Paper'
import * as Grid from '../../../../components/Grid'
import {
  Input,
  // Select,
  // DatePicker,
  Error,
} from '../../../../components/FormElements'
import { CancelButton } from '../../../../components/Buttons'
import {
  SaveButton,
  ActionWrapper,
  Spacer,
} from '../../../../components/Globals'

import { Commerce as CommerceType } from '../'

import {
  useStringInput,
  // useSelectInput,
  // useNumberInput,
  // useDateInput,
} from '../index'

type Props = {
  commerce: CommerceType
  onUpdated: () => void
}

export default function EditCommerceForm({
  commerce: {
    name: initialName,
    commercialEmail: initialCommercialEmail,
    loyaltyEmail: initialLoyaltyEmail,
    merchantCode: initialMerchantCode,
  },
  onUpdated,
}: Props) {
  const name = useStringInput(initialName, 40)
  const commercialEmail = useStringInput(initialCommercialEmail, 40)
  const loyaltyEmail = useStringInput(initialLoyaltyEmail, 40)
  const merchantCode = useStringInput(initialMerchantCode, 40)
  // const categories = useSelectInput([])
  // const currency = useSelectInput([])
  // const prepaidPrice = useNumberInput('')
  // const postpaidPrice = useNumberInput('')
  // const convertionRate = useNumberInput('')
  // const validityTo = useDateInput(new Date())
  // const validityFrom = useDateInput(new Date())
  return (
    <>
      <Paper>
        <Grid.ContainerFluid>
          <Grid.Row>
            <Grid.Column md={4}>
              <Input
                id="name"
                type="text"
                label="Nombre del comercio"
                required
                {...name}
              />
              {name.error ? (
                <Error>La longitud máxima debe ser de 40 caracteres.</Error>
              ) : (
                <Spacer />
              )}
            </Grid.Column>
            <Grid.Column md={4}>
              <Input
                id="commercialEmail"
                type="email"
                label="Email Comercial"
                required
                {...commercialEmail}
              />
              {commercialEmail.error ? (
                <Error>La longitud máxima debe ser de 40 caracteres.</Error>
              ) : (
                <Spacer />
              )}
            </Grid.Column>
            <Grid.Column md={4}>
              <Input
                id="loyaltyEmail"
                type="email"
                label="Email Loyalty"
                required
                {...loyaltyEmail}
              />
              {loyaltyEmail.error ? (
                <Error>La longitud máxima debe ser de 40 caracteres.</Error>
              ) : (
                <Spacer />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={4}>
              <Input
                id="merchantCode"
                type="text"
                label="RUC"
                required
                {...merchantCode}
              />
              {merchantCode.error ? (
                <Error>La longitud máxima debe ser de 40 caracteres.</Error>
              ) : (
                <Spacer />
              )}
            </Grid.Column>
            <Grid.Column md={4}>
              {/* <Select
                label="Categoría"
                isMulti
                required
                options={[
                  {
                    value: 'sports',
                    label: 'Deportes',
                  },
                  {
                    value: 'videogames',
                    label: 'Juegos de Video',
                  },
                ]}
                {...categories}
              />
              {categories.value == 0 ? (
                <Error>Categoría Requerida.</Error>
              ) : (
                <Spacer />
              )} */}
            </Grid.Column>
          </Grid.Row>
        </Grid.ContainerFluid>
      </Paper>
      <Spacer />
      <Paper>
        <Grid.ContainerFluid>
          <Grid.Row>
            <Grid.Column md={4}>
              {/* <Select
                label="??Currency??"
                required
                options={[
                  {
                    value: 'usd',
                    label: 'Dólares',
                  },
                  {
                    value: 'miles',
                    label: 'Millas',
                  },
                ]}
                {...currency}
              />
              {currency.value == 0 ? (
                <Error>??Currency?? Requerida.</Error>
              ) : (
                <Spacer />
              )} */}
            </Grid.Column>
          </Grid.Row>
          {/* {currency.value != 0 && (
            <Grid.Row>
              <Grid.Column md={4}>
                <Input
                  id="prepaidPrice"
                  type="number"
                  label="Prepago"
                  required
                  {...prepaidPrice}
                />
                <Spacer />
              </Grid.Column>
              <Grid.Column md={4}>
                <Input
                  id="postpaidPrice"
                  type="number"
                  label="Postpago"
                  required
                  {...postpaidPrice}
                />
                <Spacer />
              </Grid.Column>
              <Grid.Column md={4}>
                <Input
                  id="convertionRate"
                  type="number"
                  label="Tasa de conversión"
                  required
                  {...convertionRate}
                />
                <Spacer />
              </Grid.Column>
              <Grid.Column md={4}>
                <DatePicker label="Desde" {...validityTo} />
              </Grid.Column>
              <Grid.Column md={4}>
                <DatePicker label="Hasta" {...validityFrom} />
              </Grid.Column>
            </Grid.Row>
          )} */}
        </Grid.ContainerFluid>
      </Paper>
      <ActionWrapper>
        <CancelButton color="secondary" to="commerces" alone={true}>
          Cancelar
        </CancelButton>
        <SaveButton
          color="primary"
          // disabled={
          //   !name.value ||
          //   !commercialEmail.value ||
          //   !loyaltyEmail.value ||
          //   !merchantCode.value ||
          //   !prepaidPrice ||
          //   !postpaidPrice ||
          //   !convertionRate ||
          //   !validityTo.date ||
          //   !validityFrom.date ||
          //   name.error ||
          //   commercialEmail.error ||
          //   loyaltyEmail.error ||
          //   merchantCode.error ||
          //   categories.value == 0 ||
          //   currency.value == 0
          // }
          // onClick={() => {
          //   const body = {
          //     name,
          //     commercialEmail,
          //     loyaltyEmail,
          //     merchantCode,
          //     prepaidPrice,
          //     postpaidPrice,
          //     convertionRate,
          //     validityTo,
          //     validityFrom,
          //   }

          //   console.log('body', body)
          // }}
        >
          Crear Comercio
        </SaveButton>
      </ActionWrapper>
    </>
  )
}
