import React from 'react'

import {
  Title,
  DescriptionContainer,
  TableContainer,
  Container,
  ColumnDescription,
  ColumnTitle,
} from './style'

interface Props {
  pqrs: any
  refetch: any
}

export class PqrsViewModal extends React.Component<Props> {
  render() {
    const { pqrs } = this.props

    return (
      <>
        <Title>Detalle del PQRS</Title>
        <DescriptionContainer />
        <TableContainer>
          <Container>
            <ColumnTitle>Número</ColumnTitle>
            <ColumnDescription>{pqrs.number}</ColumnDescription>
            <ColumnTitle>Cliente</ColumnTitle>
            <ColumnDescription>
              {pqrs.businessName + pqrs.firstName + ' ' + pqrs.lastName}
            </ColumnDescription>
            <ColumnTitle>Tipo de Identificación</ColumnTitle>
            <ColumnDescription>{pqrs.identificationType}</ColumnDescription>
            <ColumnTitle>Identificación</ColumnTitle>
            <ColumnDescription>{pqrs.identificationNumber}</ColumnDescription>
            <ColumnTitle>Email</ColumnTitle>
            <ColumnDescription>{pqrs.email}</ColumnDescription>
          </Container>
          <Container>
            <ColumnTitle>Tipo</ColumnTitle>
            <ColumnDescription>{pqrs.pqrsType.label}</ColumnDescription>
            <ColumnTitle>Teléfono</ColumnTitle>
            <ColumnDescription>{pqrs.phone}</ColumnDescription>
            <ColumnTitle>Dirección</ColumnTitle>
            <ColumnDescription>{pqrs.address}</ColumnDescription>
            <ColumnTitle>Estado</ColumnTitle>
            <ColumnDescription>{pqrs.status.name}</ColumnDescription>
          </Container>
        </TableContainer>
        <TableContainer>
          <Container>
            <ColumnTitle>Descripción del Cliente</ColumnTitle>
            <ColumnDescription>{pqrs.description}</ColumnDescription>
          </Container>
        </TableContainer>
        <TableContainer>
          <Container>
            <ColumnTitle>Feedback</ColumnTitle>
            <ColumnDescription>{pqrs.feedback}</ColumnDescription>
          </Container>
        </TableContainer>
      </>
    )
  }
}
