import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import * as queryString from 'query-string'

import Paper from '../../../components/Paper'
import * as Grid from '../../../components/Grid'
import { Input, Error } from '../../../components/FormElements'
import { Button } from '../../../components/Buttons'
import { Spacer } from '../../../components/Globals'

import { Container, LoginButtonContainer, ErrorContainer } from './style'
import ThemeContext from '../../../context/ThemeContext'

const LOGIN_USER_MUTATION = gql`
  mutation loginUserMutation($input: LoginUserInput!) {
    loginUser(input: $input) {
      token
      roles {
        allowed
      }
    }
  }
`
interface State {
  username: string
  usernameError: boolean
  password: string
  passwordError: boolean
  loginError: boolean
}
class LoginForm extends React.Component<RouteComponentProps<{}>, State> {
  state: State = {
    username: '',
    usernameError: false,
    password: '',
    passwordError: false,
    loginError: false,
  }

  handleUsernameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const username = value

    if (username.length >= 40) {
      this.setState({
        username,
        usernameError: true,
      })
      return
    }

    this.setState({
      username,
      usernameError: false,
    })
  }

  handlePasswordChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const password = value

    if (password.length >= 40) {
      this.setState({
        password,
        passwordError: true,
      })
      return
    }

    this.setState({
      password,
      passwordError: false,
    })
  }
  render() {
    const {
      username,
      usernameError,
      password,
      passwordError,
      loginError,
    } = this.state
    const { history } = this.props

    return (
      <ThemeContext.Consumer>
        {context => (
          <Container>
            <Grid.ContainerFluid>
              <Grid.Row>
                <Grid.Column md={3} />
                <Grid.Column md={6}>
                  <Paper>
                    <Mutation mutation={LOGIN_USER_MUTATION}>
                      {(loginUser, { loading, called, error }) => (
                        <React.Fragment>
                          <form
                            onSubmit={async e => {
                              e.preventDefault()
                              try {
                                if (
                                  !username ||
                                  usernameError ||
                                  !password ||
                                  passwordError
                                ) {
                                  return
                                }

                                const response = await loginUser({
                                  variables: {
                                    input: { username, password },
                                  },
                                })

                                if (response) {
                                  const {
                                    token,
                                    roles,
                                  } = response.data.loginUser
                                  sessionStorage.setItem('token', token)
                                  localStorage.setItem(
                                    'sessionStorage',
                                    JSON.stringify(sessionStorage)
                                  )

                                  context.onSetLogin &&
                                    context.onSetLogin({
                                      user: roles.allowed,
                                      token,
                                    })

                                  const { r } = queryString.parse(
                                    this.props.location.search
                                  )

                                  if (r && typeof r === 'string') {
                                    history.replace(r)
                                  } else {
                                    history.replace('/')
                                  }
                                }
                              } catch (error) {
                                // tslint:disable-next-line:no-console
                                console.log('something is wrong', error)
                                this.setState({
                                  loginError: true,
                                })
                                return
                              }
                            }}
                          >
                            <Input
                              id="username"
                              value={username}
                              onChange={this.handleUsernameChange}
                              type="text"
                              error={usernameError}
                              label="Usuario"
                              required
                            />
                            {usernameError ? (
                              <Error>
                                La longitud máxima debe ser hasta 40 caracteres.
                              </Error>
                            ) : (
                              <Spacer />
                            )}
                            <Input
                              id="password"
                              value={password}
                              onChange={this.handlePasswordChange}
                              type="password"
                              error={passwordError}
                              label="Contraseña"
                              required
                            />

                            {passwordError ? (
                              <Error>
                                La longitud máxima debe ser hasta 40 caracteres.
                              </Error>
                            ) : (
                              <Spacer />
                            )}

                            <LoginButtonContainer>
                              <Button
                                color="primary"
                                disabled={
                                  !username ||
                                  usernameError ||
                                  !password ||
                                  passwordError
                                }
                              >
                                {loading ? 'Cargando...' : 'Iniciar Sesión'}
                              </Button>
                            </LoginButtonContainer>
                          </form>
                        </React.Fragment>
                      )}
                    </Mutation>
                    <ErrorContainer>
                      {loginError ? (
                        <Error>Usuario y/o contraseña incorrectos</Error>
                      ) : null}
                    </ErrorContainer>
                  </Paper>
                </Grid.Column>
                <Grid.Column md={3} />
              </Grid.Row>
            </Grid.ContainerFluid>
          </Container>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default withRouter(LoginForm)
