import React from 'react'

import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import OrderTable from './components/OrderTable'
import OrderTransactionTable from './components/OrderDetailsTable'

import { PageTopSection } from './style'

export const OrderList = () => {
  return (
    <PageWrapper title="Lista de Órdenes">
      <Paper>
        <OrderTable />
      </Paper>
      <PageTopSection />
    </PageWrapper>
  )
}

export const OrderTransactionList = () => {
  return (
    <PageWrapper title={'Detalles de la Orden'}>
      <Paper>
        <OrderTransactionTable />
      </Paper>
      <PageTopSection />
    </PageWrapper>
  )
}
