import React from 'react'
import {
  Header,
  DesktopHeader,
  MobileHeader,
  Title,
  Subtitle,
  CollapseIcon,
  Body,
} from './style'

interface Props {
  title?: string
  subtitle?: string
  openedByDefault?: boolean
  noBottomMargin?: boolean
  children: React.ReactNode
}

interface State {
  collapsed: boolean
}

class FormSection extends React.Component<Props, State> {
  state: State = {
    collapsed: !this.props.openedByDefault,
  }

  onCollapse = () => {
    this.setState(state => ({ collapsed: !state.collapsed }))
  }

  render() {
    const { title, subtitle, noBottomMargin, children } = this.props
    const { collapsed } = this.state

    return (
      <React.Fragment>
        <Header>
          <DesktopHeader>
            {title ? <Title>{title}</Title> : null}
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          </DesktopHeader>
          <MobileHeader onClick={this.onCollapse}>
            <React.Fragment>
              {title ? <Title>{title}</Title> : null}
              {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
            </React.Fragment>
            <React.Fragment>
              <CollapseIcon name={collapsed ? 'down-open' : 'up-open'} />
            </React.Fragment>
          </MobileHeader>
        </Header>
        <Body collapsed={collapsed} noBottomMargin={!!noBottomMargin}>
          {children}
        </Body>
      </React.Fragment>
    )
  }
}

export default FormSection
