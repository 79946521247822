import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult, Mutation } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import qs from 'qs'

import { PAGE_SIZE_OPTIONS } from '../../../../config'
import DataTable from '../../../../components/DataTable'
import { parsePageParams } from '../../../../helpers/params'
import { Tooltip } from '../../../../components/FormElements'
import Modal from '../../../../components/Modals/ModalRoot'

import ThemeContext from '../../../../context/ThemeContext'

import { ActionContainer, ConfirmAction } from '../../../../components/Globals'
import { EditIcon } from '../style'
import Loading from '../../../../components/Loader'

const GET_TRANSACTION_FILES_QUERY = gql`
  query transactionsFiles(
    $options: TransactionsFilesOptions!
    $filters: TransactionsFilesFilters!
  ) {
    transactionsFiles(options: $options, filters: $filters) {
      nodes {
        id
        name
        status
        updated_at
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`
export const UPDATE_TRANSACTION_FILE_MUTATION = gql`
  mutation editTransactionFile($input: EditTransactionFileInput!) {
    editTransactionFile(input: $input)
  }
`

type TransactionFileNode = Record<string, any>
type FilterOptionType = Record<string, any>

interface orderTableState {
  filtersOpened: boolean
  filterSelected: FilterOptionType | null
  transactionFileFromUrl: string
  action: string
}

interface Data {
  transactionsFiles: {
    nodes: TransactionFileNode[]
    pagination: {
      total: number
    }
  }
}
class TransactionsFilesTable extends React.Component<
  RouteComponentProps<{}>,
  orderTableState
> {
  state: orderTableState = {
    filtersOpened: false,
    filterSelected: null,
    transactionFileFromUrl: '',
    action: '',
  }
  componentWillMount() {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
    })
  }

  componentDidMount() {
    const { status } = parsePageParams(this.props.location.search.slice(1))
    if (status) {
      this.setState({ filtersOpened: true, transactionFileFromUrl: status })
    }
  }

  toggleFilterSection = () => {
    const { filtersOpened } = this.state
    this.setState({ filtersOpened: !filtersOpened })
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  extractPageParams = () => {
    const { page = 1, page_size, s, sort_by, status } = parsePageParams(
      window.location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
      status,
    }
  }

  onPageChange = (page: number) => {
    const params = this.extractPageParams()

    params.page = page

    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    this.updatePageParams(params)
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText
    params.page = 1
    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  onFetch = (formatParams: any) => {
    const params = this.extractPageParams()

    const { filters } = formatParams

    if (filters && filters.status) {
      params.status = filters.status.value
    }

    if (params.page) {
      delete params.page
    }

    if (params.s) {
      delete params.s
    }

    this.updatePageParams(params)
  }

  onFilterSelected = (
    index: number,
    selected: FilterOptionType | null | undefined
  ) => {
    this.setState({ filterSelected: { index, selected } })
  }

  render() {
    const {
      filtersOpened,
      filterSelected,
      transactionFileFromUrl,
      action,
    } = this.state

    const { page_size, sort_by, s, page, status } = this.extractPageParams()

    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

    return (
      <ThemeContext.Consumer>
        {({ forceShowNotification }) => (
          <Query
            variables={{
              options: {
                limit: pageSize,
                offset: page ? (page - 1) * pageSize : 0,
                sortBy: sort_by,
              },
              filters: {
                s,
                status,
              },
            }}
            query={GET_TRANSACTION_FILES_QUERY}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, refetch }: QueryResult<Data, any>) => {
              if (loading) {
                return <p>Loading...</p>
              }
              if (error) {
                return <p>Error</p>
              }
              if (!data) {
                return <p>No Data</p>
              }
              if (!data.transactionsFiles) {
                return <p>No Transactions Files</p>
              }

              const { nodes, pagination } = data.transactionsFiles

              const statusOptions: FilterOptionType[] = []
              statusOptions.push(
                {
                  label: 'Todos los estados',
                  value: 'all',
                },
                { label: 'Procesado', value: 'proccesing' },
                { label: 'Pendiente de aprobación', value: 'pending' },
                { label: 'Rechazada', value: 'rejected' },
                { label: 'Aprobada', value: 'approved' }
              )

              const columns = [
                {
                  header: 'Archivo',
                  key: 'name',
                },

                {
                  header: 'Estado',
                  key: 'status',
                  sortable: true,
                  Cell: ({ status }: TransactionFileNode) => {
                    let statusStr = ''
                    statusOptions.filter(s => {
                      if (s.value === status) {
                        statusStr = s.label
                      }
                      return s.value === status
                    })
                    return statusStr
                  },
                },

                {
                  header: 'Última Actualización',
                  key: 'updated_at',
                  sortable: false,
                  Cell: (transactionFile: TransactionFileNode) => {
                    const d = new Date(transactionFile.updated_at)
                    const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
                    const mm =
                      (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
                    const yyyy = d.getFullYear()
                    const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
                    const min =
                      (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
                    const sec =
                      (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
                    const date =
                      yyyy +
                      '-' +
                      mm +
                      '-' +
                      dd +
                      ' ' +
                      hour +
                      ':' +
                      min +
                      ':' +
                      sec
                    return date
                  },
                },
                {
                  header: 'Acciones',
                  key: 'actions',
                  width: 99,
                  sortable: false,
                  Cell: (file: TransactionFileNode) => {
                    if (file.status == 'pending') {
                      return (
                        <ActionContainer>
                          <Modal>
                            {({ openModal, closeModal }) => {
                              return (
                                <Mutation
                                  mutation={UPDATE_TRANSACTION_FILE_MUTATION}
                                  onCompleted={() => {
                                    refetch().finally(() => {
                                      forceShowNotification &&
                                        forceShowNotification({
                                          type: 'ok',
                                          message:
                                            'Se ' +
                                            action +
                                            ' la carga de millas ' +
                                            file.name,
                                        })
                                    })
                                  }}
                                  onError={() => {
                                    forceShowNotification &&
                                      forceShowNotification({
                                        type: 'alert',
                                        message:
                                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                      })
                                  }}
                                >
                                  {(editTransactionFile, { loading }) =>
                                    !loading ? (
                                      <>
                                        <ConfirmAction
                                          onClick={() => {
                                            this.setState({ action: 'aprobó' })
                                            openModal('CONFIRM', {
                                              header: {
                                                title:
                                                  '¿Aprobar el archivo ' +
                                                  file.name +
                                                  ' ?',
                                                textAlign: 'left',
                                              },
                                              modalSize: 'md',
                                              description: '',
                                              type: 'ok',
                                              functionOK: () => {
                                                closeModal()
                                                editTransactionFile({
                                                  variables: {
                                                    input: {
                                                      status: 'approved',
                                                      fileId: file.id,
                                                    },
                                                  },
                                                })
                                              },

                                              functionClose: () => {},
                                            })
                                          }}
                                        >
                                          <Tooltip
                                            id={`approved-${file.id}`}
                                            message="Aprobar"
                                          >
                                            <EditIcon name="ok" />
                                          </Tooltip>
                                        </ConfirmAction>
                                        <ConfirmAction
                                          onClick={() => {
                                            this.setState({ action: 'rechazó' })
                                            openModal('CONFIRM', {
                                              header: {
                                                title:
                                                  '¿Rechazar el archivo ' +
                                                  file.name +
                                                  ' ?',
                                                textAlign: 'left',
                                              },
                                              modalSize: 'md',
                                              description: '',
                                              type: 'alert',
                                              functionOK: () => {
                                                closeModal()
                                                editTransactionFile({
                                                  variables: {
                                                    input: {
                                                      status: 'rejected',
                                                      fileId: file.id,
                                                    },
                                                  },
                                                })
                                              },

                                              functionClose: () => {},
                                            })
                                          }}
                                        >
                                          <Tooltip
                                            id={`rejected-${file.id}`}
                                            message="Rechazar"
                                          >
                                            <EditIcon
                                              name="cancel"
                                              color="black"
                                            />
                                          </Tooltip>
                                        </ConfirmAction>
                                      </>
                                    ) : (
                                      <Loading />
                                    )
                                  }
                                </Mutation>
                              )
                            }}
                          </Modal>
                        </ActionContainer>
                      )
                    }
                    return ''
                  },
                },
              ]

              return (
                <React.Fragment>
                  <DataTable
                    indexKey="id"
                    columns={columns}
                    data={nodes}
                    loading={loading}
                    totalItemsCount={pagination.total}
                    sortBy={sort_by}
                    filters={[
                      {
                        key: 'status',
                        label: 'Estados',
                        options: statusOptions,
                      },
                    ]}
                    page={page || 1}
                    pageSize={pageSize}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    searchText={s}
                    onFetch={this.onFetch}
                    onSort={this.onSortByChange}
                    onPageChange={this.onPageChange}
                    onPageSizeChange={this.onPageSizeChange}
                    filtersOpened={filtersOpened}
                    toggleFilterSection={this.toggleFilterSection}
                    filterSelected={filterSelected}
                    onFilterSelected={this.onFilterSelected}
                    supplierFromUrl={transactionFileFromUrl}
                  />
                </React.Fragment>
              )
            }}
          </Query>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default withRouter(TransactionsFilesTable)
