import React from 'react'

import PageWrapper from '../../../components/PageWrapper'
import ProcessOrderTable from './components/ProcessOrderTable'

function OrderList() {
  return (
    <PageWrapper title="Procesar Órdenes">
      <ProcessOrderTable />
    </PageWrapper>
  )
}

export default OrderList
