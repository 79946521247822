import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import { TextArea } from '../../../../../components/FormElements'
import { Spacer } from '../../../../../components/Globals'

import { Field, validateField } from '../../../../../helpers/validations'

import PqrsStatusSelector, { PqrsStatusOption } from './PqrsStatusSelector'

import {
  Title,
  DescriptionContainer,
  ConfirmContainer,
  Feedback,
  ForwardButton,
  FeedbackError,
  Error,
} from './style'

interface Props {
  pqrs: any
  refetch: any
}

export const UPDATE_PQRS_MUTATION = gql`
  mutation editPqrsMutation($input: EditPqrsInput!) {
    editPqrs(input: $input)
  }
`

interface State {
  id: string
  status: Field
  feedback: Field
}

export class PqrsEditModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const { id, status, feedback } = this.props.pqrs
    this.state = {
      id: id,
      status: {
        value: { name: status.name, id: status.id },
        error: false,
        errorText: '',
      },
      feedback: { value: feedback, error: false, errorText: '' },
    }
  }

  handleStatusChange = (status: PqrsStatusOption) => {
    this.setState({
      status: {
        value: status,
        error: false,
        errorText: '',
      },
    })
  }

  handleFeedbackChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    const businessName = value

    const validations = validateField(businessName, [
      {
        name: 'shorterThan',
        value: 400,
      },
    ])

    if (validations.value) {
      this.setState({
        feedback: {
          value: businessName,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      feedback: {
        value: businessName,
        error: false,
        errorText: '',
      },
    })
  }

  render() {
    const { pqrs, refetch } = this.props
    const { status, feedback } = this.state

    return (
      <>
        <Title>Responder PQRS</Title>
        <DescriptionContainer />
        <ConfirmContainer>
          <TextArea
            rows={10}
            id="feedback"
            value={feedback.value}
            onChange={this.handleFeedbackChange}
            type="text"
            error={feedback.error}
            label="Descripción"
          />
          {feedback.error ? <Error>{feedback.errorText}</Error> : <Spacer />}
          <PqrsStatusSelector
            value={status.value}
            onChange={this.handleStatusChange}
            isRequired={true}
            error={status.error}
            showLabel
          />
          {status.error ? <Error>{status.errorText}</Error> : <Spacer />}
          <Spacer />
          <Mutation
            mutation={UPDATE_PQRS_MUTATION}
            onCompleted={() => {
              refetch()
            }}
          >
            {(updatePqrs, { loading, called, error }) => (
              <React.Fragment>
                {called &&
                  !loading &&
                  (!error ? (
                    <Feedback>Guardado correctamente</Feedback>
                  ) : (
                    <FeedbackError>
                      La acción solicitada no se pudo completar. Intenténtelo
                      mas tarde por favor.
                    </FeedbackError>
                  ))}

                <ForwardButton
                  color="primary"
                  disabled={loading || feedback.error || status.error}
                  onClick={() => {
                    if (status.error) {
                      return
                    }
                    updatePqrs({
                      variables: {
                        input: {
                          id: `${pqrs.id}`,
                          status: status.value.id,
                          feedback: feedback.value,
                        },
                      },
                    })
                    return
                  }}
                >
                  {loading ? 'Guardando...' : 'Guardar'}
                </ForwardButton>
              </React.Fragment>
            )}
          </Mutation>
        </ConfirmContainer>
      </>
    )
  }
}
