import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'

import { Input, Select } from '../../../../../components/FormElements'
import { Spacer } from '../../../../../components/Globals'

import {
  Title,
  DescriptionContainer,
  ConfirmContainer,
  Feedback,
  ForwardButton,
  FeedbackError,
  Error,
} from './style'

interface Props {
  purchaseOrder: any
  refetch: any
}

export const RESEND_PURCHASE_ORDER_DOCUMENT_MUTATION = gql`
  mutation resendPurchaseOrderDocument($input: ResendPurchaseOrderEmailInput!) {
    resendPurchaseOrderDocument(input: $input)
  }
`

export const RESEND_PURCHASE_ORDER_TAGS = gql`
  mutation resendPurchaseOrderTags($input: ResendPurchaseOrderEmailInput!) {
    resendPurchaseOrderTags(input: $input)
  }
`

export const RECREATE_PURCHASE_ORDER_DOCUMENT = gql`
  mutation recreatePurchaseOrderDocument(
    $input: RecreatePurchaseOrderDocumentInput!
  ) {
    recreatePurchaseOrderDocument(input: $input)
  }
`

function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

interface State {
  email: string
  emailError: boolean
  option: ValueType<{ [key: string]: any } | string | number>
}

class PurchaseOrderActionsModal extends React.Component<Props, State> {
  state: State = {
    email: '',
    emailError: false,
    option: {},
  }

  handleEmailChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const email = value

    if (email.length >= 40) {
      this.setState({
        email,
        emailError: true,
      })
      return
    }

    if (!validateEmail(email)) {
      this.setState({
        email,
        emailError: true,
      })

      return
    }

    this.setState({
      email,
      emailError: false,
    })
  }

  handleTypeChange = (
    option: ValueType<{ [key: string]: any } | string | number>
  ) => {
    this.setState({ option })

    if (option && option['value'] === 'recreate-purchase-order-document') {
      this.setState({
        email: '',
        emailError: false,
      })
    }
  }

  render() {
    const { purchaseOrder, refetch } = this.props
    const { email, option, emailError } = this.state

    const purchaseOrderActions = [
      {
        value: 'resend-purchase-order-document',
        label: 'Reenviar documento de Orden de Compra',
      },
      {
        value: 'recreate-purchase-order-document',
        label: 'Recrear documento de Orden de Compra',
      },
    ]
    if (purchaseOrder.supplier.tagProducts) {
      purchaseOrderActions.push({
        value: 'resend-purchase-order-tags',
        label: 'Reenviar PDF de Etiquetas',
      })
    }
    return (
      <>
        <Title>Reenviar</Title>
        <DescriptionContainer />
        <ConfirmContainer>
          <Select
            label="Tipo"
            placeholder="Seleccione..."
            options={
              purchaseOrder.documentUrl
                ? purchaseOrderActions
                : [
                    {
                      value: 'recreate-purchase-order-document',
                      label: 'Generar documento de Orden de Compra',
                    },
                  ]
            }
            onChange={(
              option: ValueType<string | number | { [key: string]: any }>
            ) => this.handleTypeChange(option)}
          />
          <Spacer />
          {option &&
            (option['value'] === 'resend-purchase-order-document' ||
              option['value'] === 'resend-purchase-order-tags') && (
              <React.Fragment>
                <Input
                  id="email"
                  value={email}
                  onChange={this.handleEmailChange}
                  type="text"
                  label="Email persona de contacto"
                  required
                />
                {emailError ? (
                  <Error>Email ingresado no válido..</Error>
                ) : (
                  <Spacer />
                )}
              </React.Fragment>
            )}

          <Mutation
            mutation={RESEND_PURCHASE_ORDER_DOCUMENT_MUTATION}
            onCompleted={() => {
              refetch()
            }}
          >
            {(
              resendPurchaseOrderDocument,
              {
                loading: loadingDocument,
                called: calledDocument,
                error: errorDocument,
              }
            ) => (
              <Mutation
                mutation={RESEND_PURCHASE_ORDER_TAGS}
                onCompleted={() => {
                  refetch()
                }}
              >
                {(
                  resendPurchaseOrderTags,
                  { loading: loadingTags, called: calledTags, error: errorTags }
                ) => (
                  <Mutation
                    mutation={RECREATE_PURCHASE_ORDER_DOCUMENT}
                    onCompleted={() => {
                      refetch()
                    }}
                  >
                    {(
                      recreatePurchaseOrderDocument,
                      { loading, called, error }
                    ) => (
                      <React.Fragment>
                        {calledDocument &&
                          !loadingDocument &&
                          (!errorDocument ? (
                            <Feedback>
                              Mensaje de Órden de compra reenviado correctamente
                            </Feedback>
                          ) : (
                            <FeedbackError>
                              La acción solicitada no se pudo completar.
                              Intenténtelo mas tarde por favor.
                            </FeedbackError>
                          ))}

                        {calledTags &&
                          !loadingTags &&
                          (!errorTags ? (
                            <Feedback>
                              Mensaje de Tags de compras reenviado correctamente
                            </Feedback>
                          ) : (
                            <FeedbackError>
                              La acción solicitada no se pudo completar.
                              Intenténtelo mas tarde por favor.
                            </FeedbackError>
                          ))}

                        {called &&
                          !loading &&
                          (!error ? (
                            <Feedback>
                              PDF de Órden de compra generado correctamente
                            </Feedback>
                          ) : (
                            <FeedbackError>
                              La acción solicitada no se pudo completar.
                              Intenténtelo mas tarde por favor.
                            </FeedbackError>
                          ))}

                        <ForwardButton
                          color="primary"
                          disabled={
                            loadingTags ||
                            loadingDocument ||
                            loading ||
                            emailError
                          }
                          onClick={() => {
                            if (!option) {
                              return
                            }

                            if (
                              option['value'] ===
                              'resend-purchase-order-document'
                            ) {
                              resendPurchaseOrderDocument({
                                variables: {
                                  input: {
                                    purchaseOrderId: `${purchaseOrder.id}`,
                                    email,
                                  },
                                },
                              })
                              return
                            }

                            if (
                              option['value'] === 'resend-purchase-order-tags'
                            ) {
                              resendPurchaseOrderTags({
                                variables: {
                                  input: {
                                    purchaseOrderId: `${purchaseOrder.id}`,
                                    email,
                                  },
                                },
                              })
                              return
                            }

                            if (
                              option['value'] ===
                              'recreate-purchase-order-document'
                            ) {
                              recreatePurchaseOrderDocument({
                                variables: {
                                  input: {
                                    purchaseOrderId: `${purchaseOrder.id}`,
                                  },
                                },
                              })
                              return
                            }
                          }}
                        >
                          {loadingDocument || loadingTags
                            ? 'Cargando...'
                            : 'Aceptar'}
                        </ForwardButton>
                      </React.Fragment>
                    )}
                  </Mutation>
                )}
              </Mutation>
            )}
          </Mutation>
        </ConfirmContainer>
      </>
    )
  }
}

export default PurchaseOrderActionsModal
