import styled from 'styled-components'

import Icon from '../../../components/Icon'
import { Button } from '../../../components/Buttons'

import sizes from '../../../styles/media'

export const PageTopSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  & a {
    width: 100%;
  }

  @media ${sizes.sm} {
    & a {
      width: auto;
    }
  }
`

export const CreateButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
  @media ${sizes.sm} {
    width: auto;
  }
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const EditIcon = styled(Icon)`
  color: #18b0f7;
  font-size: 20px;
`
export const DeleteIcon = styled(Icon)`
  color: #454545;
  font-size: 20px;
`

export const FeedbackError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ff3b30;
`

export const GenerateOrders = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`
