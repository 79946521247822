import styled from 'styled-components'
import { ToastContainer as TC } from 'react-toastify'
import sizes from '../../styles/media'
const className = 'dark-toast'
const toastClassName = 'toast-container'
const ToastIconContainerClass = 'toast-icon-container'
interface Props {
  type?: string
  color?: string
}

export const ToastContainer = styled(TC).attrs<Props>({
  className,
  toastClassName,
})`
  .${toastClassName} {
    box-shadow: 0 0 8px 0 rgba(6, 6, 6, 0.45);
    border-radius: 0.7em;
    padding: 0;
    font-size: 1.2em;
  }
`

export const CloseButton = styled.button`
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  padding: 0;
`

export const ToastContainerBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1.5px solid ${props => props.color};
  border-radius: 0.7em;

  .${ToastIconContainerClass} {
    background: ${props => props.color};
    color: ${props => props.color};
    height: 100%;
    width: 80px;
    margin: 0;
  }
`
export const ToastIconContainer = styled.div.attrs({
  className: ToastIconContainerClass,
})``
export const ToastTextContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const ToastText = styled.p`
  margin: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #858585;
  font-size: 14px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 200px;
  @media ${sizes.xl} {
    width: 200px;
  }
`
