import styled from 'styled-components'
import Icon from '../../../components/Icon'

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const WiewIcon = styled(Icon)`
  color: #454545;
  font-size: 20px;
`
export const ForwardIcon = styled(Icon)`
  color: #454545;
  font-size: 20px;
`

export const UpdatePruchaseIcon = styled(Icon)`
  color: #18b0f7;
  font-size: 20px;
`

export const ShowItmesIcon = styled(Icon)`
  color: #18b0f7;
  font-size: 20px;
`
export const ConfirmAction = styled.div`
  cursor: pointer;
`

export const Semaphore = styled.li`
  list-style-type: none;
  &::before {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${props => props.color};
    margin-right: 8px;
    content: '';
  }
`
