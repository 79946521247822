import * as React from "react";
import { useTabs } from "./Tabs";

import { PanelTab } from './style'

export interface PanelProps {
  label: string;
}

export const Panel: React.FC<PanelProps> = props => {
  const { activeTab } = useTabs();
  return activeTab === props.label ? <PanelTab>{props.children}</PanelTab> : null;
};
