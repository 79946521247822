import React from 'react'
import Modal from 'react-modal'

import { Button } from '../Buttons'
import { Spacer } from '../Globals'
import Badge from '../Badge'
import Icon from '../Icon'

import {
  ConfirmContainer,
  Wrapper,
  modalStyles,
  Title,
  DescriptionContainer,
  Header,
  CloseIcon,
  CloseButton,
} from './style'
interface ModalProps {
  header: {
    title?: string
    fontSize?: string
    textAlign?: 'center' | 'right' | 'left'
    color?: string
  }
  description: React.ReactNode
  type?: string
  showCloseTop?: boolean
  modalSize?: 'sm' | 'md' | 'lg'
  functionOK?: () => void
  functionClose?: () => void
}

interface ModalActions {
  openModal: (
    modalType: 'ALERT' | 'DETAILS' | 'CONFIRM' | 'FORM',
    modalProps: ModalProps
  ) => any
  closeModal: () => any
}

interface ModalRootProps {
  children: (actions: ModalActions) => React.ReactNode
}

interface ModalRootState {
  isModalOpen: boolean
  modalType: string | null
  modalProps: ModalProps | null
}

class ModalRoot extends React.Component<ModalRootProps, ModalRootState> {
  state: ModalRootState = {
    isModalOpen: false,
    modalType: null,
    modalProps: null,
  }

  openModal = (modalType: string, modalProps: ModalProps) => {
    this.setState({ isModalOpen: true, modalType, modalProps })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false, modalType: null, modalProps: null })
  }

  render() {
    const { modalType, modalProps, isModalOpen } = this.state

    if (!modalType) {
      return this.props.children({
        openModal: this.openModal,
        closeModal: this.closeModal,
      })
    }

    if (!modalProps) {
      return this.props.children({
        openModal: this.openModal,
        closeModal: this.closeModal,
      })
    }

    const {
      type,
      header,
      description,
      functionClose,
      functionOK,
      showCloseTop,
      modalSize,
    } = modalProps

    let { title, color, textAlign, fontSize } = header

    let icon = ''
    switch (type) {
      case 'fail': {
        icon = 'cancel'
        break
      }
      case 'ok': {
        icon = 'ok'
        break
      }
      case 'alert': {
        icon = 'attention'
        break
      }
      default: {
        break
      }
    }
    if (modalType == 'DETAILS') {
      color = color ? color : '#454545'
      fontSize = fontSize ? fontSize : '18px'
      textAlign = textAlign ? textAlign : 'left'
    }

    switch (modalSize) {
      case 'sm': {
        modalStyles.content.maxWidth = '400px'
        break
      }
      case 'md': {
        modalStyles.content.maxWidth = '600px'
        break
      }
      case 'lg': {
        modalStyles.content.maxWidth = '1000px'
        break
      }
      default: {
        break
      }
    }
    return (
      <React.Fragment>
        {this.props.children({
          openModal: this.openModal,
          closeModal: this.closeModal,
        })}
        <Modal
          ariaHideApp={false}
          isOpen={isModalOpen}
          onRequestClose={close}
          style={modalStyles}
          contentLabel="Cofirmar accion"
          closeTimeoutMS={330}
          shouldCloseOnOverlayClick={false}
        >
          <Wrapper>
            {showCloseTop && (
              <Header>
                <CloseButton onClick={this.closeModal}>
                  <CloseIcon name="cancel" />
                </CloseButton>
              </Header>
            )}
            {type &&
              icon && (
                <Badge type={type} size="l">
                  <Icon color="white" size="24px" name={icon} />
                </Badge>
              )}
            {title && (
              <Title style={{ color, textAlign, fontSize }}>{title}</Title>
            )}
            <DescriptionContainer type={modalType} style={{}}>
              {description}
            </DescriptionContainer>
            {(functionClose ||
              modalType == 'ALERT' ||
              modalType == 'DETAILS' ||
              functionOK) &&
              !showCloseTop && (
                <ConfirmContainer>
                  {functionOK && (
                    <Button color="primary" onClick={functionOK}>
                      Aceptar
                    </Button>
                  )}
                  <Spacer />
                  {(functionClose ||
                    modalType == 'ALERT' ||
                    modalType == 'DETAILS') && (
                    <Button
                      color="secondary"
                      onClick={this.closeModal || functionClose}
                    >
                      {modalType == 'DETAILS' || modalType == 'ALERT'
                        ? 'Cerrar'
                        : 'Cancelar'}
                    </Button>
                  )}
                </ConfirmContainer>
              )}
          </Wrapper>
        </Modal>
      </React.Fragment>
    )
  }
}

export default ModalRoot
