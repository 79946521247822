import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import PageWrapper from '../../../components/PageWrapper'
import CreateVariationForm from './components/CreateVariationForm'
import EditVariationForm from './components/EditVariationForm'

interface RouteParams {
  id?: string
  action: string
}

const GET_VARIATION_QUERY = gql`
  query getVariationQuery($id: ID!) {
    variation(id: $id) {
      id
      reference
      supplierCost
      supplierDiscount
      supplierTaxes
      supplierSpecialTaxes
      taxes
      courierCost
      courierTaxes
      profit
      price
      regularPrice
      refundPrice
      cardProfit
      taxes
      mileCost
      length
      width
      height
      weight
      status
      arriveInDays
      assets {
        id
        url
        type
        order
      }
      metaData
      inventory {
        id
        sku
        localStock
        storageStock
      }
      options {
        id
        name
        feature {
          id
          name
        }
      }
      product {
        id
        name
        features {
          id
          name
          options {
            id
            name
          }
        }
      }
    }
  }
`

interface Data {
  variation: any
}
class Variation extends React.Component<RouteComponentProps<RouteParams>> {
  onVariationSaved = () => {
    this.props.history.push('/variations')
  }

  render() {
    const { id, action } = this.props.match.params

    return (
      <PageWrapper
        title={`${action === 'create' ? 'Crear' : 'Editar'} Variación`}
      >
        {action === 'create' ? (
          <CreateVariationForm onCreated={this.onVariationSaved} />
        ) : (
          <Query
            variables={{ id }}
            query={GET_VARIATION_QUERY}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return null
              }

              if (error) {
                return null
              }

              if (!data) {
                return null
              }

              return (
                <EditVariationForm
                  variation={data.variation}
                  onUpdated={this.onVariationSaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(Variation)
