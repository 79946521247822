import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs'

import { PAGE_SIZE_OPTIONS } from '../../../../config'
import DataTable from '../../../../components/DataTable'
import { parseCursorPageParams } from '../../../../helpers/params'
import { Tooltip } from '../../../../components/FormElements'
import { EyeIcon } from '../style'

import { ActionContainer } from '../../../../components/Globals'
import ThemeContext from '../../../../context/ThemeContext'

const GET_ORDERS_QUERY = gql`
  query getOrdersQuery($options: OrdersOptions!, $filters: OrdersFilters!) {
    orders(options: $options, filters: $filters) {
      nodes {
        id
        number
        status
        invoiceNumber
        invoiceDate
        createdAt
        customer {
          identificationType
          identificationNumber
          firstName
          lastName
          businessName
        }
      }
      pagination {
        limit
        cursors {
          before
          after
        }
      }
    }
  }
`

type OrderNode = Record<string, any>

interface Data {
  orders: {
    nodes: OrderNode[]
    pagination: {
      limit: number
      cursors: {
        before: string
        after: string
      }
    }
  }
}

type FilterOptionType = Record<string, any>

interface orderTableState {
  filtersOpened: boolean
  filterSelected: FilterOptionType | null
  supplierFromUrl: string
}

class orderTable extends React.Component<
  RouteComponentProps<{}>,
  orderTableState
> {
  state: orderTableState = {
    filtersOpened: false,
    filterSelected: null,
    supplierFromUrl: '',
  }

  componentDidMount() {
    const { supplier } = parseCursorPageParams(
      this.props.location.search.slice(1)
    )

    if (supplier) {
      this.setState({ filtersOpened: true, supplierFromUrl: supplier })
    }
  }

  toggleFilterSection = () => {
    const { filtersOpened } = this.state
    this.setState({ filtersOpened: !filtersOpened })
  }

  extractPageParams = () => {
    const {
      page_size,
      sort_by,
      after,
      before,
      status,
      s,
    } = parseCursorPageParams(this.props.location.search.slice(1))
    return {
      page_size,
      sort_by,
      after,
      before,
      status,
      s,
    }
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText
    params.after = ''
    params.before = ''
    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  onAfter = (after: string | undefined) => {
    const params = this.extractPageParams()
    params.after = after

    if (params.before) {
      delete params.before
    }

    this.updatePageParams(params)
  }

  onBefore = (before: string | undefined) => {
    const params = this.extractPageParams()
    params.before = before

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onFetch = (formatParams: any) => {
    const params = this.extractPageParams()

    const { filters } = formatParams

    if (filters && filters.status) {
      params.status = filters.status.value
    }

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }
    if (params.s) {
      delete params.s
    }

    this.updatePageParams(params)
  }

  onFilterSelected = (
    index: number,
    selected: FilterOptionType | null | undefined
  ) => {
    this.setState({ filterSelected: { index, selected } })
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  render() {
    const { filtersOpened, filterSelected, supplierFromUrl } = this.state

    const { page_size, after, before, status, s } = this.extractPageParams()

    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

    return (
      <ThemeContext.Consumer>
        {context => (
          <Query
            variables={{
              options: {
                limit: pageSize,
                after,
                before,
              },
              filters: { status, s },
            }}
            query={GET_ORDERS_QUERY}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }: QueryResult<Data, any>) => {
              if (loading) {
                return <p>Loading...</p>
              }
              if (error) {
                return <p>Error</p>
              }
              if (!data) {
                return <p>No Data</p>
              }

              const { nodes, pagination } = data.orders

              //const { orderSuppliers } = data

              const statusOptions: FilterOptionType[] = []
              statusOptions.push(
                {
                  label: 'Todos los estados',
                  value: 'all',
                },

                { label: 'Aprobado', value: 'approved' },
                { label: 'Pagado', value: 'paid' },
                { label: 'Procesando', value: 'processing' },
                { label: 'Creada', value: 'created' },
                { label: 'Reversado', value: 'reversed' },
                { label: 'Cancelado', value: 'canceled' }
              )

              const columns = [
                {
                  header: '# Orden',
                  key: 'number',
                  sortable: false,
                },
                {
                  header: 'Estado',
                  key: 'status',
                  sortable: false,
                  Cell: ({ status }: OrderNode) => {
                    let statusStr = ''
                    statusOptions.filter(s => {
                      if (s.value === status) {
                        statusStr = s.label
                      }
                      return s.value === status
                    })
                    return statusStr
                  },
                },
                {
                  header: '# Factura',
                  key: 'invoiceNumber',
                  sortable: false,
                },
                {
                  header: 'Fecha Factura',
                  key: 'invoiceDate',
                  sortable: false,
                  Cell: (order: OrderNode) => {
                    if (order.invoiceDate) {
                      const d = new Date(order.invoiceDate)
                      const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
                      const mm =
                        (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
                      const yyyy = d.getFullYear()
                      const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
                      const min =
                        (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
                      const sec =
                        (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
                      const date =
                        yyyy +
                        '-' +
                        mm +
                        '-' +
                        dd +
                        ' ' +
                        hour +
                        ':' +
                        min +
                        ':' +
                        sec
                      return date
                    }
                    return ''
                  },
                },
                {
                  header: 'Tipo Identificación',
                  key: 'indetificationType',
                  sortable: false,
                  width: 100,
                  Cell: ({ customer }: OrderNode) => {
                    return customer && customer.identificationType
                  },
                },
                {
                  header: '# Identificación',
                  key: 'indetificationNumber',
                  sortable: false,
                  Cell: ({ customer }: OrderNode) => {
                    return customer && customer.identificationNumber
                  },
                },
                {
                  header: 'Cliente',
                  key: 'customer',
                  sortable: false,
                  Cell: ({ customer }: OrderNode) => {
                    return (
                      customer &&
                      customer.businessName +
                        customer.firstName +
                        ' ' +
                        customer.lastName
                    )
                  },
                },

                {
                  header: 'Fecha Orden',
                  key: 'createdAt',
                  sortable: false,
                  Cell: (order: OrderNode) => {
                    const d = new Date(order.createdAt)
                    const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
                    const mm =
                      (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
                    const yyyy = d.getFullYear()
                    const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
                    const min =
                      (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
                    const sec =
                      (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
                    const date =
                      yyyy +
                      '-' +
                      mm +
                      '-' +
                      dd +
                      ' ' +
                      hour +
                      ':' +
                      min +
                      ':' +
                      sec
                    return date
                  },
                },
                {
                  header: 'Acciones',
                  key: 'actions',
                  width: 99,
                  sortable: false,
                  Cell: (order: OrderNode) => (
                    <ActionContainer>
                      <Link to={'/orders/' + order.id}>
                        <Tooltip
                          id={`show-${order.id}-transactions`}
                          message="Detalle de la orden"
                        >
                          <EyeIcon name="eye" />
                        </Tooltip>
                      </Link>
                    </ActionContainer>
                  ),
                },
              ]

              return (
                <React.Fragment>
                  <DataTable
                    indexKey="number"
                    columns={columns}
                    data={nodes}
                    loading={loading}
                    sortBy={''}
                    pageSize={pageSize}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    onSort={this.onSortByChange}
                    onPageSizeChange={this.onPageSizeChange}
                    onAfter={this.onAfter}
                    onBefore={this.onBefore}
                    after={pagination.cursors.after}
                    before={pagination.cursors.before}
                    filters={[
                      {
                        key: 'status',
                        label: 'Estados',
                        options: statusOptions,
                      },
                    ]}
                    onFetch={this.onFetch}
                    searchText={s}
                    onSearch={this.onSearch}
                    placeholderSearchBar={
                      'Buscar por número de indetificación, nombre cliente'
                    }
                    filtersOpened={filtersOpened}
                    toggleFilterSection={this.toggleFilterSection}
                    filterSelected={filterSelected}
                    onFilterSelected={this.onFilterSelected}
                    supplierFromUrl={supplierFromUrl}
                  />
                </React.Fragment>
              )
            }}
          </Query>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default withRouter(orderTable)
