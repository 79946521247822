import styled from 'styled-components'

export const StyledUserSection = styled.div`
  padding: 0 20px;
`

export const UserSectionWrapper = styled.div`
  padding: 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex: 1;
  align-items: center;
`

export const AvatarContainer = styled.div`
  background-color: #fff;
  border-radius: 30px;
  padding: 5px;
`

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
`

export const UserInfo = styled.div`
  padding: 0 20px;
  font-size: 14px;
  font-weight: bold;
`

export const Header = styled.div`
  color: #ffffff;
  padding: 25px 15px;
  text-align: center;
`

export const Logo = styled.img`
  word-wrap: break-word;
  width: 100%;
  max-width: 130px;
`
