import React from 'react'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

import * as Grid from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import { Input, Error } from '../../../../components/FormElements'
import { Spacer, ActionWrapper } from '../../../../components/Globals'
import Modal from '../../../../components/Modals/ModalRoot'
import { SaveButton } from '../../../../components/Globals'

import ThemeContext from '../../../../context/ThemeContext'

const CREATE_PROMO_TYPE_MUTATION = gql`
  mutation createPromoTypeMutation($input: CreatePromoTypeInput!) {
    createPromoType(input: $input) {
      id
      name
    }
  }
`

interface State {
  name: string
  nameError: boolean
  createError: boolean
  openModal: boolean
}

interface Props {
  onCreated: () => void
}

class CreatePromoTypeForm extends React.Component<Props, State> {
  state: State = {
    name: '',
    nameError: false,
    createError: false,
    openModal: false,
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    if (name.length >= 40) {
      this.setState({
        name,
        nameError: true,
      })

      return
    }

    this.setState({
      name,
      nameError: false,
    })
  }

  render() {
    const { name, nameError } = this.state
    const { onCreated } = this.props
    return (
      <Modal>
        {({ openModal }) => {
          return (
            <React.Fragment>
              <Paper>
                <Grid.Row>
                  <Grid.Column md={4}>
                    <Input
                      id="name"
                      value={name}
                      onChange={this.handleNameChange}
                      type="text"
                      error={nameError}
                      label="Nombre del tipo de promo"
                      required
                    />
                    {nameError ? (
                      <Error>
                        La longitud máxima debe ser de 40 caracteres.
                      </Error>
                    ) : (
                      <Spacer />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Paper>
              <ThemeContext.Consumer>
                {notify => (
                  <Mutation
                    mutation={CREATE_PROMO_TYPE_MUTATION}
                    onCompleted={() => {
                      onCreated()
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message: 'Tipo de promoción creada correctamente',
                        })
                    }}
                    onError={() => {
                      openModal('ALERT', {
                        header: {
                          title: 'ALERTA',
                        },
                        description:
                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                        type: 'fail',
                      })
                    }}
                  >
                    {(createPromoType, { loading, error, called }) => {
                      return (
                        <ActionWrapper>
                          <SaveButton
                            color="primary"
                            disabled={loading}
                            onClick={() => {
                              if (!name || nameError) {
                                this.setState({
                                  nameError: true,
                                })
                                return
                              }

                              createPromoType({
                                variables: { input: { name } },
                              })
                            }}
                          >
                            {loading
                              ? 'Creando tipo de promo...'
                              : 'Crear Tipo de Promo'}
                          </SaveButton>
                        </ActionWrapper>
                      )
                    }}
                  </Mutation>
                )}
              </ThemeContext.Consumer>
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
}

export default CreatePromoTypeForm
