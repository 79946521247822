import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import qs from 'qs'
import { RouteComponentProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'

import Datatable from '../../../../components/DataTable'
import { PAGE_SIZE_OPTIONS } from '../../../../config'
import { parsePageParams } from '../../../../helpers/params'
import { Tooltip } from '../../../../components/FormElements'
// import Modal from '../../../../components/Modals/ModalRoot'
import {
  ActionContainer,
  EditIcon,
  // DeleteIcon,
  // ConfirmAction,
} from '../../../../components/Globals'

const GET_COMMERCES_QUERY = gql`
  query getCommercesQuery(
    $filters: CommercesFilters!
    $options: CommercesOptions!
  ) {
    commerces(filters: $filters, options: $options) {
      nodes {
        id
        name
        merchantCode
        status
      }
      pagination {
        total
      }
    }
  }
`

type CommerceNode = Record<string, any>

type Data = {
  commerces: {
    nodes: CommerceNode[]
    pagination: {
      total: number
    }
  }
}

export default withRouter(function CommerceTable({
  history,
  location,
}: RouteComponentProps) {
  const updatePageParams = (params: object) => {
    history.push({
      pathname: history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  const extractPageParams = () => {
    const { page = 1, page_size, s, sort_by } = parsePageParams(
      location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
    }
  }

  const onSortByChange = (sortBy: string | undefined) => {
    const params = extractPageParams()

    params.sort_by = sortBy

    updatePageParams(params)
  }

  const onPageSizeChange = (pageSize: number) => {
    const params = extractPageParams()

    params.page_size = pageSize

    updatePageParams(params)
  }

  const onPageChange = (page: number) => {
    const params = extractPageParams()

    params.page = page

    updatePageParams(params)
  }

  const onSearch = (searchText: string | undefined) => {
    const params = extractPageParams()

    params.s = searchText
    params.page = 1
    updatePageParams(params)
  }

  const { page, page_size, s, sort_by } = extractPageParams()

  const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

  const columns = [
    {
      header: 'Nombre',
      key: 'name',
    },
    {
      header: 'Código',
      key: 'merchantCode',
    },
    {
      header: 'Estado',
      key: 'status',
      width: 210,
      Cell: ({ status }: CommerceNode) => {
        const statusCell = { value: status, label: '' }

        if (status === 'active') {
          statusCell.label = 'Activo'
        }
        if (status === 'inactive') {
          statusCell.label = 'Inactivo'
        }

        return statusCell.label
      },
    },
    {
      header: 'Acciones',
      key: 'actions',
      width: 99,
      sortable: false,
      Cell: (commerce: CommerceNode) => (
        <ActionContainer>
          <Link to={`/commerces/${commerce.id}/balances`}>
            <Tooltip id={`balance-${commerce.id}`} message="Ver Balances">
              <EditIcon name="chart-bar" />
            </Tooltip>
          </Link>
          {/* <Link to={`commerces/${commerce.id}/edit`}>
            <Tooltip id={`edit-${commerce.id}`} message="Editar">
              <EditIcon name="pencil" />
            </Tooltip>
          </Link> */}
          {/* <Modal>
            {({ openModal }) => {
              return (
                <ConfirmAction
                  onClick={() =>
                    openModal('CONFIRM_DELETE', {
                      title: 'Confirmación',
                      description: 'Deseas eliminar el item seleccionado',
                    })
                  }
                >
                  <Tooltip id={`delete-${commerce.id}`} message="Eliminar">
                    <DeleteIcon name="trash-empty" />
                  </Tooltip>
                </ConfirmAction>
              )
            }}
          </Modal> */}
        </ActionContainer>
      ),
    },
  ]

  return (
    <Query
      variables={{
        options: {
          limit: pageSize,
          offset: page ? (page - 1) * pageSize : 0,
          sortBy: sort_by,
        },
        filters: {
          s,
        },
      }}
      query={GET_COMMERCES_QUERY}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult<Data, any>) => {
        if (loading) {
          return <p>Loading...</p>
        }
        if (error) {
          return <p>Error</p>
        }
        if (!data) {
          return <p>No Data</p>
        }
        if (!data.commerces) {
          return <p>No Commerces</p>
        }

        const { nodes, pagination } = data.commerces

        return (
          <Datatable
            indexKey="id"
            columns={columns}
            data={nodes}
            loading={loading}
            totalItemsCount={pagination.total}
            sortBy={sort_by}
            page={page || 1}
            pageSize={pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            searchText={s}
            onSort={onSortByChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSearch={onSearch}
            placeholderSearchBar={'Buscar por nombre'}
          />
        )
      }}
    </Query>
  )
})
