import React from 'react'
import { Link } from 'react-router-dom'

import PageWrapper from './../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import ProductTable from './components/ProductTable'

import { PageTopSection, CreateProductButton } from './style'

const ProductList = () => (
  <PageWrapper title="Productos">
    <PageTopSection>
      <Link to="/products/create">
        <CreateProductButton icon="plus" color="success">
          Crear Producto
        </CreateProductButton>
      </Link>
    </PageTopSection>
    <Paper>
      <ProductTable />
    </Paper>
  </PageWrapper>
)

export default ProductList
