import styled from 'styled-components'

type Props={
  color : string
  size : string
}
export const CustomIcon = styled.i<Props>`
  color: ${props => props.color};
  font-size: ${props => props.size};
`
