import React from 'react'
import { PageLink } from '../style'

interface PageProps {
  className?: string

  pageContent?: React.ReactNode
  active?: boolean
  disabled?: boolean

  cursor?: string
  onClick: (cursor: string) => void
}

class Page extends React.Component<PageProps> {
  onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    if (this.props.cursor) {
      this.props.onClick(this.props.cursor)
    }

    // if (this.props.disabled) {
    //   return
    // }
  }

  render() {
    const { className, pageContent, active, disabled } = this.props
    return (
      <PageLink
        className={className}
        active={active}
        disabled={disabled}
        href="#"
        onClick={this.onClick}
      >
        {pageContent}
      </PageLink>
    )
  }
}

export default Page
