import React from 'react'
import { PageLink } from '../style'

interface PageProps {
  className?: string
  pageNumber: number
  pageContent?: React.ReactNode
  active?: boolean
  disabled?: boolean
  first?: boolean
  last?: boolean
  onClick: (pageNumber: number) => void
}

class Page extends React.Component<PageProps> {
  onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    if (this.props.disabled) {
      return
    }

    this.props.onClick(this.props.pageNumber)
  }

  render() {
    const {
      className,
      pageContent,
      pageNumber,
      active,
      disabled,
      first,
      last,
    } = this.props
    return (
      <PageLink
        className={className}
        active={active}
        disabled={disabled}
        first={first}
        last={last}
        href="#"
        onClick={this.onClick}
      >
        {pageContent || pageNumber}
      </PageLink>
    )
  }
}

export default Page
