import styled from 'styled-components'

import { Button } from '../../../../../../components/Buttons'

import sizes from '../../../../../../styles/media'

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.16)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '1.2rem',
    zIndex: 10,
  },
  content: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: '20px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2)',
    padding: 0,
    border: 0,
    top: 'auto',
    bottom: 'auto',
    left: 'auto',
    right: 'auto',
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
  },
}

export const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #454545;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
  border-radius: 20px;
  padding: 0;
  margin-bottom: 10px;
  text-align: left;
  color: #858585;

  @media ${sizes.sm} {
    flex-direction: row;
  }
`
export const ConfirmContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;

  @media ${sizes.sm} {
    margin-left: 15%;
    margin-right: 15%;
  }
`
export const Feedback = styled.div`
  font-size: 12px;
  color: #18b0f7;
  margin-bottom: 15px;
`

export const TButton = styled(Button)`
  width: 80%;
`
export const FeedbackError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ff3b30;
`
export const Error = styled.small`
  font-size: 12px;
  color: #ff2d2d;
  height: 25px;
  display: block;
  margin: 5px 0 0 8px;
`

export const TableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #c5c5c5;
  border-radius: 20px;
  margin-bottom: 5%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
`

export const ColumnTitle = styled.span`
  padding-top: 5px;
`

export const ColumnDescription = styled.span`
  color: #858585;
  padding-bottom: 5%;
  padding-top: 2%;
`

export const CloseButton = styled.button`
  background-color: #ff2d2d;
  border-radius: 15px;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  padding: 2px 0;
  left: -8px;
  position: absolute;
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8%;
`
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px;
`
