import React from 'react'

import { Loader } from './style'

interface Props {
  children?: React.ReactNode
}

const Loading = ({ children }: Props) => <Loader>{children}</Loader>

export default Loading
