import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult, Mutation } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import qs from 'qs'

import Modal from '../../../../components/Modals/ModalRoot'
import { Tooltip } from '../../../../components/FormElements'
import { STATUS_OPTIONS, PAGE_SIZE_OPTIONS } from '../../../../config'
import DataTable from '../../../../components/DataTable'
import Loading from '../../../../components/Loader'
import { Select } from '../../../../components/FormElements'
import { parsePageParams } from '../../../../helpers/params'

import { ActionsContainer, EditIcon } from '../style'

import ThemeContext from '../../../../context/ThemeContext'

const GET_PRODUCTS_QUERY = gql`
  query getProductsQuery(
    $options: ProductsOptions!
    $filters: ProductsFilters!
  ) {
    products(options: $options, filters: $filters) {
      nodes {
        id
        slug
        name
        status
        supplier {
          id
          legalName
        }
      }
      pagination {
        total
      }
    }
  }
`

const EDIT_PRODUCT_MUTATION = gql`
  mutation editProductMutation($input: EditProductInput!) {
    editProduct(input: $input)
  }
`

type ProductNode = Record<string, any>

interface Data {
  products: {
    nodes: ProductNode[]
    pagination: {
      total: number
    }
  }
}

const ProductTable = ({ history, location }: RouteComponentProps) => {
  const { forceShowNotification } = useContext(ThemeContext)

  function updatePageParams(params: object) {
    history.push({
      pathname: history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  function extractPageParams() {
    const { page, page_size, s, sort_by } = parsePageParams(
      location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
    }
  }

  function onPageChange(page: number) {
    const params = extractPageParams()

    params.page = page

    updatePageParams(params)
  }

  function onPageSizeChange(pageSize: number) {
    const params = extractPageParams()

    params.page_size = pageSize

    updatePageParams(params)
  }

  function onSearch(searchText: string | undefined) {
    const params = extractPageParams()

    params.s = searchText
    params.page = 1
    updatePageParams(params)
  }

  function onSortByChange(sortBy: string | undefined) {
    const params = extractPageParams()

    params.sort_by = sortBy

    updatePageParams(params)
  }

  const { page, page_size, s, sort_by } = extractPageParams()

  const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

  let flag = false

  return (
    <Query
      variables={{
        options: {
          limit: pageSize,
          offset: page ? (page - 1) * pageSize : 0,
          sortBy: sort_by,
        },
        filters: {
          s,
        },
      }}
      query={GET_PRODUCTS_QUERY}
      fetchPolicy="network-only"
    >
      {({ loading, error, data, refetch }: QueryResult<Data, any>) => {
        if (loading) {
          return <p>Loading...</p>
        }
        if (error) {
          return <p>Error</p>
        }
        if (!data) {
          return <p>No Data</p>
        }

        const { nodes, pagination } = data.products

        const columns = [
          { header: 'Slug', key: 'slug' },
          { header: 'Nombre del producto', key: 'name' },
          {
            header: 'Estado',
            key: 'status',
            width: 210,
            Cell: (product: ProductNode) => {
              const status = { value: product.status, label: '' }

              if (product.status === 'active') {
                status.label = 'Activo'
              } else if (product.status === 'inactive') {
                status.label = 'Inactivo'
              } else {
                status.label = 'Descontinuado'
              }

              return (
                <Modal>
                  {({ openModal }) => {
                    return (
                      <Mutation
                        mutation={EDIT_PRODUCT_MUTATION}
                        onCompleted={() => {
                          refetch().finally(() => {
                            flag = true
                            forceShowNotification &&
                              forceShowNotification({
                                type: 'ok',
                                message: 'Producto actualizado correctamente',
                              })
                          })
                        }}
                        onError={() => {
                          openModal('ALERT', {
                            header: {
                              title: 'ALERTA',
                            },
                            description:
                              'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                            type: 'fail',
                          })
                        }}
                      >
                        {(editStatusProduct, { loading: loadingEdit }) => (
                          <div>
                            {loadingEdit || loading || flag ? (
                              <Loading />
                            ) : (
                              <Select
                                id="status"
                                options={STATUS_OPTIONS}
                                value={status}
                                onChange={s => {
                                  if (s && s['value'] != status.value) {
                                    flag = true

                                    editStatusProduct({
                                      variables: {
                                        input: {
                                          status: s && s['value'],
                                          id: product.id,
                                        },
                                      },
                                    }).then(() => {
                                      flag = false
                                    })
                                  }
                                }}
                              />
                            )}
                          </div>
                        )}
                      </Mutation>
                    )
                  }}
                </Modal>
              )
            },
          },
          {
            header: 'Proveedor',
            key: 'supplier',
            sortable: false,
            Cell: (product: ProductNode) => {
              return product.supplier && product.supplier.legalName
            },
          },
          {
            header: 'Acciones',
            key: 'acciones',
            width: 99,
            sortable: false,
            Cell: (product: ProductNode) => (
              <ActionsContainer>
                <Link to={`/products/${product.id}/edit`}>
                  <Tooltip id={`edit-${product.id}`} message="Editar">
                    <EditIcon name="pencil" />
                  </Tooltip>
                </Link>
              </ActionsContainer>
            ),
          },
        ]

        return (
          <DataTable
            indexKey="id"
            columns={columns}
            data={nodes}
            loading={loading}
            totalItemsCount={pagination.total}
            sortBy={sort_by}
            page={page || 1}
            pageSize={pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            searchText={s}
            onSort={onSortByChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSearch={onSearch}
            placeholderSearchBar={'Buscar por marca, nombre'}
          />
        )
      }}
    </Query>
  )
}

export default withRouter(ProductTable)
