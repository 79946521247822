import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import { parsePageParams } from '../../../../../helpers/params'
import { PAGE_SIZE_OPTIONS } from '../../../../../config'
import DataTable from '../../../../../components/DataTable'
import { parseCursorPageParams } from '../../../../../helpers/params'
import { Tooltip } from '../../../../../components/FormElements'
import { EyeIcon, ViewAction, DetailTitle, DetailDescription } from '../style'
import Modal from '../../../../../components/Modals/ModalRoot'
import { ActionContainer, EditIcon } from '../../../../../components/Globals'
import ThemeContext from '../../../../../context/ThemeContext'
import { AccreditationViewModal } from './AccreditationActionsModel/AccreditationView'

const GET_TRANSACTION_QUERY = gql`
  query getTransactionsQuery(
    $options: TransactionsOptions!
    $filters: TransactionsFilters!
  ) {
    transactions(options: $options, filters: $filters) {
      nodes {
        id
        amount
        pointCost
        status
        number
        transactionType {
          id
          name
          slug
          description
          type
        }
        accreditation {
          promo {
            id
            name
            psCode
            promoType {
              id
              name
            }
          }
        }
        member {
          id
          identificationNumber
          firstName
          secondName
          firstLastName
          secondLastName
          birthday
          phone
        }
        createdAt
        updatedAt
      }
      pagination {
        limit
        cursors {
          before
          after
        }
      }
    }
  }
`

type TransactionNode = Record<string, any>

interface Data {
  transactions: {
    nodes: TransactionNode[]
    pagination: {
      limit: number
      cursors: {
        before: string
        after: string
      }
    }
  }
}
type FilterOptionType = Record<string, any>

interface transactionTableState {
  filtersOpened: boolean
  filterSelected: FilterOptionType | null
  supplierFromUrl: string
}
const statusOptions: FilterOptionType[] = []
statusOptions.push(
  { label: 'Todos los estados', value: 'all' },
  { label: 'Aprobado', value: 'approved' },
  { label: 'Ingresado', value: 'pending' },
  { label: 'Reversado', value: 'reversed' },
  { label: 'Cancelado', value: 'canceled' }
)

const typosApprovalOptions: FilterOptionType[] = []
typosApprovalOptions.push(
  { label: 'Todos los tipos', value: 'all' },
  { label: 'Promoción', value: 'Promoción' },
  { label: 'Consumo', value: 'Consumo' }
)

let filters = [
  {
    key: 'type',
    label: 'Tipo de aprobación',
    options: typosApprovalOptions,
    selected: {},
  },
  {
    key: 'status',
    label: 'Estados',
    options: statusOptions,
    selected: {},
  },
]

class transactionTable extends React.Component<
  RouteComponentProps<{}>,
  transactionTableState
> {
  state: transactionTableState = {
    filtersOpened: false,
    filterSelected: null,
    supplierFromUrl: '',
  }

  componentWillMount() {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
    })
  }

  toggleFilterSection = () => {
    const { filtersOpened } = this.state
    this.setState({ filtersOpened: !filtersOpened })
  }

  extractPageParams = () => {
    const {
      page_size,
      sort_by,
      after,
      before,
      status,
      type,
      s,
    } = parseCursorPageParams(this.props.location.search.slice(1))
    return {
      page_size,
      sort_by,
      after,
      before,
      status,
      type,
      s,
    }
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText

    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  onAfter = (after: string | undefined) => {
    const params = this.extractPageParams()
    params.after = after

    if (params.before) {
      delete params.before
    }

    this.updatePageParams(params)
  }

  onBefore = (before: string | undefined) => {
    const params = this.extractPageParams()
    params.before = before

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onFetch = (formatParams: any) => {
    const params = this.extractPageParams()

    const { filters } = formatParams

    if (filters && filters.status) {
      params.status = filters.status.value
    }

    if (filters && filters.type) {
      params.type = filters.type.value
    }

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onFilterSelected = (
    index: number,
    selected: FilterOptionType | null | undefined
  ) => {
    this.setState({ filterSelected: { index, selected } })
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  componentDidMount() {
    const { status } = parsePageParams(this.props.location.search.slice(1))
    const { type } = parsePageParams(this.props.location.search.slice(1))
    if (type) {
      this.setState({ filtersOpened: true, supplierFromUrl: type })
    }
    if (status) {
      this.setState({ filtersOpened: true, supplierFromUrl: status })
    }
  }

  render() {
    const { filtersOpened, filterSelected, supplierFromUrl } = this.state

    const {
      page_size,
      after,
      before,
      status,
      type,
      s,
    } = this.extractPageParams()

    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

    return (
      <ThemeContext.Consumer>
        {context => (
          <Query
            variables={{
              options: {
                limit: pageSize,
                after,
                before,
              },
              filters: { status, type, s },
            }}
            query={GET_TRANSACTION_QUERY}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, refetch }: QueryResult<Data, any>) => {
              if (loading) {
                return <p>Loading...</p>
              }
              if (error) {
                return <p>Error</p>
              }
              if (!data) {
                return <p>No Data</p>
              }

              const { nodes, pagination } = data.transactions

              const statusOptions: FilterOptionType[] = []
              statusOptions.push(
                { label: 'Todos los estados', value: 'all' },
                { label: 'Aprobado', value: 'approved' },
                { label: 'Ingresado', value: 'pending' },
                { label: 'Reversado', value: 'reversed' },
                { label: 'Cancelado', value: 'canceled' }
              )

              const typosApprovalOptions: FilterOptionType[] = []
              typosApprovalOptions.push(
                { label: 'Todos los tipos', value: 'all' },
                { label: 'Promoción', value: 'Promoción' },
                { label: 'Consumo', value: 'Consumo' }
              )

              const columns = [
                {
                  header: 'ID',
                  key: 'number',
                  sortable: false,
                },
                {
                  header: 'Tipo',
                  key: 'type',
                  sortable: false,
                  Cell: ({ accreditation }: TransactionNode) => {
                    return accreditation.promo.promoType.name
                  },
                },
                {
                  header: 'Detalle',
                  key: 'detail',
                  sortable: false,
                  Cell: ({ accreditation }: TransactionNode) => {
                    return (
                      <>
                        {' '}
                        <DetailTitle>
                          {' '}
                          Código de la promoción:{' '}
                        </DetailTitle>{' '}
                        <br />
                        <DetailDescription>
                          {' '}
                          {accreditation.promo.psCode}{' '}
                        </DetailDescription>{' '}
                        <br />
                        <DetailTitle>
                          {' '}
                          Nombre de la promoción:{' '}
                        </DetailTitle>{' '}
                        <br />
                        <DetailDescription>
                          {' '}
                          {accreditation.promo.name}{' '}
                        </DetailDescription>
                      </>
                    )
                  },
                },
                {
                  header: 'Solicitado por',
                  key: 'requestedBy',
                  sortable: false,
                  Cell: ({ member }: TransactionNode) => {
                    return member.firstName + ' ' + member.firstLastName
                  },
                },
                {
                  header: 'Gestionado por',
                  key: 'managedBy',
                  sortable: false,
                },
                {
                  header: 'Estado',
                  key: 'status',
                  sortable: false,
                  Cell: ({ status }: TransactionNode) => {
                    let statusStr = ''
                    statusOptions.filter(s => {
                      if (s.value === status) {
                        statusStr = s.label
                      }
                      return s.value === status
                    })
                    return statusStr
                  },
                },
                {
                  header: 'Fecha de creación',
                  key: 'createdAt',
                  sortable: false,
                  Cell: (transaction: TransactionNode) => {
                    const d = new Date(transaction.createdAt)
                    const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
                    const mm =
                      (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
                    const yyyy = d.getFullYear()
                    const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
                    const min =
                      (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
                    const sec =
                      (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
                    const date =
                      yyyy +
                      '-' +
                      mm +
                      '-' +
                      dd +
                      ' ' +
                      hour +
                      ':' +
                      min +
                      ':' +
                      sec
                    return date
                  },
                },
                {
                  header: 'Acciones',
                  key: 'actions',
                  width: 99,
                  sortable: false,
                  Cell: (transaction: TransactionNode) => (
                    <Modal>
                      {({ openModal, closeModal }) => {
                        return (
                          <ActionContainer>
                            {transaction.status !== 'pending' ? (
                              <ViewAction
                                onClick={() =>
                                  openModal('DETAILS', {
                                    header: {},
                                    description: (
                                      <AccreditationViewModal
                                        transaction={transaction}
                                        refetch={refetch}
                                      />
                                    ),
                                    modalSize: 'md',
                                  })
                                }
                              >
                                <Tooltip
                                  id={`show-${transaction.id}-transactions`}
                                  message="Detalle del movimiento"
                                >
                                  <EyeIcon name="eye" />
                                </Tooltip>
                              </ViewAction>
                            ) : (
                              <ViewAction
                                onClick={() =>
                                  openModal('FORM', {
                                    header: {},
                                    description: (
                                      <AccreditationViewModal
                                        transaction={transaction}
                                        refetch={refetch}
                                        closeModal={closeModal}
                                      />
                                    ),
                                    modalSize: 'md',
                                  })
                                }
                              >
                                <Tooltip
                                  id={`edit-${transaction.id}`}
                                  message="Editar movimiento"
                                >
                                  <EditIcon name="pencil" />
                                </Tooltip>
                              </ViewAction>
                            )}
                          </ActionContainer>
                        )
                      }}
                    </Modal>
                  ),
                },
              ]

              return (
                <React.Fragment>
                  <DataTable
                    indexKey="number"
                    columns={columns}
                    data={nodes}
                    loading={loading}
                    sortBy={''}
                    pageSize={pageSize}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    onSort={this.onSortByChange}
                    onPageSizeChange={this.onPageSizeChange}
                    onAfter={this.onAfter}
                    onBefore={this.onBefore}
                    after={pagination.cursors.after}
                    before={pagination.cursors.before}
                    filters={filters}
                    onFetch={this.onFetch}
                    searchText={s}
                    onSearch={this.onSearch}
                    placeholderSearchBar={'Buscar usuario por cédula'}
                    filtersOpened={filtersOpened}
                    toggleFilterSection={this.toggleFilterSection}
                    filterSelected={filterSelected}
                    onFilterSelected={this.onFilterSelected}
                    supplierFromUrl={supplierFromUrl}
                  />
                </React.Fragment>
              )
            }}
          </Query>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default withRouter(transactionTable)
