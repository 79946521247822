import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs'

import { Mutation } from 'react-apollo'

import { PAGE_SIZE_OPTIONS } from '../../../../config'
import DataTable from '../../../../components/DataTable'
import { parsePageParams } from '../../../../helpers/params'
import { CheckBox } from '../../../../components/FormElements'

import * as Grid from '../../../../components/Grid'
import { Button } from '../../../../components/Buttons'

import { GenerateOrders, PageTopSection } from '../style'
import Paper from '../../../../components/Paper'

import ThemeContext from '../../../../context/ThemeContext'

const GET_PURCHASE_SUPPLIERS_QUERY = gql`
  query getPurchaseSuppliersQuery(
    $options: PurchaseSuppliersOptions!
    $filters: PurchaseSuppliersFilters!
  ) {
    purchaseSuppliers(options: $options, filters: $filters) {
      nodes {
        supplier {
          id
          legalName
        }
        purchasesCount
      }
      pagination {
        total
      }
    }
  }
`

export const CREATE_PURCHASE_ORDER_MUTATION = gql`
  mutation createPurchaseOrderMutation($input: CreatePurchaseOrderInput!) {
    createPurchaseOrder(input: $input) {
      id
      supplier {
        id
        legalName
        businessName
      }
      documentUrl
      createdAt
      updatedAt
    }
  }
`

type PurchaseNode = Record<string, any>

interface SupplierNode {
  id: string
  legalName: string
}

interface PurchaseSupplier {
  id: string
  supplier: SupplierNode
  purchasesCount: number
}

interface Data {
  purchaseSuppliers: {
    nodes: PurchaseSupplier[]
    pagination: {
      total: number
    }
  }
}

type FilterOptionType = Record<string, any>

interface ProcessOrderTableState {
  filtersOpened: boolean
  filterSelected: FilterOptionType | null
  supplierFromUrl: string
  checkAll: boolean
  checked: boolean[]
}

class ProcessOrderTable extends React.Component<
  RouteComponentProps<{}>,
  ProcessOrderTableState
> {
  state: ProcessOrderTableState = {
    filtersOpened: false,
    filterSelected: null,
    supplierFromUrl: '',
    checkAll: false,
    checked: [],
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  extractPageParams = () => {
    const { page = 1, page_size, s, sort_by } = parsePageParams(
      window.location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
    }
  }

  onPageChange = (page: number) => {
    const params = this.extractPageParams()

    params.page = page

    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    this.updatePageParams(params)
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText
    params.page = 1
    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  onCheckboxAll = (suppliers: any) => {
    const { checkAll } = this.state

    let { checked } = this.state

    suppliers.forEach(({ supplier }: any) => {
      checked[supplier.id] = !checkAll
    })

    this.setState({ checkAll: !checkAll, checked })
  }

  handleCheck = (index: any) => {
    let { checked } = this.state
    checked[index] = !this.state.checked[index]
    this.setState({ checked })
  }

  render() {
    const { checkAll, checked } = this.state

    const { page_size, sort_by, s, page } = this.extractPageParams()

    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

    return (
      <Query
        variables={{
          options: {
            limit: pageSize,
            offset: page ? (page - 1) * pageSize : 0,
            sortBy: sort_by,
          },
          filters: {
            s,
            processed: 'false',
          },
        }}
        query={GET_PURCHASE_SUPPLIERS_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }: QueryResult<Data, any>) => {
          if (loading) {
            return <p>Loading...</p>
          }
          if (error) {
            return <p>Error</p>
          }
          if (!data) {
            return <p>No Data</p>
          }
          if (!data.purchaseSuppliers) {
            return <p>No Suppliers</p>
          }

          const { nodes } = data.purchaseSuppliers
          const purchaseSuppliers = nodes.map(purchaseSupplier => {
            purchaseSupplier.id = purchaseSupplier.supplier.id
            return purchaseSupplier
          })

          const columns = [
            {
              header: (
                <CheckBox
                  id="allSupplier"
                  checked={checkAll}
                  onChange={() => {
                    this.onCheckboxAll(purchaseSuppliers)
                  }}
                />
              ),
              width: 10,
              key: 'id',
              sortable: false,
              Cell: (purchase: PurchaseNode) => {
                return (
                  <CheckBox
                    id="eachSupplier"
                    checked={checked[purchase.supplier.id] || checkAll}
                    onChange={() => {
                      this.handleCheck(purchase.supplier.id)
                    }}
                  />
                )
              },
            },
            {
              header: 'Proveedor',
              key: 'supplierName',
              sortable: true,
              Cell: (purchase: PurchaseNode) => {
                return purchase.supplier.legalName
              },
            },
            {
              header: 'Número de compras',
              width: 200,
              key: 'purchasesCount',
              sortable: false,
              Cell: (purchase: PurchaseNode) => {
                return (
                  <Link to={'/pending-orders?supplier=' + purchase.supplier.id}>
                    {purchase.purchasesCount} compras
                  </Link>
                )
              },
            },
          ]

          return (
            <Mutation
              mutation={CREATE_PURCHASE_ORDER_MUTATION}
              onCompleted={() => {
                refetch()
              }}
            >
              {(createPurchaseOrder, { loading, called, error }) => (
                <React.Fragment>
                  <ThemeContext.Consumer>
                    {({ forceShowNotification }) => (
                      <>
                        <Paper>
                          <DataTable
                            indexKey="id"
                            columns={columns}
                            data={nodes}
                            loading={loading}
                            sortBy={sort_by}
                            pageSize={pageSize}
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            searchText={s}
                            onSort={this.onSortByChange}
                            onPageChange={this.onPageChange}
                            onPageSizeChange={this.onPageSizeChange}
                            onSearch={this.onSearch}
                            placeholderSearchBar={'Buscar por proveedor'}
                          />
                        </Paper>
                        <PageTopSection>
                          <Grid.Row>
                            <Grid.Column md={12}>
                              <GenerateOrders>
                                <Button
                                  color="primary"
                                  disabled={loading ? true : false}
                                  onClick={() => {
                                    try {
                                      purchaseSuppliers.forEach(
                                        async purchaseSupplier => {
                                          let errActived = false
                                          if (checked[purchaseSupplier.id]) {
                                            await createPurchaseOrder({
                                              variables: {
                                                input: {
                                                  supplierId:
                                                    purchaseSupplier.supplier
                                                      .id,
                                                },
                                              },
                                            })
                                              .catch(() => {
                                                errActived = true
                                                forceShowNotification &&
                                                  forceShowNotification({
                                                    type: 'fail',
                                                    message:
                                                      'Error al procesar orden de ' +
                                                      purchaseSupplier.supplier
                                                        .legalName +
                                                      '.',
                                                  })
                                              })
                                              .finally(() => {
                                                !errActived &&
                                                  forceShowNotification &&
                                                  forceShowNotification({
                                                    type: 'ok',
                                                    message:
                                                      'La orden de ' +
                                                      purchaseSupplier.supplier
                                                        .legalName +
                                                      ' procesada correctamente.',
                                                  })
                                              })
                                          }
                                        }
                                      )
                                    } catch (error) {
                                      forceShowNotification &&
                                        forceShowNotification({
                                          type: 'fail',
                                          message:
                                            'No se procesaron las ordenes',
                                        })
                                      // // tslint:disable-next-line:no-console
                                      // console.log(
                                      //   'this provider does not have assigned purchase orders',
                                      //   error
                                      // )
                                      return
                                    }
                                  }}
                                >
                                  {loading
                                    ? 'Procesando órdenes...'
                                    : 'Procesar órdenes'}
                                </Button>
                              </GenerateOrders>
                            </Grid.Column>
                          </Grid.Row>
                        </PageTopSection>
                      </>
                    )}
                  </ThemeContext.Consumer>
                </React.Fragment>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(ProcessOrderTable)
