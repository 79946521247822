import React from 'react'
import { Link } from 'react-router-dom'

import PageWrapper from './../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import SupplierTable from './components/SupplierTable'

import { PageTopSection, CreateSupplierButton } from './style'

const SupplierList = () => (
  <PageWrapper title="Proveedores">
    <PageTopSection>
      <Link to="/suppliers/create">
        <CreateSupplierButton icon="plus" color="success">
          Crear Proveedor
        </CreateSupplierButton>
      </Link>
    </PageTopSection>
    <Paper>
      <SupplierTable />
    </Paper>
  </PageWrapper>
)

export default SupplierList
