import React from 'react'

import { Container } from './style'

interface Props {
  children?: React.ReactNode
}

const Paper = ({ children }: Props) => <Container>{children}</Container>

export default Paper
