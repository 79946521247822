import styled from 'styled-components'

import { Button } from '../../../../../components/Buttons'

import sizes from '../../../../../styles/media'

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.16)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '1.2rem',
    zIndex: 10,
  },
  content: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: '20px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2)',
    padding: 0,
    border: 0,
    top: 'auto',
    bottom: 'auto',
    left: 'auto',
    right: 'auto',
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
  },
}

export const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #454545;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
  border-radius: 20px;
  padding: 0;
  margin-bottom: 10px;
  text-align: left;
  color: #858585;

  @media ${sizes.sm} {
    flex-direction: row;
  }
`
export const ConfirmContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;

  @media ${sizes.sm} {
    margin-left: 5%;
    margin-right: 5%;
  }
`
export const Feedback = styled.div`
  font-size: 12px;
  color: #18b0f7;
  margin-bottom: 15px;
`

export const ForwardButton = styled(Button)`
  width: 100%;
`
export const FeedbackError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ff3b30;
`
export const Error = styled.small`
  font-size: 12px;
  color: #ff2d2d;
  height: 25px;
  display: block;
  margin: 5px 0 0 8px;
`
