import React from 'react'
import { Select } from '../../../../../../components/FormElements'
import { TButton } from './style'

import {
  Title,
  DescriptionContainer,
  TableContainer,
  Container,
  ColumnDescription,
  ColumnTitle,
  ContentContainer,
  ButtonsContainer,
} from './style'

interface Props {
  transaction: any
  refetch: any
  closeModal?: () => any
}

export class AccreditationViewModal extends React.Component<Props> {
  render() {
    const { transaction, closeModal } = this.props
    const statusOptions = [
      { label: 'Aprobado', value: 'approved' },
      { label: 'Ingresado', value: 'pending' },
      { label: 'Reversado', value: 'reversed' },
      { label: 'Cancelado', value: 'canceled' },
    ]

    const d = new Date(transaction.updatedAt)
    const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
    const mm = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
    const yyyy = d.getFullYear()
    const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
    const min = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
    const sec = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
    const date = yyyy + '-' + mm + '-' + dd + ' ' + hour + ':' + min + ':' + sec

    transaction.updatedAt = date

    return (
      <>
        <Title>Detalle de la acreditación</Title>
        <DescriptionContainer />
        <TableContainer>
          <Container>
            <ColumnTitle>Nombre del socio</ColumnTitle>
            <ColumnDescription>
              {transaction.member.firstName +
                ' ' +
                transaction.member.firstLastName}
            </ColumnDescription>
            <ColumnTitle>Cantidad (millas)</ColumnTitle>
            <ColumnDescription>{transaction.amount}</ColumnDescription>
            <ColumnTitle>Usuario solicitante</ColumnTitle>
            <ColumnDescription>{}</ColumnDescription>
          </Container>
          <Container>
            <ColumnTitle>Identificación del socio</ColumnTitle>
            <ColumnDescription>
              {transaction.member.identificationNumber}
            </ColumnDescription>
            <ColumnTitle>Promoción</ColumnTitle>
            <ColumnDescription>
              {transaction.accreditation.promo.name}
            </ColumnDescription>
          </Container>
        </TableContainer>
        {transaction.status !== 'pending' ? (
          <TableContainer>
            <Container>
              <ColumnTitle>Aprobada por</ColumnTitle>
              <ColumnDescription>{}</ColumnDescription>
            </Container>
            <Container>
              <ColumnTitle>Aprobada el</ColumnTitle>
              <ColumnDescription>{transaction.updatedAt}</ColumnDescription>
            </Container>
          </TableContainer>
        ) : (
          <>
            <Title>Tomar una acción</Title>
            <DescriptionContainer />
            <ContentContainer>
              <Select
                id="status"
                options={statusOptions}
                placeholder="Seleccione"
              />
            </ContentContainer>

            <ContentContainer>
              <ButtonsContainer>
                <TButton color="primary">Guardar</TButton>
              </ButtonsContainer>

              <ButtonsContainer>
                <TButton color="secondary" onClick={closeModal}>
                  Cancelar
                </TButton>
              </ButtonsContainer>
            </ContentContainer>
          </>
        )}
      </>
    )
  }
}
