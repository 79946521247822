import React from 'react'
import { Helmet } from 'react-helmet'

import Sidebar from './Sidebar'
import { IconButton } from '../Buttons'

import { Container, MainContent, PageTitle, TopSection, Content } from './style'

interface Props {
  children?: React.ReactNode
  title?: string
}

interface State {
  isMenuCollapsed: boolean
}

class PageWrapper extends React.Component<Props, State> {
  state = {
    isMenuCollapsed: window.innerWidth <= 991,
  }

  windowInnerWidth: number = window.innerWidth

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    if (this.windowInnerWidth > window.innerWidth && window.innerWidth < 991) {
      this.setState({ isMenuCollapsed: true })
    }
    this.windowInnerWidth = window.innerWidth
  }

  toggleMenu = () => {
    this.setState(state => ({ isMenuCollapsed: !state.isMenuCollapsed }))
  }

  render() {
    const { children, title } = this.props
    return (
      <Container>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Sidebar
          collapsed={this.state.isMenuCollapsed}
          toggleMenu={this.toggleMenu}
        />
        <MainContent expanded={!this.state.isMenuCollapsed}>
          <TopSection>
            {this.state.isMenuCollapsed ? (
              <IconButton
                onClick={this.toggleMenu}
                color="primary"
                icon="cancel"
              />
            ) : (
              <IconButton
                onClick={this.toggleMenu}
                color="primary"
                icon="menu"
              />
            )}
            <PageTitle>{title}</PageTitle>
          </TopSection>
          <Content>{children}</Content>
        </MainContent>
      </Container>
    )
  }
}

export default PageWrapper
