import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult, Mutation } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import qs from 'qs'

import { Tooltip } from '../../../../components/FormElements'

import { PAGE_SIZE_OPTIONS } from '../../../../config'
import DataTable from '../../../../components/DataTable'
import { parsePageParams } from '../../../../helpers/params'

import ThemeContext from '../../../../context/ThemeContext'
import Loading from '../../../../components/Loader'

import { ActionsContainer, ViewAction, ViewIcon, EditIcon } from '../style'
import Modal from '../../../../components/Modals/ModalRoot'
import PqrsStatusSelector from './PqrsActionsModal/PqrsStatusSelector'
import { PqrsViewModal } from './PqrsActionsModal/PqrsView'
import { PqrsEditModal } from './PqrsActionsModal/PqrsEdit'

const GET_PQRS_QUERY = gql`
  query getPqrsQuery($options: PqrsOptions!, $filters: PqrsFilters!) {
    pqrs(options: $options, filters: $filters) {
      nodes {
        id
        number
        firstName
        lastName
        businessName
        identificationType
        identificationNumber
        email
        pqrsType {
          id
          label
        }
        phone
        address
        status {
          id
          name
        }
        feedback
        description
        createdAt
      }
      pagination {
        total
      }
    }
  }
`

const GET_PQRS_STATUS_QUERY = gql`
  query getPqrsStatusQuery($filters: PqrsFilters!) {
    pqrsStatus(filters: $filters) {
      nodes {
        id
        name
      }
      pagination {
        total
      }
    }
  }
`

export const UPDATE_PQRS_MUTATION = gql`
  mutation editPqrsMutation($input: EditPqrsInput!) {
    editPqrs(input: $input)
  }
`

export const EXPORT_PQRS_MUTATION = gql`
  mutation exportPqrsMutation($input: String) {
    exportPqrs(input: $input)
  }
`

type PqrsNode = Record<string, any>

interface Pqrs {
  id: string
  firstName: string
  lastName: string
  businessName: string
  identificationType: string
  identificationNumber: string
  email: string
  pqrsType: string
  phone: string
  address: string
}

interface Data {
  pqrs: {
    nodes: Pqrs[]
    pagination: {
      total: number
    }
  }
}

type PqrsStatus = { [key: string]: any }
interface DataPqrsStatus {
  pqrsStatus: {
    nodes: PqrsStatus[]
  }
}

type FilterOptionType = Record<string, any>

interface PqrsTableState {
  filtersOpened: boolean
  filterSelected: FilterOptionType | null
  pqrsFromUrl: string
}

class PqrsTable extends React.Component<
  RouteComponentProps<{}>,
  PqrsTableState
> {
  state: PqrsTableState = {
    filtersOpened: false,
    filterSelected: null,
    pqrsFromUrl: '',
  }

  componentDidMount() {
    const { status } = parsePageParams(this.props.location.search.slice(1))
    if (status) {
      this.setState({ filtersOpened: true, pqrsFromUrl: status })
    }
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  toggleFilterSection = () => {
    const { filtersOpened } = this.state
    this.setState({ filtersOpened: !filtersOpened })
  }

  extractPageParams = () => {
    const { page = 1, page_size, s, sort_by, status } = parsePageParams(
      location.search.slice(1)
    )

    return {
      page,
      page_size,
      s,
      sort_by,
      status,
    }
  }

  onPageChange = (page: number) => {
    const params = this.extractPageParams()

    params.page = page

    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    this.updatePageParams(params)
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText
    params.page = 1
    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  onFetch = (formatParams: any) => {
    const params = this.extractPageParams()

    const { filters } = formatParams

    if (filters && filters.status) {
      params.status = filters.status.value
    }

    if (params.page) {
      delete params.page
    }

    if (params.s) {
      delete params.s
    }

    this.updatePageParams(params)
  }

  onFilterSelected = (
    index: number,
    selected: FilterOptionType | null | undefined
  ) => {
    this.setState({ filterSelected: { index, selected } })
  }

  render() {
    const { filtersOpened, filterSelected, pqrsFromUrl } = this.state

    const { page_size, sort_by, s, page, status } = this.extractPageParams()

    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

    return (
      <ThemeContext.Consumer>
        {({ forceShowNotification }) => (
          <>
            <Query
              query={GET_PQRS_STATUS_QUERY}
              fetchPolicy="network-only"
              variables={{ filters: {} }}
            >
              {({
                loading: loadingStatus,
                error: errorStatus,
                data: dataStatus,
                client: clientStatus,
              }: QueryResult<DataPqrsStatus, any>) => {
                if (loadingStatus) {
                  return <p>Loading...</p>
                }
                if (errorStatus) {
                  return <p>Error</p>
                }
                if (!dataStatus) {
                  return <p>No Data</p>
                }
                if (!dataStatus.pqrsStatus) {
                  return <p>No Status</p>
                }

                const statusOptions: FilterOptionType[] = []
                statusOptions.push({
                  label: 'Todos los estados',
                  value: 'all',
                })

                statusOptions.push(
                  ...dataStatus.pqrsStatus.nodes.map(pqrs => ({
                    label: pqrs.name,
                    value: pqrs.id,
                  }))
                )

                return (
                  <Query
                    variables={{
                      options: {
                        limit: pageSize,
                        offset: page ? (page - 1) * pageSize : 0,
                        sortBy: sort_by,
                      },
                      filters: {
                        s,
                        status,
                      },
                    }}
                    query={GET_PQRS_QUERY}
                    fetchPolicy="network-only"
                  >
                    {({
                      loading,
                      error,
                      data,
                      refetch,
                    }: QueryResult<Data, any>) => {
                      if (loading) {
                        return <p>Loading...</p>
                      }
                      if (error) {
                        return <p>Error</p>
                      }
                      if (!data) {
                        return <p>No Data</p>
                      }
                      if (!data.pqrs) {
                        return <p>No Status</p>
                      }

                      const { nodes, pagination } = data.pqrs

                      const columns = [
                        {
                          header: '#',
                          key: 'number',
                          width: 50,
                          sortable: false,
                          Cell: (pqrs: PqrsNode) => {
                            return pqrs.number
                          },
                        },
                        {
                          header: 'Cliente',
                          key: 'client',
                          width: 300,
                          sortable: false,
                          Cell: (pqrs: PqrsNode) => {
                            return (
                              pqrs.businessName +
                              pqrs.firstName +
                              ' ' +
                              pqrs.lastName
                            )
                          },
                        },
                        {
                          header: 'Tipo de Identificación',
                          width: 100,
                          key: 'identificationType',
                          sortable: false,
                        },
                        {
                          header: 'Identificación',
                          width: 100,
                          key: 'identificationNumber',
                          sortable: false,
                        },
                        {
                          header: 'Tipo',
                          key: 'pqrsType',
                          width: 500,
                          sortable: false,
                          Cell: (pqrs: PqrsNode) => {
                            return pqrs.pqrsType.label
                          },
                        },
                        {
                          header: 'Estado',
                          key: 'status',
                          width: 200,
                          sortable: false,
                          Cell: (pqrs: PqrsNode) => {
                            const status = { value: pqrs.status, label: '' }

                            if (pqrs.status === 'active') {
                              status.label = 'Activo'
                            } else if (pqrs.status === 'inactive') {
                              status.label = 'Inactivo'
                            } else {
                              status.label = 'Descontinuado'
                            }

                            return (
                              <Modal>
                                {({ openModal }) => {
                                  return (
                                    <Mutation
                                      mutation={UPDATE_PQRS_MUTATION}
                                      onCompleted={() => {
                                        refetch().finally(() => {
                                          forceShowNotification &&
                                            forceShowNotification({
                                              type: 'ok',
                                              message:
                                                'PQRS actualizado correctamente',
                                            })
                                        })
                                      }}
                                      onError={() => {
                                        openModal('ALERT', {
                                          header: {
                                            title: 'ALERTA',
                                          },
                                          description:
                                            'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                          type: 'fail',
                                        })
                                      }}
                                    >
                                      {(
                                        updatePqrs,
                                        { loading: loadingEdit }
                                      ) => (
                                        <div>
                                          {loadingEdit || loading ? (
                                            <Loading />
                                          ) : (
                                            <PqrsStatusSelector
                                              value={status.value}
                                              onChange={s => {
                                                if (
                                                  s &&
                                                  s['id'] != status.value.id
                                                ) {
                                                  updatePqrs({
                                                    variables: {
                                                      input: {
                                                        id: `${pqrs.id}`,
                                                        status: s && s['id'],
                                                        feedback: 'test andrei',
                                                      },
                                                    },
                                                  })
                                                }
                                              }}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </Mutation>
                                  )
                                }}
                              </Modal>
                            )
                          },
                        },
                        {
                          header: 'Fecha',
                          key: 'createdAt',
                          width: 200,
                          sortable: false,
                          Cell: (pqrs: PqrsNode) => {
                            const d = new Date(pqrs.createdAt)
                            const dd =
                              (d.getDate() < 10 ? '0' : '') + d.getDate()
                            const mm =
                              (d.getMonth() + 1 < 10 ? '0' : '') +
                              (d.getMonth() + 1) // January is 0!
                            const yyyy = d.getFullYear()
                            const hour =
                              (d.getHours() < 10 ? '0' : '') + d.getHours()
                            const min =
                              (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
                            const sec =
                              (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
                            const date =
                              yyyy +
                              '-' +
                              mm +
                              '-' +
                              dd +
                              ' ' +
                              hour +
                              ':' +
                              min +
                              ':' +
                              sec
                            return date
                          },
                        },
                        {
                          header: 'Acciones',
                          key: 'acciones',
                          width: 99,
                          sortable: false,
                          Cell: (pqrs: PqrsNode) => (
                            <Modal>
                              {({ openModal }) => {
                                return (
                                  <ActionsContainer>
                                    <ViewAction
                                      onClick={() => {
                                        // openModal('PQRS_VIEW_ACTIONS', {
                                        //   pqrs,
                                        //   refetch,
                                        // })
                                        openModal('DETAILS', {
                                          header: {},
                                          description: (
                                            <PqrsViewModal
                                              pqrs={pqrs}
                                              refetch={refetch}
                                            />
                                          ),
                                          modalSize: 'lg',
                                        })
                                      }}
                                    >
                                      <Tooltip
                                        id={`view-${pqrs.id}`}
                                        message="Ver"
                                      >
                                        <ViewIcon name="eye" />
                                      </Tooltip>
                                    </ViewAction>
                                    <ViewAction
                                      onClick={() => {
                                        openModal('FORM', {
                                          header: {},
                                          description: (
                                            <PqrsEditModal
                                              pqrs={pqrs}
                                              refetch={refetch}
                                            />
                                          ),
                                          modalSize: 'lg',
                                          showCloseTop: true,
                                        })
                                      }}
                                    >
                                      <Tooltip
                                        id={`edit-${pqrs.id}`}
                                        message="Editar"
                                      >
                                        <EditIcon name="pencil" />
                                      </Tooltip>
                                    </ViewAction>
                                  </ActionsContainer>
                                )
                              }}
                            </Modal>
                          ),
                        },
                      ]

                      return (
                        <Modal>
                          {({ openModal }) => {
                            return (
                              <Mutation
                                mutation={EXPORT_PQRS_MUTATION}
                                onCompleted={() => {
                                  forceShowNotification &&
                                    forceShowNotification({
                                      type: 'ok',
                                      message: 'PQRS exportado correctamente',
                                    })
                                }}
                                onError={() => {
                                  openModal('ALERT', {
                                    header: {
                                      title: 'ALERTA',
                                    },
                                    description:
                                      'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                    type: 'fail',
                                  })
                                }}
                              >
                                {(exportPqrs, { loading: loadingEdit }) => (
                                  <React.Fragment>
                                    <DataTable
                                      indexKey="id"
                                      columns={columns}
                                      data={nodes}
                                      loading={loading}
                                      totalItemsCount={pagination.total}
                                      sortBy={sort_by}
                                      page={page || 1}
                                      pageSize={pageSize}
                                      filters={[
                                        {
                                          key: 'status',
                                          label: 'Estado',
                                          options: statusOptions,
                                        },
                                      ]}
                                      onFetch={this.onFetch}
                                      filtersOpened={filtersOpened}
                                      toggleFilterSection={
                                        this.toggleFilterSection
                                      }
                                      filterSelected={filterSelected}
                                      onFilterSelected={this.onFilterSelected}
                                      supplierFromUrl={pqrsFromUrl}
                                      pageSizeOptions={PAGE_SIZE_OPTIONS}
                                      searchText={s}
                                      button={{
                                        onClick: () => {
                                          if (exportPqrs) {
                                            exportPqrs().then((resp: any) => {
                                              if (
                                                resp.data &&
                                                resp.data.exportPqrs
                                              ) {
                                                const json = {
                                                  report: resp.data.exportPqrs,
                                                }
                                                const str = atob(json.report)
                                                var hiddenElement = document.createElement(
                                                  'a'
                                                )
                                                hiddenElement.href =
                                                  'data:text/csv;charset=utf-8,' +
                                                  encodeURI(str)
                                                hiddenElement.target = '_blank'
                                                hiddenElement.download =
                                                  'PQRS_Report-' +
                                                  new Date()
                                                    .toISOString()
                                                    .slice(0, 10) +
                                                  '.csv'
                                                hiddenElement.click()
                                              }
                                            })
                                          }
                                        },
                                        textButton: 'Exportar data',
                                      }}
                                      onSort={this.onSortByChange}
                                      onPageChange={this.onPageChange}
                                      onPageSizeChange={this.onPageSizeChange}
                                      onSearch={this.onSearch}
                                      placeholderSearchBar={'Buscar por cédula'}
                                    />
                                  </React.Fragment>
                                )}
                              </Mutation>
                            )
                          }}
                        </Modal>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          </>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default withRouter(PqrsTable)
