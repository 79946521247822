import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import * as Grid from '../../../../../components/Grid'
import { ValueType } from 'react-select/lib/types'

import { Input, Select } from '../../../../../components/FormElements'
import { Spacer } from '../../../../../components/Globals'

import {
  Title,
  DescriptionContainer,
  ConfirmContainer,
  ActionButton,
  Error,
  Feedback,
  FeedbackError,
} from './style'

interface Props {
  balance: any
  refetch: any
}

export const BUY_COMMERCE_POINTS_MUTATION = gql`
  mutation buyCommercePoints($input: CommerceTransactionsInput!) {
    buyCommercePoints(input: $input) {
      id
    }
  }
`

export const PAY_COMMERCE_POINTS_MUTATION = gql`
  mutation payCommercePoints($input: CommerceTransactionsInput!) {
    payCommercePoints(input: $input) {
      id
    }
  }
`

interface State {
  amount: string
  amountError: boolean
  option: ValueType<{ [key: string]: any } | string | number>
}

class TransactionsActionsModal extends React.Component<Props, State> {
  state: State = {
    amount: '',
    amountError: false,
    option: {},
  }

  handleamountChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const amount = value

    if (Number(amount) <= 0) {
      this.setState({
        amount,
        amountError: true,
      })
      return
    }

    this.setState({
      amount,
      amountError: false,
    })
  }

  handleTypeChange = (
    option: ValueType<{ [key: string]: any } | string | number>
  ) => {
    this.setState({ option })

    if (option && option['value'] === 'buy') {
      this.setState({
        amount: '',
        amountError: false,
      })
    }

    if (option && option['value'] === 'pay') {
      this.setState({
        amount: '',
        amountError: false,
      })
    }
  }

  render() {
    const { balance, refetch } = this.props
    let { amount, amountError } = this.state

    let option = {
      value: 'paid',
      label: 'Pagar deuda',
    }
    let price = balance ? balance.agreements[0].postpaidPrice : 0

    if (Number(balance.total) >= 0) {
      option = {
        value: 'buy',
        label: 'Comprar puntos',
      }
      price = balance ? balance.agreements[0].prepaidPrice : 0
    }

    let points = new Intl.NumberFormat('en-EN').format(Number(amount) / price)

    return (
      <>
        <Title>Pagar / Acreditar</Title>
        <DescriptionContainer />
        <ConfirmContainer>
          <Mutation
            mutation={BUY_COMMERCE_POINTS_MUTATION}
            onCompleted={() => {
              refetch()
            }}
          >
            {(
              buyCommercePoints,
              { loading: loadingBuy, called: calledBuy, error: errorBuy }
            ) => (
              <Mutation
                mutation={PAY_COMMERCE_POINTS_MUTATION}
                onCompleted={() => {
                  refetch()
                }}
              >
                {(
                  payCommercePoints,
                  { loading: loadingPay, called: calledPay, error: errorPay }
                ) => (
                  <React.Fragment>
                    <Grid.ContainerFluid>
                      <Grid.Row>
                        <Grid.Column md={4}>
                          <Input
                            id="prepaid_price"
                            type="text"
                            label="Precio Prepago"
                            disabled={true}
                            value={balance.agreements[0].prepaidPrice}
                            onChange={() => {}}
                          />
                          <Spacer />
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Input
                            id="postpaidPrice"
                            type="text"
                            label="Precio Postpago"
                            disabled
                            value={balance.agreements[0].postpaidPrice}
                            onChange={() => {}}
                          />
                          <Spacer />
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Input
                            id="convertionRate"
                            type="email"
                            label="Tasa de conversión"
                            disabled
                            value={balance.agreements[0].convertionRate}
                            onChange={() => {}}
                          />
                          <Spacer />
                        </Grid.Column>
                      </Grid.Row>
                      <Select
                        label="Tipo"
                        //placeholder="Seleccione..."
                        options={[option]}
                        value={option}
                        onChange={(
                          option: ValueType<
                            string | number | { [key: string]: any }
                          >
                        ) => this.handleTypeChange(option)}
                      />
                      <Spacer />

                      <Grid.Row>
                        <Grid.Column md={6}>
                          <Input
                            id="amount"
                            value={amount}
                            onChange={this.handleamountChange}
                            type="number"
                            label="Monto ($)"
                            required
                          />
                          {amountError ? (
                            <Error>Monto ingresado no válido..</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={6}>
                          <Input
                            id="points"
                            value={points}
                            onChange={() => {}}
                            type="text"
                            label="Puntos"
                            disabled
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid.ContainerFluid>
                    {calledBuy &&
                      !loadingBuy &&
                      (!errorBuy ? (
                        <Feedback>Puntos Acreditados con éxito</Feedback>
                      ) : (
                        <FeedbackError>
                          La acción solicitada no se pudo completar.
                          Intenténtelo más tarde por favor.
                        </FeedbackError>
                      ))}
                    {calledPay &&
                      !loadingPay &&
                      (!errorPay ? (
                        <Feedback>Puntos Pagados con éxito</Feedback>
                      ) : (
                        <FeedbackError>
                          La acción solicitada no se pudo completar.
                          Intenténtelo mas tarde por favor.
                        </FeedbackError>
                      ))}
                    <ActionButton
                      color="primary"
                      disabled={amountError || loadingBuy || loadingPay}
                      onClick={() => {
                        if (!option) {
                          return
                        }

                        if (option['value'] === 'buy') {
                          buyCommercePoints({
                            variables: {
                              input: {
                                amount,
                                balanceId: `${balance.id}`,
                              },
                            },
                          })
                          return
                        }

                        if (option['value'] === 'pay') {
                          payCommercePoints({
                            variables: {
                              input: {
                                amount,
                                balanceId: `${balance.id}`,
                              },
                            },
                          })
                          return
                        }
                      }}
                    >
                      {loadingBuy || loadingPay ? 'Cargando...' : 'Enviar'}
                    </ActionButton>
                  </React.Fragment>
                )}
              </Mutation>
            )}
          </Mutation>
        </ConfirmContainer>
      </>
    )
  }
}

export default TransactionsActionsModal
