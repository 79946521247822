import React from 'react'

import { Button } from '../../../../../components/Buttons'

import { Title, VariationContainer, VariationTitle } from './style'
import { Link } from 'react-router-dom'

interface ProductFeature {
  id: string
  name: string
}

interface ProductFeatureOption {
  id: string
  name: string
  feature: ProductFeature
}

export interface ProductVariation {
  id: string
  options: ProductFeatureOption[]
}

interface Props {
  variations: ProductVariation[]
}

class VariationListModal extends React.Component<Props> {
  render() {
    const { variations } = this.props
    return (
      <>
        <Title>Variaciones de producto</Title>
        {variations &&
          variations.map((variation, index) => (
            <VariationContainer key={variation.id}>
              <VariationTitle>
                Combinación {index + 1}:
                {variation.options.map(option => {
                  return (
                    <React.Fragment key={option.id}>
                      {' ' + option.feature.name + ' ' + option.name}
                    </React.Fragment>
                  )
                })}
              </VariationTitle>
              <Link target="_blank" to={`/variations/${variation.id}/edit`}>
                <Button color="primary">Editar Variación</Button>
              </Link>
            </VariationContainer>
          ))}
      </>
    )
  }
}

export default VariationListModal
