import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'

import Icon from '../Icon'

import {
  StyledButton,
  Label,
  StyledIconButton,
  StyledCancelButton,
} from './style'

interface Props extends React.HTMLProps<HTMLButtonElement> {
  color: 'primary' | 'secondary' | 'success' | 'tertiary'
  icon?: string
}

interface CancelProps extends RouteComponentProps<any> {
  color: 'primary' | 'secondary' | 'success'
  icon?: string
  to: string
  alone?: boolean
  children: React.ReactNode
}

export const Button = ({ ref, type, icon, ...props }: Props) => (
  <StyledButton {...props}>
    {icon && <Icon name={icon} />}
    <Label>{props.children}</Label>
  </StyledButton>
)

export const IconButton = ({ ref, type, icon, ...props }: Props) => (
  <StyledIconButton {...props}>{icon && <Icon name={icon} />}</StyledIconButton>
)

export const CancelButton = withRouter(
  ({ to, icon, alone, ...props }: CancelProps) => (
    <StyledCancelButton
      {...props}
      onClick={() => props.history.push(`/${to}`)}
      alone={alone}
    >
      {icon && <Icon name={icon} />}
      <Label>{props.children}</Label>
    </StyledCancelButton>
  )
)
