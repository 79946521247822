import styled from 'styled-components'
import { Button } from '../../../components/Buttons'
import sizes from '../../../styles/media'

export const SaveButton = styled(Button)`
  width: 100%;
  @media ${sizes.sm} {
    width: auto;
  }
`
