import React from 'react'
import AuthViewHandler from '../views/AuthViewHandler'

interface SwitchProps {
  Component: React.ComponentType
  FallbackComponent: React.ComponentType
}

interface SignedOutFallbackProps {
  Component: React.ComponentType
  FallbackComponent: React.ComponentType
}

const Switch = (props: SwitchProps) => {
  const { Component, FallbackComponent, ...rest } = props

  return (
    <AuthViewHandler>
      {(authed: boolean) => {
        if (!authed) {
          return <FallbackComponent {...rest} />
        } else {
          return <Component {...rest} />
        }
      }}
    </AuthViewHandler>
  )
}

const signedOutFallback = (
  Component: React.ComponentType,
  FallbackComponent: React.ComponentType
) => {
  return (props: SignedOutFallbackProps) => (
    <Switch
      {...props}
      Component={Component}
      FallbackComponent={FallbackComponent}
    />
  )
}

export default signedOutFallback
