import styled from 'styled-components'
import * as Grid from '../../components/Grid'

export const Instruction = styled.p`
  color: #858585;
`

export const Title = styled.p`
  margin-top: 30px;
  font-weight: bold;
  color: #858585;
`
export const Feedback = styled.div`
  font-size: 12px;
  color: #18b0f7;
  margin-bottom: 15px;
`

export const FeedbackError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #ff3b30;
`

export const StyledExportButtonContainer = styled(Grid.Column)`
  display: flex;
  align-self: flex-end;
  justify-content: center;
`
