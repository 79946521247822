import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import ThemeContext from '../../context/ThemeContext'

const GET_CHECK_LOGIN_QUERY = gql`
  query getCheckLoginQuery($token: String!) {
    checkLogin(token: $token) {
      roles {
        allowed
      }
    }
  }
`

interface Props {
  children: (authed: boolean) => React.ReactNode
}

interface Data {
  checkLogin: {
    roles: any
  }
}

class AuthViewHandler extends React.Component<Props> {
  render() {
    const { children } = this.props

    const { token } = JSON.parse(localStorage.getItem('sessionStorage') || '[]')

    return token ? (
      <ThemeContext.Consumer>
        {context => (
          <Query
            variables={{
              token: token,
            }}
            query={GET_CHECK_LOGIN_QUERY}
            fetchPolicy="network-only"
          >
            {({ data }: QueryResult<Data, any>) => {
              if (!data) {
                return children(false)
              }
              if (!data.checkLogin) {
                return <p>Checking Session Token</p>
              }

              const { roles } = data.checkLogin

              if (token && roles && roles.allowed) {
                if (!context.login.user) {
                  context.onSetLogin &&
                    context.onSetLogin({ user: roles.allowed, token })
                }
                sessionStorage.setItem('token', token)
                return children(true)
              }
              return children(false)
            }}
          </Query>
        )}
      </ThemeContext.Consumer>
    ) : (
      children(false)
    )
  }
}

export default AuthViewHandler
