export default {
  bg: {
    default: '#ffffff',
    generic: '#f1f4f8',
  },
  colors: {
    primary: {
      default: '#18b0f7',
      disabled: 'rgba(24, 176, 247, 0.4)',
      hover: '#16a6ea',
    },
    secondary: {
      default: '#858585',
      disabled: 'rgba(133, 133, 133, 0.4)',
      hover: '#757575',
    },
    success: {
      default: '#4cd964',
      disabled: 'rgba(76, 217, 100, 0.4)',
      hover: '#4cd964',
    },
    tertiary: {
      default: '#fff',
      disabled: 'rgba(133, 133, 133, 0.4)',
      hover: '#fff',
    },
  },
}
