import React from 'react'
import { Link } from 'react-router-dom'

import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import { PageTopSection, CreateButton } from '../../../components/Globals'
import CommerceTable from './components/CommerceTable'

export function CommerceList() {
  return (
    <PageWrapper title="Comercios">
      <PageTopSection>
        <Link to="/commerces/create">
          <CreateButton icon="plus" color="success">
            Crear Comercio
          </CreateButton>
        </Link>
      </PageTopSection>
      <Paper>
        <CommerceTable />
      </Paper>
    </PageWrapper>
  )
}
