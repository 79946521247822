import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import { AsyncSelect } from '../../FormElements'

const GET_COMMERCE_CATEGORIES_QUERY = gql`
  query getCommerceCategoriesQuery(
    $filters: CategoriesFilters!
    $options: CategoriesOptions!
  ) {
    commerceCategories(filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`

type Category = { [key: string]: any }

export type CategoryOption = ValueType<{ [key: string]: any }>

type Data = {
  commerceCategories: {
    nodes: Category[]
  }
}

type Props = {
  value: CategoryOption | CategoryOption[] | null
  onChange: (categories: CategoryOption) => void
  isMulti: boolean
  disabled: boolean
}

export default function CategorySelector({
  value,
  onChange,
  isMulti,
  disabled,
}: Props) {
  return (
    <Query
      query={GET_COMMERCE_CATEGORIES_QUERY}
      fetchPolicy="network-only"
      variables={{ options: { limit: 10 }, filters: {} }}
    >
      {({ data, loading, error, client }: QueryResult<Data, any>) => {
        if (loading) {
          return null
        }
        if (error || !data) {
          return (
            <p>
              Error cargando categories. <br />
              Por favor recargue la página.
            </p>
          )
        }

        return (
          <AsyncSelect
            id="categories"
            label="Categoria"
            placeholder="Seleccione un categoria"
            isDisabled={disabled}
            required
            isMulti={isMulti}
            value={value}
            onChange={onChange}
            getOptionLabel={({ name }: Category) => name}
            getOptionValue={({ id }: Category) => id}
            backspaceRemovesValue={false}
            defaultOptions={data.commerceCategories.nodes}
            cacheOptions
            loadOptions={async inputValue => {
              const { data } = await client.query<Data>({
                query: GET_COMMERCE_CATEGORIES_QUERY,
                variables: {
                  options: { limit: 10 },
                  filters: { s: inputValue },
                },
              })
              if (!data) {
                return []
              }
              return data.commerceCategories.nodes
            }}
          />
        )
      }}
    </Query>
  )
}
