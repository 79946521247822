import styled from 'styled-components'

import Icon from '../../../../components/Icon'

export const EyeIcon = styled(Icon)`
  color: #454545;
  font-size: 20px;
`

export const DetailTitle = styled.span`
  font-weight: bold;
`

export const DetailDescription = styled.span`
  color: #858585;
  padding-bottom: 5px;
`
export const ViewAction = styled.div`
  cursor: pointer;
`
