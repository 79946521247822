import React from 'react'

import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import PurchaseTable from './components/PurchaseTable'

import { PageTopSection } from './style'

function OrderList() {
  return (
    <PageWrapper title="Nuevos canjes">
      <Paper>
        <PurchaseTable />
      </Paper>
      <PageTopSection />
    </PageWrapper>
  )
}

export default OrderList
