import React from 'react'
import gql from 'graphql-tag'

import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import { AsyncSelect } from '../../../../components/FormElements'
import { Spacer } from '../../../../components/Globals'
import Loading from '../../../../components/Loader'

const GET_COURIERS_QUERY = gql`
  query getCouriersQuery(
    $options: CouriersOptions!
    $filters: CouriersFilters!
  ) {
    couriers(options: $options, filters: $filters) {
      nodes {
        id
        name
      }
    }
  }
`

type Courier = { [key: string]: any }
export type CourierOption = ValueType<{ [key: string]: any }>

interface Data {
  couriers: {
    nodes: Courier[]
  }
}

interface Props {
  value: CourierOption | CourierOption[] | null
  onChange: (courier: CourierOption) => void
  isRequired?: boolean
  disabled?: boolean
  error?: boolean
}

const CourierSelector = ({
  value,
  onChange,
  isRequired,
  disabled,
  error,
}: Props) => (
  <Query
    query={GET_COURIERS_QUERY}
    fetchPolicy="network-only"
    variables={{ options: { limit: 10 }, filters: {} }}
  >
    {({ loading, error: errorQuery, data, client }: QueryResult<Data, any>) => {
      if (loading) {
        return (
          <div id={'courier'}>
            <Spacer />
            <Loading />
          </div>
        )
      }
      if (errorQuery || !data) {
        return (
          <p>
            Error cargando couriers. <br />
            Por favor recargue la página.
          </p>
        )
      }

      return (
        <AsyncSelect
          id="courierSelect"
          label="Courier"
          placeholder="Seleccione un courier"
          required={isRequired}
          error={error}
          inputId={'courier'}
          value={value}
          disabled={disabled}
          onChange={onChange}
          getOptionLabel={({ name }: Courier) => name}
          getOptionValue={({ id }: Courier) => id}
          backspaceRemovesValue={false}
          defaultOptions={data.couriers.nodes}
          cacheOptions
          loadOptions={async inputValue => {
            const { data } = await client.query<Data>({
              query: GET_COURIERS_QUERY,
              variables: {
                options: { limit: 10 },
                filters: { s: inputValue },
              },
            })

            if (!data) {
              return []
            }

            return data.couriers.nodes
          }}
        />
      )
    }}
  </Query>
)

export default CourierSelector
