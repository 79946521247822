import React, { createContext } from 'react'

interface FilterOptions {
  label: string
  value: string
}
interface Filter {
  key: string
  label: string
  options: Array<FilterOptions>
  selected: any
}

interface IProps {}
export interface IState {
  filters: Array<Filter>
  handleState: any
}

const { Consumer, Provider } = createContext<IState | null>(null)
const AppConsumer = Consumer
const AppProvider = Provider

class AppContext extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      filters: [],
      handleState: this.HandleState.bind(this),
    }
  }
  HandleState(filters: any) {
    this.setState({
      filters: filters,
    })
  }
  render() {
    return <AppProvider value={this.state}>{this.props.children}</AppProvider>
  }
}

export { AppContext, AppConsumer }
