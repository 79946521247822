import React from 'react'
import { toast } from 'react-toastify'

import Badge from '../Badge'
import Icon from '../Icon'
import {
  ToastContainerBox,
  ToastContainer,
  ToastIconContainer,
  ToastText,
  ToastTextContainer,
} from './style'

import 'react-toastify/dist/ReactToastify.css'

interface ToastActions {
  notify: (iconName: string, message: string) => any
}

interface ToastRootProps {
  children?: (actions: ToastActions) => React.ReactNode
}

class Notification extends React.Component<ToastRootProps> {
  notify = (type: string, message: string) => {
    let color = ''
    let icon = ''
    switch (type) {
      case 'fail': {
        color = '#ff3b30'
        icon = 'cancel'
        break
      }
      case 'ok': {
        color = '#4cd964'
        icon = 'ok'
        break
      }
      case 'alert': {
        color = '#ffcc00'
        icon = 'attention-circled'
        break
      }
      default: {
        color = '#ffffff'
        break
      }
    }
    toast(() => {
      return (
        <ToastContainerBox color={color}>
          <ToastIconContainer>
            <Badge size="m">
              <Icon name={icon} />
            </Badge>
          </ToastIconContainer>
          <ToastTextContainer>
            <ToastText>{message}</ToastText>
          </ToastTextContainer>
        </ToastContainerBox>
      )
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children && this.props.children({ notify: this.notify })}
        <ToastContainer
          position={'bottom-right'}
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          draggable
          pauseOnHover
          closeButton={false}
        />
      </React.Fragment>
    )
  }
}

export default Notification
