import styled from 'styled-components'

export const TabList = styled.ol`
  padding-left: 0;
`

export const Paper = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
`
