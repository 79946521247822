import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import { AsyncSelect } from '../../FormElements'

const GET_CURRENCIES_QUERY = gql`
  query getCurrenciesQuery(
    $filters: CurrenciesFilters!
    $options: CurrenciesOptions!
  ) {
    currencies(filters: $filters, options: $options) {
      nodes {
        id
        name
        shortName
        description
      }
    }
  }
`

type Currency = { [key: string]: any }

export type CurrencyOption = ValueType<{ [key: string]: any }>

type Data = {
  currencies: {
    nodes: Currency[]
  }
}

type Props = {
  value: CurrencyOption | CurrencyOption[] | null
  onChange: (currencies: CurrencyOption) => void
  isMulti: boolean
  disabled: boolean
}

function CurrencySelector({ value, onChange, isMulti, disabled }: Props) {
  return (
    <Query
      query={GET_CURRENCIES_QUERY}
      variables={{ options: { limit: 10 }, filters: {} }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error, client }: QueryResult<Data, any>) => {
        if (loading) {
          return null
        }

        if (error || !data) {
          return (
            <p>
              Error cargando monedas. <br />
              Por favor recargue la página.
            </p>
          )
        }

        return (
          <AsyncSelect
            id="currencies"
            label="Currency"
            placeholder="Seleccione una Moneda"
            isDisabled={disabled}
            isMulti={isMulti}
            value={value}
            onChange={onChange}
            getOptionValue={({ id }: Currency) => id}
            getOptionLabel={({ name }: Currency) => name}
            backspaceRemovesValue={false}
            defaultOptions={data.currencies.nodes}
            cacheOptions
            loadOptions={async inputValue => {
              const { data } = await client.query<Data>({
                query: GET_CURRENCIES_QUERY,
                variables: {
                  filters: { s: inputValue },
                  options: { limit: 10 },
                },
              })
              if (!data) {
                return []
              }
              return data.currencies.nodes
            }}
            required
          />
        )
      }}
    </Query>
  )
}

export default CurrencySelector
