import React from 'react'
import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import PurchaseOrdersTable from './components/PurchaseOrderTable'
import PurchaseOrderItemsTable from './components/PurchaseOrderItemsTable'

export const PurchaseOrderList = () => (
  <PageWrapper title="Órdenes de Compra">
    <Paper>
      <PurchaseOrdersTable />
    </Paper>
  </PageWrapper>
)

export const PurchaseOrderItemsList = () => (
  <PageWrapper title="Productos de la orden de compra">
    <Paper>
      <PurchaseOrderItemsTable />
    </Paper>
  </PageWrapper>
)

// export default PurchaseOrderList
