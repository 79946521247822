import React from 'react'
import { CustomIcon } from './style'

interface Props {
  className?: string
  name: string
  color?: string
  size?: string
}

const Icon = (props: Props) => {
  let className = ''
  let color = ''
  let size = ''

  if (props.color) {
    color = props.color
  }
  if (props.size) {
    size = props.size
  }
  if (props.className) {
    className += `${props.className} `
  }

  className += `icon-${props.name}`

  return <CustomIcon color={color} size={size} className={className} />
}

export default Icon
