import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import PageWrapper from '../../../components/PageWrapper'
import CreatePromoTypeForm from './components/CreatePromoTypeForm'
import EditPromoTypeForm from './components/EditPromoTypeForm'

interface RouteParams {
  id?: string
  action: string
  history: string
}

const GET_PROMO_TYPE_QUERY = gql`
  query getPromoTypeQuery($id: ID!) {
    promoType(id: $id) {
      id
      name
    }
  }
`
interface Data {
  promoType: {
    id: string
    name: string
  }
}

class PromoTypeForm extends React.Component<RouteComponentProps<RouteParams>> {
  onPromoTypeSaved = () => {
    this.props.history.push('/promo-types')
  }

  render() {
    const { id, action } = this.props.match.params

    return (
      <PageWrapper
        title={`${action === 'create' ? 'Crear' : 'Editar'} Tipo de Promo`}
      >
        {action === 'create' ? (
          <CreatePromoTypeForm onCreated={this.onPromoTypeSaved} />
        ) : (
          <Query
            variables={{ id }}
            query={GET_PROMO_TYPE_QUERY}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return null
              }

              if (error) {
                return null
              }

              if (!data) {
                return null
              }
              return (
                <EditPromoTypeForm
                  promoType={data.promoType}
                  onUpdated={this.onPromoTypeSaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(PromoTypeForm)
