import React from 'react'
import { ValueType } from 'react-select/lib/types'

import {
  Input,
  CreatableSelect,
  Error,
} from '../../../../components/FormElements'
import { Spacer } from '../../../../components/Globals'
import { CloseButton, CloseIcon, OldElements } from '../style'
import { Field } from '../../../../helpers/validations'

interface Props {
  index: number
  error?: string
  featureName: Field
  options: Field
  oldOptions?: string[]
  disabled?: boolean
  required?: boolean

  onFeatureNameChange: (featureIndex: number, featureName: string) => void
  onOptionsChange: (featureIndex: number, options: string[]) => void
  onNewOptionCreate: (featureIndex: number, newOption: string) => void
  onCloseClick?: (featureIndex: number) => void
}

const FeatureGroup = ({
  index,
  featureName,
  onFeatureNameChange,
  options,
  onOptionsChange,
  onNewOptionCreate,
  oldOptions,
  onCloseClick,
  required,
  disabled,
}: Props) => (
  <React.Fragment>
    {oldOptions ? null : (
      <CloseButton
        onClick={() => {
          onCloseClick && onCloseClick(index)
        }}
      >
        <CloseIcon name="cancel-circled" />
      </CloseButton>
    )}
    <Input
      id={`feature${index}`}
      required
      label="Característica"
      type="text"
      value={featureName.value}
      disabled={disabled}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onFeatureNameChange(index, e.target.value)
      }
      error={featureName.error}
    />
    {featureName.error ? <Error>{featureName.errorText}</Error> : <Spacer />}

    {oldOptions ? (
      <>
        <OldElements
          label="Opciones Actuales"
          isMulti
          value={oldOptions.map(option => ({ label: option, value: option }))}
          disabled={true}
        />
        <Spacer />
      </>
    ) : null}
    <CreatableSelect
      placeholder="Añadir opciones"
      label="Opciones"
      inputId={`options${index}`}
      required={required}
      isMulti
      options={
        options &&
        options.value &&
        options.value.map((option: string) => ({
          label: option,
          value: option,
        }))
      }
      value={
        options &&
        options.value &&
        options.value.map((option: string) => ({
          label: option,
          value: option,
        }))
      }
      onChange={(options: ValueType<{ [key: string]: any }>) => {
        onOptionsChange(
          index,
          options &&
            options.map(
              (opt: ValueType<{ [key: string]: any }>) => opt && opt['value']
            )
        )
      }}
      onCreateOption={(newOption: string) =>
        onNewOptionCreate(index, newOption)
      }
      formatCreateLabel={(currentValue: string) =>
        'Crear nueva opción: ' + currentValue
      }
      noOptionsMessage={() => 'No existen resultados'}
      error={options.error}
    />
    {options.error ? <Error>{options.errorText}</Error> : <Spacer />}
  </React.Fragment>
)

export default FeatureGroup
