import React from 'react'
import {
  Container,
  Wrapper,
  CloseButton,
  CloseIcon,
  Preview,
  Overlay,
  PreviewButton,
  PreviewIcon,
} from './style'

interface Props {
  url: string
  onPreviewClick: () => void
  onRemove: () => void
}

const Image = ({ url, onPreviewClick, onRemove }: Props) => {
  return (
    <Container>
      <CloseButton onClick={onRemove}>
        <CloseIcon name="cancel-circled" />
      </CloseButton>
      <Wrapper>
        <Overlay>
          <PreviewButton onClick={onPreviewClick}>
            <PreviewIcon name="eye" />
          </PreviewButton>
        </Overlay>
        <Preview url={url} />
      </Wrapper>
    </Container>
  )
}

export default Image
