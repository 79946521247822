import React from 'react'
import gql from 'graphql-tag'

import PageWrapper from '../../components/PageWrapper'
import Paper from '../../components/Paper'
import * as Grid from '../../components/Grid'

import { Mutation } from 'react-apollo'
import { Button } from '../../components/Buttons'

import { Instruction, StyledExportButtonContainer } from './style'

import ThemeContext from '../../context/ThemeContext'

import { DatePicker } from '../../components/FormElements'

interface State {
  to: Date
  from: Date
}

const EXPORT_SALES_MUTATION = gql`
  mutation exportSales($input: ExportSalesInput!) {
    exportSales(input: $input)
  }
`
type ExportSalesInput = {
  to: string
  from: string
}

class SalesExport extends React.Component<{}, State> {
  state: State = {
    to: new Date(),
    from: new Date(),
  }

  checkTime = (i: Number) => {
    return i < 10 ? '0' + i : i
  }

  formatDate = (date: Date) => {
    var dd = String(date.getDate()).padStart(2, '0')
    var mm = String(date.getMonth() + 1).padStart(2, '0')
    var yyyy = date.getFullYear()

    var hh = this.checkTime(date.getHours())
    var min = this.checkTime(date.getMinutes())
    var ss = this.checkTime(date.getSeconds())

    var formDate = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + ss
    return formDate
  }

  handleDateFromChange = (from: Date) => {
    this.setState({
      from,
    })
  }

  handleDateToChange = (to: Date) => {
    this.setState({
      to,
    })
  }

  render() {
    const { to, from } = this.state
    return (
      <ThemeContext.Consumer>
        {notify => (
          <PageWrapper title="Reporte de ventas">
            <Paper>
              <Grid.Row>
                <Grid.Column md={12}>
                  <Instruction>
                    Seleccione el rango de fecha del cual desea generar el
                    reporte
                  </Instruction>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column md={4}>
                  <DatePicker
                    label="Desde"
                    date={from}
                    onChange={this.handleDateFromChange}
                    dateFormat="yyyy-MM-dd h:mm:ss"
                    showTimeSelect
                  />
                </Grid.Column>
                <Grid.Column md={4}>
                  <DatePicker
                    label="Hasta"
                    date={to}
                    onChange={this.handleDateToChange}
                    dateFormat="yyyy-MM-dd h:mm:ss"
                    showTimeSelect
                  />
                </Grid.Column>
                <StyledExportButtonContainer md={4}>
                  <Mutation
                    mutation={EXPORT_SALES_MUTATION}
                    onCompleted={() => {
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message:
                            'Se ha enviado un correo con las ventas exportadas',
                        })
                    }}
                    onError={() => {
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'fail',
                          message: 'Se ha produjo en error inténtelo más tarde',
                        })
                    }}
                  >
                    {(exportProducts, { loading, error }) => {
                      return (
                        <Button
                          color="secondary"
                          onClick={() => {
                            const exportSales: ExportSalesInput = {
                              to: this.formatDate(to),
                              from: this.formatDate(from),
                            }

                            exportProducts({
                              variables: {
                                input: exportSales,
                              },
                            })
                          }}
                        >
                          Exportar ventas
                        </Button>
                      )
                    }}
                  </Mutation>
                </StyledExportButtonContainer>
              </Grid.Row>
            </Paper>
          </PageWrapper>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default SalesExport
