import styled from 'styled-components'
import Icon from '../Icon'
import sizes from '../../styles/media'

interface Props {
  type?: string
  style: {
    fontSize?: string
    textAlign?: string
    color?: string
    modalSize?: string
  }
}

export let modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.16)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '1.2rem',
    zIndex: 10,
  },
  content: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: '20px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2)',
    padding: 0,
    border: 0,
    top: 'auto',
    bottom: 'auto',
    left: 'auto',
    right: 'auto',
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
  },
}
export const Title = styled.h1<Props>`
  height: 27px;
  font-family: Ubuntu;
  font-size: ${props => (props.style.fontSize ? props.style.fontSize : '24px')};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: ${props =>
    props.style.textAlign ? props.style.textAlign : 'center'};
  color: ${props => (props.style.color ? props.style.color : '#858585')};
`
export const DescriptionContainer = styled('div').attrs<Props>({})`
${props =>
  props.type == 'ALERT' && {
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    'align-items': 'center',
    padding: '10px 20px',
    'margin-bottom': '10px',
    height: '49px',
    'font-family': 'Ubuntu',
    'font-size': '14px',
    'text-align': 'center',
    color: '#858585',
  }}
@media ${sizes.sm} {
  flex-direction: "row",
}
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px 5px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`

export const Body = styled.div``

export const CloseButton = styled.button`
  background-color: #ff2d2d;
  border-radius: 15px;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  padding: 2px 0;
  left: -8px;
  position: absolute;
`

export const CloseIcon = styled(Icon)`
  color: white;
  font-size: 20px;
`

export const Wrapper = styled.div`
  padding: 1rem;
`

export const ConfirmContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
`
