import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router'

import {
  MenuGroup,
  SubMenuLink,
  SubMenu,
  StyledMenuItem,
  MenuLink,
  MenuIcon,
} from './style'

interface Props extends RouteComponentProps<any> {
  title: string
  icon: string
  items?: Items[]
  to?: string
}

interface Items {
  url: string
  label: string
}

interface State {
  isOpen: boolean
  active: boolean
  activePath: string
}

class MenuItem extends React.Component<Props, State> {
  state = {
    isOpen: false,
    active: false,
    activePath: '',
  }

  componentDidMount() {
    this.checkActive()
  }

  checkActive() {
    const { location, to, items } = this.props
    const url = location.pathname.split('/')
    const currentPath = `/${url[1]}`

    const newState = { activePath: currentPath }

    if (to) {
      if (currentPath === to) {
        newState['active'] = true
      }
    } else {
      if (items) {
        items.forEach(item => {
          if (currentPath === item.url) {
            newState['active'] = true
            newState['isOpen'] = true
          }
        })
      }
    }

    this.setState(newState)
  }

  toggleOrRedirect = () => {
    const { to, history } = this.props
    const { isOpen } = this.state
    to || to === ''
      ? history.push(`/${to}`)
      : this.setState({ isOpen: !isOpen })
  }

  render() {
    const { title, items, icon, to } = this.props
    const { isOpen, active, activePath } = this.state
    return (
      <StyledMenuItem>
        <MenuLink
          onClick={this.toggleOrRedirect}
          active={active || activePath === to ? 1 : 0}
        >
          <MenuIcon name={icon} />
          <span>{title}</span>
        </MenuLink>
        {items && (
          <SubMenu open={isOpen}>
            <MenuGroup>
              {items.map((item, index) => (
                <StyledMenuItem key={`submenu-${index}`}>
                  <SubMenuLink
                    to={`${item.url}`}
                    active={active && activePath === item.url ? 1 : 0}
                  >
                    {item.label}
                  </SubMenuLink>
                </StyledMenuItem>
              ))}
            </MenuGroup>
          </SubMenu>
        )}
      </StyledMenuItem>
    )
  }
}

export default withRouter(MenuItem)
