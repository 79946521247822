import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import * as Grid from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import { Input, Error } from '../../../../components/FormElements'
import { Spacer, ActionWrapper } from '../../../../components/Globals'
import Modal from '../../../../components/Modals/ModalRoot'

import ThemeContext from '../../../../context/ThemeContext'

import { SaveButton } from '../../../../components/Globals'

const EDIT_PROMO_TYPE_MUTATION = gql`
  mutation editPromoTypeMutation($input: EditPromoTypeInput!) {
    editPromoType(input: $input)
  }
`

interface State {
  name: string
  nameError: boolean
  createError: boolean
}

interface Props {
  promoType: {
    id: string
    name: string
  }
  onUpdated: () => void
}

class EditPromoTypeForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { promoType } = props
    this.state = {
      name: promoType.name,
      nameError: false,
      createError: false,
    }
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    if (name.length >= 40) {
      this.setState({
        name,
        nameError: true,
      })

      return
    }

    this.setState({
      name,
      nameError: false,
    })
  }

  render() {
    const { name, nameError } = this.state
    const { promoType } = this.props

    return (
      <Modal>
        {({ openModal }) => {
          return (
            <React.Fragment>
              <Paper>
                <Grid.Row>
                  <Grid.Column md={4}>
                    <Input
                      id="name"
                      value={name}
                      onChange={this.handleNameChange}
                      type="text"
                      error={nameError}
                      label="Nombre del tipo de promoción"
                      required
                    />
                    {nameError ? (
                      <Error>
                        La longitud máxima debe ser de 40 caracteres.
                      </Error>
                    ) : (
                      <Spacer />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Paper>
              <ThemeContext.Consumer>
                {notify => (
                  <Mutation
                    mutation={EDIT_PROMO_TYPE_MUTATION}
                    onCompleted={() => {
                      this.props.onUpdated()
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message: 'Tipo de promoción guardada correctamente',
                        })
                    }}
                    onError={() => {
                      openModal('ALERT', {
                        header: {
                          title: 'ALERTA',
                        },
                        description:
                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                        type: 'fail',
                      })
                    }}
                  >
                    {(updatePromoType, { loading, error }) => (
                      <ActionWrapper>
                        <SaveButton
                          color="primary"
                          disabled={loading}
                          onClick={() => {
                            if (!name || nameError) {
                              this.setState({
                                nameError: true,
                              })
                              return
                            }

                            updatePromoType({
                              variables: {
                                input: { promoTypeId: promoType.id, name },
                              },
                            })
                          }}
                        >
                          {loading ? 'Guardando cambios...' : 'Guardar cambios'}
                        </SaveButton>
                      </ActionWrapper>
                    )}
                  </Mutation>
                )}
              </ThemeContext.Consumer>
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
}

export default EditPromoTypeForm
