import styled from 'styled-components'
import Icon from '../../../components/Icon'

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const EditIcon = styled(Icon)`
  color: #18b0f7;
  font-size: 20px;
`

export const DeleteIcon = styled(Icon)`
  color: #454545;
  font-size: 20px;
`
