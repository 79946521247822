import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import Paper from '../../../../components/Paper'
import * as Grid from '../../../../components/Grid'
import { Input, DatePicker, Error } from '../../../../components/FormElements'
import {
  SaveButton,
  ActionWrapper,
  Spacer,
} from '../../../../components/Globals'

import CommerceCategorySelector, {
  CategoryOption,
} from '../../../../components/Selectors/CommerceCategorySelector'

import CurrencySelector from '../../../../components/Selectors/CurrencySelector'

import {
  useStringInput,
  useNumberInput,
  useDateInput,
  useAsyncSelectInput,
} from '../index'

const CREATE_COMMERCE_MUTATION = gql`
  mutation createCommerceMutation($input: CreateCommerceInput!) {
    createCommerce(input: $input) {
      id
    }
  }
`

type Agreement = {
  prepaidPrice: number | undefined
  postpaidPrice: number | undefined
  convertionRate: number | undefined
  validityTo: string
  validityFrom: string
}

type CurrencyBalances = {
  currencyId: string
  agreement: Agreement
}

type CreateCommerceInput = {
  name: string
  commercialEmail: string
  loyaltyEmail: string
  merchantCode: string
  categories: string[]
  currencyBalances: CurrencyBalances[]
}

type Props = {
  onCreated: () => void
}

export default function CreateCommerceForm({ onCreated }: Props) {
  const name = useStringInput('', 40)
  const commercialEmail = useStringInput('', 40)
  const loyaltyEmail = useStringInput('', 40)
  const merchantCode = useStringInput('', 40)
  const categories = useAsyncSelectInput(null)
  const currency = useAsyncSelectInput(null)
  const prepaidPrice = useNumberInput('')
  const postpaidPrice = useNumberInput('')
  const convertionRate = useNumberInput('')
  const validityTo = useDateInput(new Date())
  const validityFrom = useDateInput(new Date())

  return (
    <>
      <Paper>
        <Grid.ContainerFluid>
          <Grid.Row>
            <Grid.Column md={4}>
              <Input
                id="name"
                type="text"
                label="Nombre del comercio"
                required
                {...name}
              />
              {name.error ? (
                <Error>La longitud máxima debe ser de 40 caracteres.</Error>
              ) : (
                <Spacer />
              )}
            </Grid.Column>
            <Grid.Column md={4}>
              <Input
                id="commercialEmail"
                type="email"
                label="Email Comercial"
                required
                {...commercialEmail}
              />
              {commercialEmail.error ? (
                <Error>La longitud máxima debe ser de 40 caracteres.</Error>
              ) : (
                <Spacer />
              )}
            </Grid.Column>
            <Grid.Column md={4}>
              <Input
                id="loyaltyEmail"
                type="email"
                label="Email Loyalty"
                required
                {...loyaltyEmail}
              />
              {loyaltyEmail.error ? (
                <Error>La longitud máxima debe ser de 40 caracteres.</Error>
              ) : (
                <Spacer />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column md={4}>
              <Input
                id="merchantCode"
                type="text"
                label="RUC"
                required
                {...merchantCode}
              />
              {merchantCode.error ? (
                <Error>La longitud máxima debe ser de 40 caracteres.</Error>
              ) : (
                <Spacer />
              )}
            </Grid.Column>
            <Grid.Column md={4}>
              <CommerceCategorySelector
                isMulti={true}
                disabled={false}
                {...categories}
              />
              {categories.value ? (
                <Spacer />
              ) : (
                <Error>Categoría Requerida.</Error>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid.ContainerFluid>
      </Paper>
      <Spacer />
      <Paper>
        <Grid.ContainerFluid>
          <Grid.Row>
            <Grid.Column md={4}>
              <CurrencySelector
                isMulti={false}
                disabled={false}
                {...currency}
              />
              {currency.value ? <Spacer /> : <Error>Currency Requerida.</Error>}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column md={4}>
              <Input
                id="prepaidPrice"
                type="number"
                label="Prepago"
                required
                {...prepaidPrice}
              />
              <Spacer />
            </Grid.Column>
            <Grid.Column md={4}>
              <Input
                id="postpaidPrice"
                type="number"
                label="Postpago"
                required
                {...postpaidPrice}
              />
              {postpaidPrice &&
              prepaidPrice &&
              postpaidPrice.value &&
              prepaidPrice.value &&
              Number(postpaidPrice.value) < Number(prepaidPrice.value) ? (
                <Error>
                  El valor del postpago debe ser mayor o igual al valor prepago.
                </Error>
              ) : (
                <Spacer />
              )}
            </Grid.Column>
            <Grid.Column md={4}>
              <Input
                id="convertionRate"
                type="number"
                label="Tasa de conversión"
                required
                {...convertionRate}
              />
              {convertionRate &&
              prepaidPrice &&
              convertionRate.value &&
              prepaidPrice.value &&
              Number(convertionRate.value) >= Number(prepaidPrice.value) ? (
                <Error>
                  El valor de la tasa de conversión debe ser menor al valor
                  prepago.
                </Error>
              ) : (
                <Spacer />
              )}
            </Grid.Column>
            <Grid.Column md={4}>
              <DatePicker label="Desde" {...validityTo} />
            </Grid.Column>
            <Grid.Column md={4}>
              <DatePicker label="Hasta" {...validityFrom} />
            </Grid.Column>
          </Grid.Row>
        </Grid.ContainerFluid>
      </Paper>
      <Mutation
        mutation={CREATE_COMMERCE_MUTATION}
        onCompleted={() => onCreated()}
      >
        {(createCommerce, { loading }) => {
          return (
            <ActionWrapper>
              <SaveButton
                color="primary"
                disabled={
                  !name.value ||
                  !commercialEmail.value ||
                  !loyaltyEmail.value ||
                  !merchantCode.value ||
                  !categories.value ||
                  (categories && categories.value == []) ||
                  !currency.value ||
                  (prepaidPrice && !prepaidPrice.value) ||
                  (postpaidPrice && !postpaidPrice.value) ||
                  (convertionRate && !convertionRate.value) ||
                  !validityTo.date ||
                  !validityFrom.date ||
                  name.error ||
                  commercialEmail.error ||
                  loyaltyEmail.error ||
                  merchantCode.error
                }
                onClick={async () => {
                  if (
                    !name.value ||
                    !commercialEmail.value ||
                    !loyaltyEmail.value ||
                    !merchantCode.value ||
                    !categories.value ||
                    (categories && categories.value == []) ||
                    !currency.value ||
                    (prepaidPrice && !prepaidPrice.value) ||
                    (postpaidPrice && !postpaidPrice.value) ||
                    (convertionRate && !convertionRate.value) ||
                    !validityTo.date ||
                    !validityFrom.date ||
                    name.error ||
                    commercialEmail.error ||
                    loyaltyEmail.error ||
                    merchantCode.error
                  ) {
                    return
                  }

                  const input: CreateCommerceInput = {
                    name: name.value,
                    commercialEmail: commercialEmail.value,
                    loyaltyEmail: loyaltyEmail.value,
                    merchantCode: merchantCode.value,
                    categories:
                      categories &&
                      categories.value &&
                      categories.value.map(
                        (category: CategoryOption) => category && category['id']
                      ),
                    currencyBalances: [
                      {
                        currencyId: currency.value && currency.value['id'],
                        agreement: {
                          prepaidPrice:
                            prepaidPrice && Number(prepaidPrice.value),
                          postpaidPrice:
                            postpaidPrice && Number(postpaidPrice.value),
                          convertionRate:
                            convertionRate && Number(convertionRate.value),
                          validityTo: validityTo.date.toJSON(),
                          validityFrom: validityFrom.date.toJSON(),
                        },
                      },
                    ],
                  }

                  createCommerce({
                    variables: {
                      input,
                    },
                  })
                }}
              >
                {loading ? 'Creando comercio...' : 'Crear Comercio'}
              </SaveButton>
            </ActionWrapper>
          )
        }}
      </Mutation>
    </>
  )
}
