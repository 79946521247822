import React from 'react'
import { Input, Error } from '../../../../components/FormElements'
import { Spacer } from '../../../../components/Globals'
import { CloseButton, CloseIcon } from '../style'
import { Field } from '../../../../helpers/validations'

type metadata = {
  key: Field
  value: Field
}
interface Props {
  index: number
  error?: string
  metadata: metadata
  value?: string
  oldOptions?: string[]
  disabled?: boolean
  required?: boolean

  onKeyChange: (index: number, key: string) => void
  onValueChange: (index: number, value: string) => void
  onCloseClick: (featureIndex: number) => void
}

const MetadaGroup = ({
  index,
  metadata,
  onKeyChange,
  onValueChange,
  onCloseClick,
}: Props) => (
  <React.Fragment>
    <CloseButton
      onClick={() => {
        onCloseClick && onCloseClick(index)
      }}
    >
      <CloseIcon name="cancel-circled" />
    </CloseButton>
    <Input
      id={`key-${index}`}
      required
      label="Clave"
      type="text"
      value={metadata.key.value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onKeyChange(index, e.target.value)
      }
      error={metadata.key.error}
    />
    {metadata.key.error ? <Error>{metadata.key.errorText}</Error> : <Spacer />}
    <Input
      id={`value-${index}`}
      required
      label="Valor"
      type="text"
      value={metadata.value.value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onValueChange(index, e.target.value)
      }
      error={metadata.value.error}
    />
    {metadata.value.error ? (
      <Error>{metadata.value.errorText}</Error>
    ) : (
      <Spacer />
    )}
  </React.Fragment>
)

export default MetadaGroup
