import React from 'react'
import {
  Container,
  Wrapper,
  CloseButton,
  CloseIcon,
  Preview,
  Overlay,
  PreviewButton,
  PreviewIcon,
} from './style'

interface Props {
  url: string
  onPreviewClick: () => void
  onRemove: () => void
}

const Video = ({ url, onPreviewClick, onRemove }: Props) => {
  const videoID = url.split('/').pop()
  const preview = `https://img.youtube.com/vi/${videoID}/0.jpg`

  return (
    <Container>
      <CloseButton onClick={onRemove}>
        <CloseIcon name="cancel-circled" />
      </CloseButton>
      <Wrapper>
        <Overlay>
          <PreviewButton onClick={onPreviewClick}>
            <PreviewIcon name="play" />
          </PreviewButton>
        </Overlay>
        <Preview url={preview} />
      </Wrapper>
    </Container>
  )
}

export default Video
