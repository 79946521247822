import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import { AsyncSelect } from '../../../../components/FormElements'
import Loading from '../../../../components/Loader'
import { Spacer } from '../../../../components/Globals'

const GET_PRODUCTS_QUERY = gql`
  query getProductsQuery(
    $options: ProductsOptions!
    $filters: ProductsFilters!
  ) {
    products(options: $options, filters: $filters) {
      nodes {
        id
        name
        features {
          id
          name
          options {
            id
            name
          }
        }
      }
    }
  }
`
export type ProductOption = { [key: string]: any }

export interface Feature {
  id: string
  name: string
  options: ProductOption[]
}
export interface Product {
  id: string
  name: string
  features: Feature[]
}

interface Data {
  products: {
    nodes: Product[]
  }
}

interface Props {
  value: any
  onChange: (
    product: ValueType<string | number | { [key: string]: any }>
  ) => void
}

function ProductSelector({ value, onChange }: Props) {
  return (
    <Query
      query={GET_PRODUCTS_QUERY}
      fetchPolicy="network-only"
      variables={{ options: { limit: 10 }, filters: {} }}
    >
      {({ loading, error, data, client }: QueryResult<Data, any>) => {
        if (loading) {
          return (
            <>
              <Spacer />
              <Loading />
            </>
          )
        }
        if (error || !data) {
          return (
            <p>
              Error cargando productos. <br />
              Por favor recargue la página.
            </p>
          )
        }

        return (
          <AsyncSelect
            id="product"
            label="Producto"
            placeholder="Seleccione un producto"
            required
            value={value}
            onChange={onChange}
            getOptionLabel={({ name }: Product) => name}
            getOptionValue={({ id }: Product) => id}
            backspaceRemovesValue={false}
            defaultOptions={data.products.nodes}
            cacheOptions
            loadOptions={async inputValue => {
              const { data } = await client.query<Data>({
                query: GET_PRODUCTS_QUERY,
                variables: {
                  options: { limit: 10 },
                  filters: { s: inputValue },
                },
              })

              if (!data) {
                return []
              }

              return data.products.nodes
            }}
          />
        )
      }}
    </Query>
  )
}

export default ProductSelector
