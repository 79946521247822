export function countDecimals(value: string) {
  const split = value.split('.')[1]
  return (split && split.length) || 0
}

export function round(value: number, decimals: number) {
  return Number(
    Math.round(Number(value.toString() + 'e' + decimals.toString())) +
      'e-' +
      decimals
  )
}

export function parseJwt(token: string) {
  if (!token && token === '') {
    return ''
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}
