import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import PageWrapper from '../../../components/PageWrapper'
import CreateBrandForm from './components/CreateBrandForm'
import EditBrandForm from './components/EditBrandForm'

interface RouteParams {
  id?: string
  action: string
  history: string
}

const GET_BRAND_QUERY = gql`
  query getBrandQuery($id: ID!) {
    brand(id: $id) {
      id
      name
    }
  }
`

interface Data {
  brand: {
    id: string
    name: string
  }
}

class BrandForm extends React.Component<RouteComponentProps<RouteParams>> {
  onBrandSaved = () => {
    this.props.history.push('/brands')
  }

  render() {
    const { id, action } = this.props.match.params

    return (
      <PageWrapper title={`${action === 'create' ? 'Crear' : 'Editar'} Marca`}>
        {action === 'create' ? (
          <CreateBrandForm onCreated={this.onBrandSaved} />
        ) : (
          <Query
            variables={{ id }}
            query={GET_BRAND_QUERY}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return null
              }

              if (error) {
                return null
              }

              if (!data) {
                return null
              }

              return (
                <EditBrandForm
                  brand={data.brand}
                  onUpdated={this.onBrandSaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(BrandForm)
