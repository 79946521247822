import React from 'react'
import { Link } from 'react-router-dom'
import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import VariationTable from './components/VariationTable'
import { PageTopSection, CreateButton } from './style'

function VariationList() {
  return (
    <PageWrapper title="Variaciones">
      <PageTopSection>
        <Link to="/variations/create">
          <CreateButton icon="plus" color="success">
            Crear Variación
          </CreateButton>
        </Link>
      </PageTopSection>
      <Paper>
        <VariationTable />
      </Paper>
    </PageWrapper>
  )
}

export default VariationList
