import styled from 'styled-components'
import { UserInfo } from './UserSection/style'
import { MenuLink, MenuGroup } from './Menu/MenuItem/style'

interface Props {
  collapsed: boolean
}

export const Container = styled.aside<Props>`
  background-image: linear-gradient(#18b0f7, #00659c);
  color: #ffffff;
  position: fixed;
  transition: 0.35s;
  z-index: 10;
  width: 280px;

  ${props => {
    if (props.collapsed) {
      return `
        @media (min-width: 992px) {
          width: 100px;
        }

        @media (max-width: 991px) {
          transform: translate3d(-280px,0,0);
        }
      `
    }

    return ''
  }};
`

export const Header = styled.div`
  color: #ffffff;
  padding: 25px 15px;
  text-align: center;
`

export const Logo = styled.img`
  word-wrap: break-word;
  width: 100%;
  max-width: 130px;
`
export const MenuWrapper = styled.div`
  height: calc(100vh - 75px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 60px;
  position: relative;
  opacity: 1;

  & ${MenuLink} span {
    transition: opacity 0.35s, transform 0.35s;
  }

  & ${UserInfo} span {
    transition: opacity 0.35s, transform 0.35s;
  }

  ${(props: Props) => {
    if (props.collapsed) {
      return `
        & ${MenuLink} span {
          opacity: 0;
          transform: translate3d(-25px,0,0);
        }

        & ${UserInfo} span {
          opacity: 0;
          transform: translate3d(-25px,0,0);
        }
      `
    }
    return null
  }};

  ${MenuGroup} {
    display: ${(props: Props) => (props.collapsed ? 'none' : 'block')};
  }
`

export const MenuFill = styled.img`
  opacity: 0.5;
  background-blend-mode: multiply;
  position: absolute;
  left: 0;
`

export const MenuTopFill = styled(MenuFill)`
  top: 138px;
  width: 100%;
`

export const MenuBottomFill = styled(MenuFill)`
  top: 366px;
  width: 100%;
`
