import styled from 'styled-components'

export const Loader = styled.div`
  width: 100%;
  height: 20px;
  border: 1px solid #2980b9;
  border-radius: 3px;
  background-image: repeating-linear-gradient(
    -45deg,
    #2980b9,
    #2980b9 11px,
    #eee 10px,
    #eee 20px /* determines size */
  );
  background-size: 28px 28px;
  animation: move 0.5s linear infinite;

  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 28px 0;
    }
  }
`
