import * as qs from 'qs'

interface PageParams {
  sort_by?: string
  page?: number
  page_size?: number
  status?: string
  type?: string
  s?: string
  id: string
}

interface CursorPageParams {
  sort_by?: string
  page_size?: number
  after?: string
  before?: string
  supplier?: string
  promo?: string
  promo_type?: string
  entity_type?: string
  type?: string
  status?: string
  s?: string
  id: string
}

export function capitalizeParams(querystring: string) {
  return qs.parse(querystring)
}

export function parsePageParams(querystring: string): PageParams {
  const { sort_by, page, page_size, status, type, ...rest } = capitalizeParams(
    querystring
  )

  const params: PageParams = { ...rest }

  params.sort_by = sort_by
  if (page) {
    params.page = Number(page)
  }
  if (page_size) {
    params.page_size = Number(page_size)
  }

  if (status) {
    params.status = String(status)
  }

  if (type) {
    params.type = String(type)
  }

  return params
}

export function parseCursorPageParams(querystring: string): CursorPageParams {
  const {
    sort_by,
    page_size,
    after,
    before,
    supplier,
    status,
    promo,
    promo_type,
    entity_type,
    type,
    s,
    id,
    ...rest
  } = capitalizeParams(querystring)

  const params: CursorPageParams = { ...rest }
  params.sort_by = sort_by
  if (page_size) {
    params.page_size = Number(page_size)
  }

  params.after = after

  params.before = before

  params.type = type

  params.supplier = supplier

  params.promo_type = promo_type

  params.promo = promo

  params.entity_type = entity_type

  params.status = status

  params.s = s

  return params
}
